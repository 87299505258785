import React, { useState } from "react";
import {
  DatePicker,
  Input,
  Form,
  Select,
  Typography,
  Button,
  InputNumber,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { locationActions } from "../../../../../services/Location/LocationSlice";

const InfoBasic = ({ next, setSelectCityBirth, }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;
  const [selectCity, setSelectCity] = useState();
  const { locations } = useSelector((state) => state.location);

  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = (values) => {
    return next(values);
  };

  const layoutstyled = {
    labelCol: {
      xs: {
        span: 1,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 8,
      },
      lg: {
        span: 10,
      },
      xl: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 10,
      },
      sm: {
        span: 20,
      },
      md: {
        span: 14,
      },
      lg: {
        span: 14,
      },
      xl: {
        span: 8,
      },
    },
  };

  const getLocationOnSearch = (e) => {
    if (e.length > 4) dispatch(locationActions.getLocations(e));
  };

  const getLocationOnChange = (e) => {
    const city = locations.filter((location) => location.id == e);
    setSelectCityBirth(city);
  };

  return (
    <>
      <Title style={{ marginTop: 20, marginBottom: 20 }} level={5}>
        Información Básica
      </Title>
      <Form
        {...layoutstyled}
        form={form}
        name="register"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Nombre"
          rules={[
            { required: true, message: "Ingresa un nombre", whitespace: true },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastname"
          label="Apellido"
          rules={[
            { required: true, message: "Ingresa un nombre", whitespace: true },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="genere_id"
          label="Sexo"
          rules={[
            { required: true, message: "Selecciona el tipo de Propiedad" },
          ]}
        >
          <Select placeholder="Seleccione su Genero">
            <Option value={1}>Hombre</Option>
            <Option value={2}>Mujer</Option>
            <Option value={3}>Indefinido</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="per_trib_type_id"
          label="Tipo de persona"
          rules={[
            { required: true, message: "Selecciona el tipo de Propiedad" },
          ]}
        >
          <Select placeholder="Tipo de persona">
            <Option value={1}>Persona natural Independiente</Option>
            <Option value={2}>Persona natural Empleado</Option>
            <Option value={3}>Persona natural Pensionado</Option>
            <Option value={4}>Persona jurídica</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="birthday"
          label="Fecha de Nacimiento"
          rules={[{ required: true, message: "Selecciona una Fecha" }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="birth_location"
          label="Lugar de Nacimiento"
          rules={[{ required: true, message: "Por favor ingrese el nombre!" }]}
        >
          <Select
            showSearch
            placeholder={"Ciudad, Departamento"}
            showArrow={false}
            filterOption={false}
            onChange={getLocationOnChange}
            onSearch={getLocationOnSearch}
            notFoundContent={null}
          >
            {locations.map((location) => (
              <Option key={location.id} data={location}>
                {location.fullname}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="docid_type_id"
          label="Documento de Identidad"
          rules={[
            { required: true, message: "Selecciona el tipo de documento" },
          ]}
        >
          <Select placeholder="Tipo de persona">
            <Option value={1}>Pasaporte</Option>
            <Option value={2}>Cedula de extranjería</Option>
            <Option value={3}>Cedula de ciudadanía</Option>
            <Option value={4}>Nit</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="code"
          label="Numero de Documento"
          rules={[{ required: true, message: "Ingresa el numero" }]}
        >
          <InputNumber style={{ width: "200px" }} />
        </Form.Item>

        <Form.Item
          name="issue_date"
          label="Fecha Expedición del Documento"
          rules={[{ required: true, message: "Selecciona una Fecha" }]}
        >
          <DatePicker />
        </Form.Item>

        <div className="footer">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              siguiente
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default InfoBasic;
