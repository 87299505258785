import React, { useEffect, useState, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router'
import { Spin, Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

import { shoppingActions } from '../../../services/Shopping/ShoppingSlice'
import { contractActions } from '../../../services/Contract/ContractSlice'
import { getStatus } from '../../../common/constants/status'
import { ticketActions } from '../../../services/Ticket/TicketSlice'
import { propertyActions } from "../../../services/Property/PropertySlice";


export const ContractCard = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { id } = useParams()

    const { shopping, status } = useSelector(state => state.shopping)
    const { contract } = useSelector(state => state.contract)
    const { userAdmin } = useSelector((state) => state.property);

    const [model, setModel] = useState({ name: undefined, id: undefined })

    useEffect(() => {
        dispatch(shoppingActions.getShopping(id))
        return () => {
            dispatch(shoppingActions.setShopping());
        }
    }, [])

    useEffect(() => {
        if (!shopping) return
        const data = JSON.parse(shopping.metadata)

        if (shopping.metadata.includes('Contract')) {
            setModel({ name: 'Contract', id: data['App\\Models\\Contract'][0] })
            dispatch(contractActions.getContract(data['App\\Models\\Contract'][0]))
        }

        if (shopping.metadata.includes('Ticket')) {
            setModel({ name: 'Ticket', id: data['App\\Models\\Ticket'][0] })
            dispatch(ticketActions.getTicket(data['App\\Models\\Ticket'][0]))
        }

        return () => {
            dispatch(contractActions.setContract());
            dispatch(propertyActions.setProperty());
        }
    }, [shopping])


    useEffect(() => {
        if (!contract && contract?.contractable_type == "App\\Models\\Property") return
        dispatch(propertyActions.getProperty(contract?.contractable_id));

        return () => {
            dispatch(propertyActions.setProperty());
        }
    }, [contract])

    return (
        <Spin spinning={status === "loading"}>
            <div style={{ borderRadius: '12px', background: '#ffff', padding: "20px", display: 'flex', flexDirection: 'column', boxShadow: '0 20px 27px rgba(0, 0, 0, 0.05)' }} >
                <Button
                    type="text"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => history.goBack()}
                    style={{ marginBottom: "16px" }}
                />
                <h2 style={{ fontSize: '24px', marginBottom: '6px' }}>Contrato</h2>
                {model?.name === 'Contract' && contract && (
                    <>
                        <span style={{ color: '#776e6f', marginBottom: '20px' }}>ID  # {contract?.id}</span>
                        <span style={{ color: '#423d3e', marginBottom: '12px', fontSize: '16px', fontWeight: 600 }}> Propiedad: </span>
                        <span onClick={() => history.push(`/property/${contract?.contractable?.id}`)} style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}> {contract?.contractable?.title} </span>
                        <div style={{ height: '1px', background: ' #d9d9d9', margin: '5px 0 20px 0' }} />
                        <span style={{ color: '#423d3e', marginBottom: '12px', fontSize: '16px', fontWeight: 600 }}> Estado: </span>
                        <span style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500 }}> {getStatus(contract?.status_id)?.label} </span>
                        <div style={{ height: '1px', background: ' #d9d9d9', margin: '5px 0 20px 0' }} />
                        <span style={{ color: '#423d3e', marginBottom: '12px', fontSize: '16px', fontWeight: 600 }}> Código de registro: </span>
                        <span style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500 }}> {contract?.contractable?.cod_registration} </span>
                        <div style={{ height: '1px', background: ' #d9d9d9', margin: '5px 0 20px 0' }} />
                        <span style={{ color: '#423d3e', marginBottom: '12px', fontSize: '16px', fontWeight: 600 }}> Propietario: </span>
                        <span onClick={() => history.push(`/user/${contract?.ownership?.find(user => user.ownership_type_id == 1)?.user_id}`)} style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}> {contract?.ownership?.find(user => user.ownership_type_id == 1)?.user?.profile?.name} {contract?.ownership?.find(user => user.ownership_type_id == 1)?.user?.profile?.lastname} </span>
                        <div style={{ height: '1px', background: ' #d9d9d9', margin: '5px 0 20px 0' }} />
                        <span style={{ color: '#423d3e', marginBottom: '12px', fontSize: '16px', fontWeight: 600 }}> Inquilino: </span>
                        <span onClick={() => history.push(`/user/${contract?.ownership?.find(user => user.ownership_type_id == 2)?.user_id}`)} style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}> {contract?.ownership?.find(user => user.ownership_type_id == 2)?.user?.profile?.name} {contract?.ownership?.find(user => user.ownership_type_id == 2)?.user?.profile?.lastname} </span>
                        <div style={{ height: '1px', background: ' #d9d9d9', margin: '5px 0 20px 0' }} />
                        <span style={{ color: '#423d3e', marginBottom: '12px', fontSize: '16px', fontWeight: 600 }}> Inmobiliaria: </span>
                        <span onClick={() => history.push(`/user/${contract?.ownership?.find(user => user.ownership_type_id == 2)?.user_id}`)} style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}>
                            {userAdmin?.company && userAdmin?.company[0]?.company_data?.name ? userAdmin?.company[0]?.company_data?.name : "Sin Inmobiliaria"} {userAdmin?.company && userAdmin?.company[0]?.company_data?.lastname ? userAdmin?.company[0]?.company_data?.namelastname : ""}
                        </span>
                    </>
                )}
            </div>
        </Spin>
    )
}