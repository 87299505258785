import React, { useEffect, useState } from "react";
import { Select, Button, DatePicker, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentSendActions } from "services/PaymentSend/PaymentSendSlice";
import locale from 'antd/es/locale/es_ES';
import 'moment/locale/es'

export const Filter = () => {

    const dispatch = useDispatch()
    const [dataInmo, setDataInmo] = useState([]);
    const formRef = React.createRef();
    const { pagination } = useSelector(state => state.paymentSend)
    const { page, limit } = pagination
    const { companys } = useSelector((state) => state.company);
    const { user } = useSelector((state) => state.auth);
    const { company } = useSelector(state => state.user)
    const [value, setValue] = useState();

    useEffect(() => {
        const rolesAdmin = ["sysadmin", "admin"];
        const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))
        if (isAdmin) {
            const data = companys?.data?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setDataInmo(data)
        } else {
            const data = company?.map((item) => ({
                value: item?.company_data?.id,
                label: item?.company_data?.name,
            }));
            setDataInmo(data)
        }
    }, [companys])

    const handleFilter = (e) => {
        dispatch(PaymentSendActions.getPaymentSend({ companyId: e.inmo, day: e.date.date(), page: 1, limit }))
    };

    const onReset = () => {
        formRef.current.resetFields();
        dispatch(PaymentSendActions.SetPaymentSend({ items: [], pagination: { page: 1, limit } }))
    };

    return (
        <Form
            name="filterMenssage"
            initialValues={{
                remember: true,
            }}
            layout="inline"
            onFinish={handleFilter}
            autoComplete="off"
            ref={formRef}
        >
            <Form.Item
                name="inmo"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Select
                    showSearch
                    value={value}
                    placeholder={"Inmobiliaria"}
                    style={{ width: 200 }}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={dataInmo}
                />
            </Form.Item>

            <Form.Item
                name="date"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <DatePicker placeholder="Fecha" locale={locale} />
            </Form.Item>
            <Form.Item >
                <Button
                    style={{
                        borderRadius: '5px',
                        borderColor: '#d82067',
                        padding: '5px 31px',
                        height: 'auto',
                        fontSize: 'medium',
                    }}
                    type="primary"
                    htmlType="submit"
                >
                    Buscar
                </Button>
            </Form.Item>
            <Form.Item>
                <Button className="btn-default" htmlType="button" onClick={onReset}>
                    Limpiar
                </Button>
            </Form.Item>
        </Form >
    );
};
