import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { specialityActions } from '../../../../../../services/Speciality/SpecialitySlice'
import { useHistory } from "react-router-dom"
import { UserOutlined, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { Form, Button, Input, Col, Row, Select, Divider } from 'antd'

const { Option } = Select
const { TextArea } = Input

export const SpecialityForm = ({ next, setSpecialties, listSpecialities, setListEspecialities }) => {

  const [form] = Form.useForm()
  const [listSpecialitiesPayload, setListEspecialitiesPayload] = useState([])
  const [categoryName, setCategoryName] = useState('');
  const [subCategoryName, setSubCategoryName] = useState('');

  let history = useHistory()
  const dispatch = useDispatch()

  const { specialities, subSpecialities } = useSelector(state => state.speciality)

  const onFinish = data => {
    let categories = { categoryName: categoryName, subCategoryName: subCategoryName }
    let specialities = listSpecialities
    let payload = listSpecialitiesPayload
    let spec = {
      "specializable_type": "user",
      "specializable_id": 1,
      "speciality_type_id": data.subcategory ? data.subcategory : data.category,
      "warranty": data.garantia,
      "range": 200
    }
    specialities.push({ ...data, ...categories })
    payload.push(spec)
    setListEspecialities(specialities)
    setListEspecialitiesPayload(payload)
    setSpecialties(payload)
    form.resetFields()
  }


  const handleCategoryChange = e => {
    const name = (specialities.find(speciality => speciality.id == e)).name
    setCategoryName(name)
    dispatch(specialityActions.getSubSpecialities(e))
  }
  const handleSubCategoryChange = e => {
    const name = (subSpecialities.find(speciality => speciality.id == e)).name
    setSubCategoryName(name)
  }

  const deleteCategory = (index) => {
    setListEspecialities(listSpecialities.filter((t, i) => i !== index))
    setListEspecialitiesPayload(listSpecialitiesPayload.filter((t, i) => i !== index))
  }


  return (
    <div className="profile-form">
      <Form
        form={form}
        name="specialty-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="category"
              rules={[{ required: true, message: 'Por favor ingrese la categoría' }]}
            >
              <Select placeholder={"Categoría"} onChange={handleCategoryChange}>
                {specialities.map(speciality => <Option key={speciality.id}>{speciality.name}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              name="subcategory"
              rules={[{ required: true, message: 'Por favor ingrese la subcategoría' }]}
            >
              <Select placeholder={"Subcategoría"} onChange={handleSubCategoryChange}>
                {subSpecialities.map(speciality => <Option key={speciality.id}>{speciality.name}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              name="garantía"
              rules={[{ required: true, message: 'Por favor ingrese la garantía!' }]}
            >
              <Input prefix={<CheckCircleOutlined className="site-form-item-icon" />} placeholder="Garantía (en meses)" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="descripción"
              rules={[{ required: true, message: 'Por favor ingrese la descripción!' }]}
            >
              <TextArea
                placeholder="Descripción"
                autoSize={{ minRows: 4, maxRows: 5 }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Agregar especialidad
            </Button>
            </Form.Item>
          </Col>
        </Row>

        {listSpecialities?.map((speciality, i) => (
          <div className="speciality-card"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderColor: 'black',
              borderWidth: 2
            }}
            key={i}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>

              <p style={{ fontWeight: 'bold' }}>{speciality.categoryName}</p>
              <span>{speciality.subCategoryName}</span>
            </div>
            <div className="speciality-card-description">{speciality.descripcion}</div>
            <div className="speciality-card-warranty"><span>{speciality.garantia}</span> {speciality.garantia > 1 ? 'meses' : 'mes'} de garantía</div>

            <DeleteOutlined onClick={() => deleteCategory(i)} />
          </div>
        ))}
      </Form>
    </div>
  )
}