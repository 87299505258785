import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import 'moment/locale/es';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadExcel = () => {
  const { shoppings } = useSelector((state) => state.shopping);
  const [isLoading, setIsLoading] = useState(true);

  // Simular un delay para mostrar el loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const formatDate = (date) => {
    if (!date) {
      return 'sin fecha de recaudo';
    }
    return moment(date).isValid() ? moment(date).format('D [de] MMMM [de] YYYY') : 'sin fecha de recaudo';
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(amount);
  };

  const getPaymentMethod = (paymentMethod) => {
    switch (paymentMethod) {
      case 1:
        return "PayU";
      case 3:
        return "Zona Pago";
      case 4:
        return "Terceros";
      case 5:
        return "Paymentez";
      case 6:
        return "Wompi";
      default:
        return "Desconocido";
    }
  };

  if (isLoading) {
    return <div>Descargando...</div>;
  }

  return (
    <ExcelFile
      element={<button>Descargar Excel</button>}
      filename="Shoppings"
    >
      <ExcelSheet data={shoppings} name="Shoppings">
        <ExcelColumn label="ID" value="id" />
        <ExcelColumn label="Monto Total" value={(col) => formatCurrency(col.totalAmount)} />
        <ExcelColumn label="Fecha de Creación" value={(col) => formatDate(col.createdAt)} />
        <ExcelColumn label="Fecha de Recaudo" value={(col) => formatDate(col.transactionDate)} />
        <ExcelColumn label="Método de Pago" value={(col) => getPaymentMethod(col.paymentMethod)} />
        <ExcelColumn label="ID del Contrato" value={(col) => col.contract.id} />
        <ExcelColumn label="Título de la Propiedad" value={(col) => col.contract.property.title} />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default DownloadExcel;
