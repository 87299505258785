import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  status: null,
  errormsg: null,
  loading: true,
  growArray: { active: [], closed: [] },
  companys: null,
  filter: {},
};

const companySlice = createSlice({
  name: "company",
  initialState: initialState,
  reducers: {
    getCompanys() { },
    setCompanys(state, { payload }) {
      state.companys = payload;
    },
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    getGrowCompany() { },
    setGrowArray(state, { payload }) {
      state.status = false;
      state.growArray = payload.items;
      state.filter = payload.filter;
    },
  },
});

const companyActions = companySlice.actions;
const companyReducer = companySlice.reducer;

export { companyActions, companyReducer };
