import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Descriptions, Row, Spin, Typography, List, Timeline, Button, Modal, Card, Col } from "antd";
import { DollarOutlined } from '@ant-design/icons'
import { FeesConfigModal } from '../components/modals/FeesConfigModal';
import { feesActions } from 'services/Fees/FeesSlice';
import { formmatterCurrency } from 'common/utilities';
import { forEach } from 'lodash';

export const ContractFeesCard = () => {
  const { contract, status, payents } = useSelector((state) => state.contract);
  const { plans } = useSelector((state) => state.billing);
  const { Title } = Typography;
  const [feesModal, setFeesModal] = useState(false);

  const handleAddDiscount = () => {
    dispatch(feesActions.setState({ key: "feesModal", value: true }));
  };

  const handleAddFee = () => {
    setFeesModal(true);
  };

  const handleCancel = () => {
    setFeesModal(false);
  };

  let label_plan = () => { 
    let label = "";
    plans.forEach(element => { 
      if (element.id === contract?.dispersion_context.accountant_billing_plan) {
        label= element.name
      }
    }) 
    return label;
  };

  return (
    <Spin spinning={contract === undefined}>
      <div>
        <Card size="default" title={
          <Row>
            <Col flex={1}>
              <DollarOutlined style={{ background: "#ffdee2", borderRadius: '5px', padding: '5px', color: '#d82067' }} />
              <span style={{ marginLeft: '5px', fontWeight: 600, color: '#0d0c0c' }}>Tarifas</span>
            </Col>
            <Col>
              <Button className='button' type="primary" onClick={handleAddFee} >
                Editar tarifas
              </Button>
            </Col>
          </Row>
        } style={{ borderRadius: 12, marginBottom: '20px' }} bodyStyle={{ padding: 0 }}>
          <Row >
            <Descriptions
              bordered
              style={{ width: "100%" }}
              layout="vertical"
            >
              <Descriptions.Item label="Fee yampi">
                {(contract?.dispersion_context.type == 0) ? "Porcentual" : "Nominal"}
              </Descriptions.Item>
              <Descriptions.Item label="" style={{ width: "50%" }}>
                {(contract?.dispersion_context.type == 0) ? ((((contract?.dispersion_context.commission)*100)).toFixed(3))+"%" :formmatterCurrency(contract?.dispersion_context.commission)}
              </Descriptions.Item>
            </Descriptions>
          </Row>
          <Row >
            <Descriptions
              bordered
              style={{ width: "100%" }}
              layout="vertical"
            >
              <Descriptions.Item label="Fee inmobiliaria">
                {(contract?.dispersion_context.type_inmo == 0) ? "Porcentual" : "Nominal"}
              </Descriptions.Item>
              <Descriptions.Item label="" style={{ width: "50%" }}>
                {(contract?.dispersion_context.type_inmo == 0) ? ((((contract?.dispersion_context.commission_inmo)*100)).toFixed(3))+"%"  : formmatterCurrency(contract?.dispersion_context.commission_inmo)}
              </Descriptions.Item>
            </Descriptions>
          </Row>
          <Row >
            <Descriptions
              bordered
              style={{ width: "100%" }}
              layout="vertical"
            >
              <Descriptions.Item label="Fee aseguradora">
                {(contract?.dispersion_context.type_secure == 0) ? "Porcentual" : "Nominal"}
              </Descriptions.Item>
              <Descriptions.Item label="" style={{ width: "50%" }}>
              {(contract?.dispersion_context.type_secure == 0) ? ((((contract?.dispersion_context.commission_secure)*100)).toFixed(3))+"%"  : formmatterCurrency(contract?.dispersion_context.commission_secure)}
              
              </Descriptions.Item>
            </Descriptions>
          </Row>
          <Row >
            <Descriptions
              bordered
              style={{ width: "100%" }}
              layout="vertical"
            >
              <Descriptions.Item label="Plan de facturacion">
                { label_plan()}
              </Descriptions.Item>
               
            </Descriptions>
          </Row>

        </Card>

      </div>

      <Modal
        title={"Editar Tarifas"}
        visible={feesModal}
        onOk={handleAddFee}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={null}
        width={"35%"}
      >
        <FeesConfigModal handleCancel={handleCancel} dataAct={contract?.dispersion_context} contract={contract} />
      </Modal>
    </Spin>

  );

}