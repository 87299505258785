import React, { useEffect, useState } from "react";
import { Radio, Row, Col, Select, Checkbox, Button, DatePicker, Input, Form } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { billingActions } from "services/Billing/BillingSlice";

export const Filter = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const dispatch = useDispatch();
    const { companys } = useSelector((state) => state.company);
    const { company } = useSelector((state) => state.user);
    const [dataInmo, setDataInmo] = useState([]);
    const { pagination, filter } = useSelector(state => state.billing);
    const { user } = useSelector((state) => state.auth);
    const { limit, page } = pagination;
    const [startDate, setStartDate] = useState(formattedDate);

    useEffect(() => {
        const rolesAdmin = ["sysadmin", "admin"];
        const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))
        if (isAdmin) {
            const data = companys?.data?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setDataInmo(data)
        } else {
            const data = company?.map((item) => ({
                value: item?.company_data?.id,
                label: item?.company_data?.name,
            }));
            setDataInmo(data)
        }
    }, [companys])

    const onFinish = (values) => {
        values.date = startDate.toISOString().split('T')[0];
        dispatch(billingActions.getList({ page: 1, limit, filter: { ...values } }));
    };

    return (
        <Form
            style={{ width: 1500 }}
            onFinish={onFinish}
            layout="inline"
        >
            <Form.Item
                name="company_id"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Select
                    showSearch
                    value={filter.company_id}
                    placeholder={"Inmobiliaria"}
                    style={{ width: 200 }}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={dataInmo}
                />
            </Form.Item>
            <Form.Item
                name="prop_id"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input
                    placeholder={"Numero de factura"}
                    style={{ width: 150 }}
                ></Input>
            </Form.Item>
            <Form.Item>
                <DatePicker onChange={setStartDate} placeholder="Fecha" />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Buscar
                </Button>
            </Form.Item>
        </Form>
    );
};
