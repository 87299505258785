import React, { useState, useEffect, Fragment } from "react";
import { Modal, Col, Row, Divider, Button, Form, Input, Select, InputNumber, Result } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { feesActions } from "services/Fees/FeesSlice";
import { contractActions } from "services/Contract/ContractSlice";

export const StepFinal = ({
}) => { 
  return (
    <>
      <Result
        status="success"
        title="Contrato creada con éxito!"
        subTitle=""
      />
    </>
  );
};
