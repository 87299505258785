import { GiHomeGarage } from "react-icons/gi";
import { GiFruitTree } from "react-icons/gi";
import { FaWarehouse } from "react-icons/fa";
import { MdLocalLaundryService } from "react-icons/md";
import { FaCouch } from "react-icons/fa";
import { MdElevator } from "react-icons/md";
import { MdOutlinePool } from "react-icons/md";
import { MdOutlineAir } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { MdBalcony } from "react-icons/md";
import { MdApartment } from "react-icons/md";
import { MdCleaningServices } from "react-icons/md";
import { GrWheelchair } from "react-icons/gr";
import { CgGym } from "react-icons/cg";

export const ModCons = [
  {
    name: "washer_in_unit",
    label: "Lavadora",
    id: 1,
    icon: <MdLocalLaundryService size={45} style={{marginBottom: '5px'}}/>,
  },
  { name: "pool", label: "Piscina", id: 2, icon: <MdOutlinePool size={45} style={{marginBottom: '5px'}}/> },
  { name: "gym", label: "Gimnasio", id: 3, icon: <CgGym size={45} style={{marginBottom: '5px'}}/> },
  {
    name: "elevator",
    label: "Ascensor",
    id: 4,
    icon: <MdElevator size={45} style={{marginBottom: '5px'}}/>,
  },
  {
    name: "parking_spot",
    label: "Estacionamiento",
    id: 5,
    icon: <GiHomeGarage size={45} style={{marginBottom: '5px'}}/>,
  },
  {
    name: "air_conditioning",
    label: "Aire Acondicionado",
    id: 7,
    icon: <MdOutlineAir size={45} style={{marginBottom: '5px'}}/>,
  },
  {
    name: "doorman",
    label: "Portero",
    id: 8,
    icon: <MdOutlineSecurity size={45} style={{marginBottom: '5px'}}/>,
  },
  { name: "dishwasher", label: "Lavaplatos", id: 9, icon: <CgGym size={45} style={{marginBottom: '5px'}}/> },
  {
    name: "storage",
    label: "Deposito",
    id: 11,
    icon: <FaWarehouse size={45} style={{marginBottom: '5px'}}/>,
  },
  {
    name: "wheelchair",
    label: "Acceso minusválido",
    id: 12,
    icon: <GrWheelchair size={45} style={{marginBottom: '5px'}}/>,
  },
  {
    name: "hardwood_floors",
    label: "Piso de madera",
    id: 13,
    icon: <CgGym size={45} style={{marginBottom: '5px'}}/>,
  },
  { name: "garden", label: "Jardín", id: 14, icon: <GiFruitTree size={45} style={{marginBottom: '5px'}}/> },
  { name: "balcony", label: "Balcón", id: 15, icon: <MdBalcony size={45} style={{marginBottom: '5px'}}/> },
  { name: "furnished", label: "Amoblado", id: 16, icon: <FaCouch size={45} style={{marginBottom: '5px'}}/> },
  { name: "view", label: "Vista", id: 17, icon: <CgGym size={45} style={{marginBottom: '5px'}}/> },
  {
    name: "student_friendly",
    label: "Apto para estudiantes",
    id: 18,
    icon: <MdApartment size={45} style={{marginBottom: '5px'}}/>,
  },
  {
    name: "utilities_included",
    label: "Servicios públicos incluidos",
    id: 20,
    icon: <MdCleaningServices size={45} style={{marginBottom: '5px'}}/>,
  },
];
