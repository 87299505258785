
import React, { useState } from 'react';

import ApiService from '../../../../../common/api/Api';

const usePayuData = () => {
  const [payData, setPayData] = useState(null)

  const result = (idUser, idPay) =>
    ApiService.requestPost(`/paymentez/init-payment-link`, {
      "user_id": idUser,
      "cart_id": idPay
    }).then(Response => {
      setPayData(Response?.data?.data?.payment?.payment_url)
      return Response
    })
  return { result, payData }
};

export default usePayuData;
