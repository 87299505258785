import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Result, Button } from "antd";
const StepNotification = ({handleCancel}) => {
  const { status,errormsg } = useSelector((state) => state.user);

  return (
    <>
      <Result
        status={status === "success" ? "success" : 'error'}
        title={status === "success" ? "Usuario editado con éxito!!!": "Ha ocurrido un erro con la modificacion del usuario"}
        subTitle={status === "susccess" ?? errormsg}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" htmlType="submit" onClick={()=>handleCancel()}>
          Cerrar
        </Button>
      </div>
    </>
  );
};

export default StepNotification;
