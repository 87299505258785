import { createSlice } from "@reduxjs/toolkit";
import { error } from "highcharts";

const initialState = {
  contractDispersionModal: false,
  dispersionSelected: false,
  status: null,
  errormsg: null,
  loading: null,
  error: false,
  alertError: false
};

const contractDispersionSlice = createSlice({
  name: "dispersions",
  initialState: initialState,
  reducers: {
    setState(state, { payload: { key, value } }) {
      state[key] = value
    },
    onChangeStatus(state, { payload }) {
      state.loading = false;
      state.status = payload;
    },
    onError(state, { payload }) {
      state.loading = false;
      state.status = "error";
      state.errormsg = payload;
    },
    onAlert(state, { payload }) {
      state.error = payload
    },
    setLoading(state, { payload: { key, value } }) {
      state.loading[key] = value
    },
    createDispersion() { },
    updateDispersion() { },
    deleteDispersion() { },

  },
});

const contractDispersionActions = contractDispersionSlice.actions;
const contractDispersionReducer = contractDispersionSlice.reducer;

export { contractDispersionActions, contractDispersionReducer };
