import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import './sass/main.scss'

import store from './store/Store'
import App from './scenes/App'
import cuiTheme from './ui/type.d'

import locale from 'antd/es/locale/es_ES';

export const history = createBrowserHistory()

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={cuiTheme}>
      <ConfigProvider locale={locale}>
        <App />
      </ConfigProvider>;
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
