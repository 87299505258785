import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, DatePicker, Select, Form, InputNumber, Alert } from "antd";

import { CONTRACT_TYPE } from "../../../../../.././common/constants/contractType";
import { contractActions } from "../../../../../../services/Contract/ContractSlice";
import moment from 'moment';


const EditContract = ({ setIsModalEditContract }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { errormsg, loading, error, status, contract } = useSelector(
    (state) => state.contract
  );
  const { property } = useSelector((state) => state.property);
  const layoutstyled = {
    labelCol: {
      xs: {
        span: 1,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 8,
      },
      lg: {
        span: 10,
      },
      xl: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 10,
      },
      sm: {
        span: 20,
      },
      md: {
        span: 14,
      },
      lg: {
        span: 14,
      },
      xl: {
        span: 8,
      },
    },
  };

  useEffect(() => {
    console.log("🚀 ~ file: EditContract.js:58 ~ useEffect ~ property:", property)
  },
    [property])

  const formSuccess = (data) => {
    let d = data.finalDate;
    const pay = {
      payload: {
        contract_type_id: data.select,
        have_policy: true,
        contract_id: property.contract_active[0].id,
        started_at: data.startDate.format("YYYY-MM-DD"),
        finished_at: data.startDate.add(d, "months").format("YYYY-MM-DD"),
        //dispersion_context_id: 1,
      },
      cost: [
        {
          cost_type: { id: 1 },
          currency: { id: 2 },
          value: data.contractValue,
        },
        {
          cost_type: { id: 2 },
          currency: { id: 2 },
          value: data.AdmintValue,
        },
      ],
      idProperty: id,
      idContract: property.contract_active[0].id,
    };
    const res = dispatch(contractActions.editContract(pay));

    if (res.payload) {
      setIsModalEditContract(false);
    }
    d = null;
  };

  const formFailed = (error) => {

  };

  return (
    <Form
      onFinish={formSuccess}
      onFinishFailed={formFailed}
      initialValues={{
        select: property?.contract_active[0]?.contract_type_id,
        startDate: moment(property?.contract_active[0]?.started_at),
        contractValue: property?.contract_active[0]?.tarifa,
        finalDate: moment(property?.contract_active[0]?.finished_at).diff(moment(property?.contract_active[0]?.started_at), "months")
      }}
      form={form}
      {...layoutstyled}
    >
      <Form.Item
        name="select"
        label="Tipo de Contrato"
        rules={[{ required: true, message: "Selecciona el tipo de Contrato" }]}
      >
        <Select
          placeholder="Seleccione el tipo de contrato"
          style={{ width: "14rem" }}
        >
          {CONTRACT_TYPE.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="startDate"
        label="Fecha de Inicio"
        rules={[{ required: true, message: "Selecciona una Fecha" }]}
      >
       <DatePicker style={{ width: 200 }} />
      </Form.Item>

      <Form.Item
        name="finalDate"
        label="Meses del Contrato"
        rules={[{ required: true, message: "Selecciona una Fecha" }]}
      >
        <InputNumber min={1} style={{ width: 200 }} />
      </Form.Item>

      <Form.Item
        name="contractValue"
        label="Valor del Contrato"
        rules={[{ required: true, message: "Escriba un valor" }]}
      >
        <InputNumber
          style={{ width: 200 }}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        />
      </Form.Item>
       <Form.Item
        name="AdmintValue"
        label="Valor de administración"
        rules={[{ required: true, message: "Escriba un valor" }]}
      >
        <InputNumber
          style={{ width: 200 }}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        />
      </Form.Item> 
      {/*       <Form.Item
        name="porcentage Ipc"
        label="Porcentaje ipc"
        rules={[{ required: false, message: "Selecciona una Fecha" }]}
      >
        <InputNumber min={1} max={10} style={{ width: 200 }} />
      </Form.Item> */}

      {loading ? (
        <div>
          <LoadingOutlined style={{ fontSize: 30 }} spin />
        </div>
      ) : (
        <div className="footer">
          <Button type="primary" htmlType="submit">
            Asignar
          </Button>
        </div>
      )}

      {error && (
        <Alert
          message={errormsg}
          type="error"
          style={{ marginTop: "10px" }}
          showIcon
          closable
        />
      )}


    </Form>
  );
};

export default EditContract;
