import { MdBedroomChild } from 'react-icons/md';
import { MdBathroom } from 'react-icons/md';
import { MdKitchen } from 'react-icons/md';
import { GiHomeGarage } from 'react-icons/gi';
import { BsFillTreeFill } from 'react-icons/bs';
import { FaWarehouse } from 'react-icons/fa';
import { MdLiving } from 'react-icons/md';
import { MdLocalLaundryService } from 'react-icons/md';
import { MdFoodBank } from 'react-icons/md';



export const Features = [
  {
    name: "size",
    label: "Tamaño m2",
    id: 1,
    icon: <MdBedroomChild size={45} />
  },
  {
    name: "bedroom",
    label: "Cuartos",
    id: 2,
    icon: <MdBedroomChild size={45} />
  },
  {
    name: "bathroom",
    label: "Baños",
    id: 3,
    icon: <MdBathroom size={45} />
  },
  {
    name: "kitchen",
    label: "Cocina",
    id: 4,
    icon: <MdKitchen size={45} />
  },
  /* {
    name: "garage",
    label: "Garage",
    id: 5,
    icon: <GiHomeGarage size={45} />
  }, */
  {
    name: "playground",
    label: "Patios",
    id: 6,
    icon: <BsFillTreeFill size={45} />
  },
  /* {
    name: "storage",
    label: "Almacén",
    id: 7,
    icon: <FaWarehouse size={45} />
  }, */
  {
    name: "living_room",
    label: "Sala",
    id: 8,
    icon: <MdLiving size={45} />
  },
  {
    name: "basement",
    label: "Sótano",
    id: 9,
    icon: <MdLocalLaundryService size={45} />
  },
  {
    name: "dinning_room",
    label: "Comedor",
    id: 10,
    icon: <MdFoodBank size={45} />
  },
  {
    name: "laundry",
    label: "Lavandería",
    id: 11,
    icon: <MdLocalLaundryService size={45} />
  },
  {
    name: "attic",
    label: "Ático",
    id: 12,
    icon: <MdFoodBank size={45} />
  },
];
