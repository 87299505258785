
import React, { useEffect } from "react";
import { Spin, Table, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { shoppingActions } from "services/Shopping/ShoppingSlice";
import { columnsPayments } from "./constants"
import { useParams } from "react-router";


export const Payment = () => {
	const { contract_id } = useParams();
	const dispatch = useDispatch();
	const { shoppings, status, pagination, filterShoppings } = useSelector(state => state.shopping)
	const { page, limit, total } = pagination
	const currentColumn = columnsPayments();

	useEffect(() => {
		if (contract_id)
			dispatch(shoppingActions.getShoppings({ page: 1, limit: 10, date_filter: { contract_id: parseInt(contract_id) } }))
		return () => {
			dispatch(shoppingActions.setShoppings({
				data: [],
				pagination: {
					total: 1,
					page: 1,
					limit: 10
				}
			}))
		}
	}, [])

	const handlePagination = (page, limit) => {
		dispatch(shoppingActions.getShoppings({ page, limit, date_filter: { ...filterShoppings } }))
	}

	const tablePagination = {
		onChange: handlePagination,
		hideOnSinglePage: true,
		current: page,
		pageSize: limit,
		total: total
	}

	return (
		<>
			<Spin spinning={status === "loading"}>
				<Table
					columns={currentColumn}
					dataSource={shoppings}
					scroll={{ x: 400 }}
					pagination={tablePagination}
				/>
			</Spin>
		</>
	)
}