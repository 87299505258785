import moment from "moment";
import { formmatterCurrency } from "common/utilities";
import { ShowButton } from "../../../../components/ShowButton/ShowButton";
import { DollarOutlined, RedoOutlined, BankOutlined, MoreOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { shoppingActions } from 'services/Shopping/ShoppingSlice'
import { dispersionActions } from "services/Dispersion/DispersionSlice";
import { Menu, Dropdown, Button, Popconfirm } from "antd";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNotificationDispatchContext } from 'common/utilities/notification/NotificationContext';


export const paymentsColumns = ({ setIsModalVisiblePayment, setStatePay, setIsModalVisibleDiscount, setIsModalEditPay, showModal }) => {
    const { property } = useSelector((state) => state.property)
    const dispatch = useDispatch();
    const dispatchNotificationEvent = useNotificationDispatchContext();


    const history = useHistory();
    const confirm = (obj) => {
        dispatch(shoppingActions.deleteShopping({
            shopping_id: obj.id, propertyId: property?.id, callback: (type, dialog, message, description) => {
                dispatchNotificationEvent({
                    type,
                    dialog,
                    message,
                    description
                })
            }
        }));
    }

    const genDispersionOverPayment = async (obj) => {
        let updateData = {
            "shopping_cart": parseInt(obj)
        }
        await dispatch(dispersionActions.regenDataDisRegister({ updateData }))
    }

    const menu = (obj) => {
        return (
            <Menu>
                <Menu.Item onClick={() => history.push(`/shopping/${obj.id}`)}>
                    <EyeOutlined />
                    <span style={{ fontSize: 12, fontWeight: "600" }}>
                        Ver detalle
                    </span>
                </Menu.Item>
                {obj.status === "Pendiente de pago" ? (
                    <>
                        <Menu.Item onClick={() => {
                            setIsModalVisiblePayment(true);
                            setStatePay(obj);
                        }}>
                            <BankOutlined />
                            <span style={{ fontSize: 12, fontWeight: "600" }}>
                                Terceros
                            </span>
                        </Menu.Item>
                        <Menu.Item onClick={() => {
                            setIsModalVisibleDiscount(true);
                            setStatePay(obj);
                        }}>
                            <DollarOutlined />
                            <span style={{ fontSize: 12, fontWeight: "600" }}>
                                Descuento
                            </span>
                        </Menu.Item>
                        <Menu.Item onClick={() => {
                            setIsModalEditPay({ toggle: true, id: obj.id });
                        }}>
                            <FiEdit style={{}} />
                            <span
                                style={{ fontSize: 12, fontWeight: "600", marginLeft: "5px" }}
                            >
                                Editar Valor
                            </span>
                        </Menu.Item>
                        <Menu.Item >
                            <Popconfirm title="Deseas borrar el pago" onConfirm={() => confirm(obj)} >
                                <DeleteOutlined />
                                <span
                                    style={{ fontSize: 12, fontWeight: "600", marginLeft: "5px" }}
                                >
                                    Eliminar pago
                                </span>
                            </Popconfirm>
                        </Menu.Item>
                    </>
                ) : (
                    <></>
                )}
                {obj.status === "Pagado" ? (
                    <>
                        <Menu.Item onClick={() => {
                            genDispersionOverPayment(obj.id);
                        }}>
                            <BankOutlined />
                            <span style={{ fontSize: 12, fontWeight: "600" }}>
                                Generar registro de dispersion
                            </span>
                        </Menu.Item>
                    </>
                ) : (
                    <></>
                )}
            </Menu>
        )

    }



    return [
        {
            title: "id",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Fecha creación",
            dataIndex: "created_at",
            key: "created_at_pay",
            render: (created_at) => moment(created_at).format("ll"),
        },
        {
            title: "Fecha de recaudo",
            dataIndex: "transaction_date",
            key: "transaction_date",
            render: (transaction_date) =>
                transaction_date
                    ? moment(transaction_date).format("ll")
                    : "sin fecha de pago",
        },
      /*   {
            title: "Periodo",
            dataIndex: "metadata",
            key: "metadata",
            render: (metadata) =>
                metadata
                    ? JSON.parse(metadata)?.canon_period
                    : "test",
        }, */
        {
            title: "Valor",
            dataIndex: "total_amount",
            key: "total_amount_pay",
            render: (total_amount) => formmatterCurrency(total_amount),
        },
        {
            title: "Estado",
            dataIndex: "",
            key: "status_pay",
            render: (data) => {
                let paymentStore;

                switch (data.payment_method) {
                    case 0:
                        paymentStore = "Wompi";
                        break;
                    case 1:
                        paymentStore = "payu";
                        break;
                    case 3:
                        paymentStore = "zona pago";
                        break;
                    case 4:
                        paymentStore = "terceros";
                        break;
                    case 4:
                        paymentStore = "terceros";
                        break;
                    case 5:
                        paymentStore = "paymentez";
                        break;
                    case 6:
                        paymentStore = "Wompi";
                        break;
                }
                return (
                    <span>
                        {data.payment_method
                            ? `${data.status} por ${paymentStore} `
                            : data.status}
                    </span>
                );
            },
        },
        {
            title: "Acciones",
            key: "action_pay",
            dataIndex: "",
            render: (obj) => (
                <>
                    <Dropdown
                        overlay={menu(obj)}
                        placement="bottomLeft"
                        arrow={{ pointAtCenter: true }}
                        trigger={['click']}
                    >
                        <Button type='text'><MoreOutlined style={{ fontSize: '22px', transform: 'scale(1.4)', color: 'rgb(112 112 112)' }} /></Button>
                    </Dropdown>
                </>
            ),
        },
        {
            title: "Mensaje de Recaudo",
            key: "link",
            dataIndex: "",
            render: (obj) => (
                <>
                    {
                        {
                            11: (
                                <>
                                    <Button
                                        style={{ backgroundColor: "#6f1183", borderColor: "#6f1183" }}
                                        type="primary"
                                        icon={<DollarOutlined style={{}} />}
                                        size={"large"}
                                        onClick={() => showModal("yampiPasarela", obj)}
                                    >
                                        <span
                                            style={{ fontSize: 12, fontWeight: "600", marginLeft: "5px" }}
                                        >
                                            Generar
                                        </span>
                                    </Button>
                                </>
                            ),
                            18: (
                                <ShowButton
                                    onClick={() => {
                                        dispatch(
                                            shoppingActions.rePayment({ shopping_id: obj.id })
                                        );
                                    }}
                                    size="small"
                                    icon={<RedoOutlined />}
                                    style={{ backgroundColor: "#ed1c27", borderColor: "#ed1c27" }}
                                />
                            ),
                        }[obj.status_id]
                    }
                </>
            ),
        },
    ];
}

export const status_id = [
    "open",
    "in_progress",
    "stopped",
    "canceled",
    "re_opened",
    "closed",
    "Rechazado",
    "accepted",
    "rescheduled",
    "signed",
    "Pendiente de pago",
    "Pagado",
    "delivered",
    "returned",
    "in_deliver",
    "in_return",
    "delivered_not_inventory",
    "Espera de aprobación",
];

export const COST_TYPE = [
    {
        id: 1,
        name: "canon"
    },
    {
        id: 2,
        name: "administración"
    },
    {
        id: 3,
        name: "deposito"
    },
    {
        id: 4,
        name: "Firma"
    },
    {
        id: 5,
        name: "Firma"
    },
    {
        id: 6,
        name: "Dispersion"
    },
    {
        id: 7,
        name: "ticket inquilino"
    },
    {
        id: 8,
        name: "canon y administracion"
    },
    {
        id: 9,
        name: "Pago late"
    },
    {
        id: 10,
        name: "sobre costo"
    },
    {
        id: 11,
        name: "ticket propietario"
    },
    {
        id: 12,
        name: "Valor del contrato"
    },
    {
        id: 13,
        name: "Planes"
    },
]