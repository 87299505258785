import React, { useEffect, useState, } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Spin, Button, Avatar, Row } from 'antd'
import { ArrowLeftOutlined, MoreOutlined } from '@ant-design/icons'
import { apiUrl } from "common/config/Environments";

export const UserInfo = () => {
    const history = useHistory()

    const { status } = useSelector(state => state.shopping)
    const { property, userAdmin } = useSelector((state) => state.property);
    const { user } = useSelector((state) => state.user);


    const { contract } = useSelector(state => state.contract)

    return (
        <Spin spinning={status === "loading"}>
            <div className="property-card"  >
                <Button
                    type="text"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => history.goBack()}
                    style={{ marginBottom: "16px" }}
                />
                {user?.portrait && (
                    <Row justify='center' style={{ marginBottom: "16px" }}>
                        <Avatar size={160} src={`${apiUrl}/${user?.portrait?.filepath}${user?.portrait?.filename}`} >Sin foto</Avatar>
                    </Row>)
                }
                <div className='property-card__title' >
                    <h2 style={{ fontSize: '24px', marginBottom: '6px' }}>Usuario</h2>
                </div>
                <span className='property-card__subtitle'>ID  # {user?.id}</span>
                <div className='item'>
                    <span className='item__label'> Nombre: </span>
                    <span className='item__value'>{user?.name} {user?.lastname}</span>
                </div>
                <div className='item'>
                    <span className='item__label'> Email: </span>
                    <span className='item__value'> {user?.email}</span>
                </div>
                <div className='item'>
                    <span className='item__label'> Teléfono: </span>
                    <span className='item__value'> {user?.phone?.number} </span>
                </div>
                <div className='item'>
                    <span className='item__label'> Ubicación: </span>
                    <span className='item__value'> {user?.location}</span>
                </div>
                <div className='item'>
                    <span className='item__label'> Compañia: </span>
                    <span className='item__value'>    {user?.company?.map((item) => (
                        <a>
                            {item?.company_data?.name}
                            {", "}
                        </a>
                    ))}</span>
                </div>
                <div className='item'>
                    <span className='item__label'> Roles: </span>
                    <span className='item__value'>  {user?.roles?.map((item) => (
                        <a>
                            {item.name}
                            {", "}
                        </a>
                    ))}</span>
                </div>
            </div>
        </Spin>
    )
}