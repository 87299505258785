import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input, Button, Col, Row, Form } from "antd";
import { propertyActions } from 'services/Property/PropertySlice'
import _debounce from 'lodash/debounce';



export const Filter = () => {
    const dispatch = useDispatch()
    const { companys } = useSelector((state) => state.company);
    const [dataInmo, setDataInmo] = useState([]);
    const [filterProp, setFilterProp] = useState({ name: undefined, prop_id: undefined });
    const { company } = useSelector((state) => state.user);
    const { user } = useSelector((state) => state.auth);
    const { pagination, filter } = useSelector(state => state.property)
    const { limit, page } = pagination;
    const formRef = React.createRef();

    useEffect(() => {
        const rolesAdmin = ["sysadmin", "admin"];
        const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))
        if (isAdmin) {
            const data = companys?.data?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setDataInmo(data)
        } else {
            const data = company?.map((item) => ({
                value: item?.company_data?.id,
                label: item?.company_data?.name,
            }));
            setDataInmo(data)
        }
    }, [companys])


    const onFinish = (values) => {
        dispatch(propertyActions.getProperties({ page: 1, limit, filter: { ...values } }))
    };

    const onReset = () => {
        formRef.current.resetFields();
        dispatch(propertyActions.getProperties({ page: 1, limit, filter: { company_id: company[0].company_id } }));
    };

    useEffect(() => {
        if (company)
            dispatch(propertyActions.getProperties({ page, limit, filter: { is_deleted: 0, company_id: company[0].company_id } }));
    }, [company]);

    return (company ?
        <Form
            name="property-list"
            labelCol={{
                span: 8,
            }
            }
            initialValues={{
                remember: true,
                company_id: company && company[0]?.company_id
            }
            }
            wrapperCol={{
                span: 16,
            }}
            onFinish={onFinish}
            ref={formRef}
            layout="inline"
        >
            <Form.Item
                name="prop_id"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input
                    value={filterProp.prop_id}
                    placeholder={"Id"}
                    style={{ width: 100 }}
                    onChange={(e) => {
                        if (e.target.value.length) {
                            setFilterProp({ ...filterProp, prop_id: e.target.value })
                        } else {
                            setFilterProp({ ...filterProp, prop_id: undefined })
                        }
                    }}
                ></Input>
            </Form.Item>
            <Form.Item
                name="name"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input
                    value={filterProp.name}
                    placeholder={"Título de propiedad"}
                    style={{ width: 250 }}
                    onChange={(e) => {
                        if (e.target.value.length) {
                            setFilterProp({ ...filterProp, name: e.target.value })
                        } else {
                            setFilterProp({ ...filterProp, name: undefined })
                        }
                    }}
                ></Input>
            </Form.Item>
            <Form.Item
                name="is_deleted"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Select
                    style={{
                        width: 120,
                    }}
                    placeholder="Estado"
                    options={[
                        {
                            value: 0,
                            label: 'Todas',
                        },
                        {
                            value: 1,
                            label: 'Borradas',
                        },
                    ]}
                />
            </Form.Item>
            <Form.Item
                name="company_id"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Select
                    showSearch
                    value={filter.company_id}
                    placeholder={"Inmobiliaria"}
                    style={{ width: 200 }}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={dataInmo}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Buscar
                </Button>
            </Form.Item>
            <Form.Item>
                <Button className="btn-default" htmlType="button" onClick={onReset}>
                    Limpiar
                </Button>
            </Form.Item>
        </Form > : <></>

    );
};
