import React, { useEffect } from "react";
import { Button, Table, Tabs } from "antd";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { FiDelete, FiEdit } from "react-icons/fi";

import { shoppingActions } from "../../../../../../services/Shopping/ShoppingSlice";
import { ShowButton } from "../../../../components/ShowButton/ShowButton";
import { contractActions } from "../../../../../../services/Contract/ContractSlice";
import { formmatterCurrency } from "../../../../../../common/utilities";
import Owner from "../ContractModal/components/Owner";
import Tenant from "../ContractModal/components/Tenant";
import Discounts from "../ContractModal/components/Discounts";
import Dispersions from "../ContractModal/components/Dispersions";
import { InventoryCard } from '../ContractModal/sections/InventoryCard';
import { ContractFeesCard } from '../ContractModal/sections/ContractFeesCard';
import DiscountsDispersion from "../ContractModal/components/DiscountsDispersion"; 
import ContractConfigurations from "../ContractModal/components/ContractConfigurations";
import { billingActions } from "services/Billing/BillingSlice";

const ContractCollapse = ({ setContractId, setIsModalEditContract, cartsProperty }) => {
  const history = useHistory();
  const { property } = useSelector((state) => state.property);
  const { contract } = useSelector((state) => state.contract);
  const dispatch = useDispatch();
  const { id: ID } = useParams();

  useEffect(() => {
    if (property && property.contract_initiate.length > 0) {
      dispatch(contractActions.getContract(property.contract_initiate[0].id));
      dispatch(billingActions.getPlans());
    } 
  }, [property]);

  const closeContract = (contract_id) => {
    let answer = window.confirm("Desea dar de baja el contrato, esto eliminara pagos no realizados y pondra la porpiedad habilitada para nuevo contrato?");
    if (answer) {
      //se da de baja el contrato
      const response = dispatch(contractActions.downContract({ id: property.contract_initiate[0].id }));
    }
  };


  const contratcsColumns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Inquilino",
      dataIndex: "ownership",
      key: "ownership",
      render: (ownership) => ownership?.find(user => user.ownership_type_id == 2)?.user?.profile?.name + ownership?.find(user => user.ownership_type_id == 2)?.user?.profile?.lastname,
    },
    /*     {
          title: "Tipo de contrato",
          dataIndex: "contract_type_id",
          key: "contract_type_id",
          render: (contract_type_id) => getContractType(contract_type_id).label,
        }, */
    {
      title: "Inicio",
      dataIndex: "started_at",
      key: "started_at",
      render: (started_at) => (started_at == "1980-01-01 00:00:00") ? "Sin definir" : moment(started_at).format("LL"),
    },
    {
      title: "Final",
      dataIndex: "finished_at",
      key: "finished_at",
      render: (finished_at) => (finished_at == "1980-01-01 00:00:00") ? "Sin definir" : moment(finished_at).format("LL"),
    },
    {
      title: "Valor Contrato",
      dataIndex: "costs",
      key: "costs",
      render: (costs) => {
        var cost_total = 0;
        var text_cost = "";
        costs?.forEach(
          function (element) {
            cost_total = cost_total + element.value;
            switch (element.cost_type_id) {
              case 1:
                text_cost += " - Canon :" + formmatterCurrency(element.value) + "";
                break;
              case 2:
                text_cost += " - Administracion :" + formmatterCurrency(element.value) + "";
                break;
              case 12:
                text_cost += " - Contrato :" + formmatterCurrency(element.value) + "";
                break;
              case 8:
                text_cost += " - Canon+Administracion :" + formmatterCurrency(element.value) + "";
                break;
            }
          }
        );
        return formmatterCurrency(cost_total) + " [" + text_cost + "]";
      }

    },
    {
      title: "Estado",
      dataIndex: "status_id",
      key: "status_id",
      render: (record) => {
        switch (record) {
          case 6:
            return "Cancelado";
            break;
          // Puedes agregar más casos según sea necesario
          default:
            return "Activo";
            break;
        }
      }
    },
    {
      title: "Acciones",
      key: "action",
      dataIndex: "id,status_id",
      width: "10%",
      render: (id, record) => {
        let deleteButton = null;
        let updateButton = null;
        if (record.status_id >= 12) {
          deleteButton = (
            <ShowButton
              onClick={() => closeContract(record.id)}
              size="small"
              icon={<FiDelete />}
            />
          );
          updateButton = (
            <ShowButton
              onClick={() => { setIsModalEditContract(true) }}
              size="small"
              icon={<FiEdit />}
            />
          )
        }
        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <ShowButton
              onClick={() =>
                history.push(
                  `/property/${ID}/contract/${record.id}`
                )
              }
              size="small"
            />
            {updateButton}
            {deleteButton}
          </div>
        );
      }
    },
  ];

  const formatDate = (end, start) => {
    return moment(end).diff(moment(start), "months");
  };


  return (
    <>
      {property?.contract_active?.length > 0 && (
        <>
          {cartsProperty.length == 0 && (
            <Button
              onClick={() => {
                dispatch(
                  shoppingActions.generatePayment({
                    contract_id: property?.contract_active[0]?.id,
                  })
                );
              }}
              type="primary"
              block
              size="middle"
              style={{ marginBottom: 20 }}
            >
              <span style={{ fontSize: 18, fontWeight: "500" }}>
                Generar Recaudo {moment().format("MMMM YYYY")}
              </span>
            </Button>
          )}
          <div style={{ padding: '10px 20px' }}>
            <Tabs defaultActiveKey="1" tabBarStyle={{ fontSize: '22px' }} size='small'>
              <Tabs.TabPane tab="Propietario" key="1" >
                <Owner />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Inquilino" key="2" >
                <Tenant />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Tarifas" key="6" >
                <ContractFeesCard />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Distribucion sobre el contrato" key="4" >
                <Dispersions />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Descuentos y Adiciones " key="5" >
                <Discounts />
              </Tabs.TabPane>
              {/*        <Tabs.TabPane tab="Inventarios" key="6" >
                <InventoryCard />
              </Tabs.TabPane> */}
              <Tabs.TabPane tab="Configuracion" key="7" >
                <ContractConfigurations />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </>
      )}

      {/*<Table
        title={() => "Historial de contratos"}
        columns={contratcsColumns}
        dataSource={property?.all_contracts}
        pagination={{ pageSize: 5 }}
        size="large"
        bordered={true}
        aling="center"
        scroll={{ x: 400 }}
      />*/}
    </>
  );
};

export default ContractCollapse;
