import React from "react";
import { Input, Form, Select, Typography, Button } from "antd";
const Email = ({ next }) => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const layoutstyled = {
    labelCol: {
      xs: {
        span: 1,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 8,
      },
      lg: {
        span: 8,
      },
      xl: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 10,
      },
      sm: {
        span: 20,
      },
      md: {
        span: 14,
      },
      lg: {
        span: 14,
      },
      xl: {
        span: 12,
      },
    },
  };
  const onFinish = (values) => {
    return next(values);
  };
  return (
    <>
      <Form
        {...layoutstyled}
        form={form}
        name="register"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        scrollToFirstError
        style={{marginTop: '2em'}}
      >
        <Form.Item
          name="Email"
          label="Correo Electrónico "
          rules={[
            {
              required: true,
              message: "Ingresa una correo",
              whitespace: true,
              type: "email",
            },
          ]}
        >
          <Input type={"email"} />
        </Form.Item>

        <Form.Item
          name="password"
          label="Contraseña "
          rules={[
            {
              required: true,
              message: "Ingresa una contraseña",
              whitespace: true,
            },
          ]}
        >
          <Input type={"password"} />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Siguiente
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default Email;
