import createAction from '../../common/utilities/create-action';

const asyncActions = createAction({
  inventory: [
    'getSetsTypeAvailable',
    'getSetsAvailable',
    'getInventoryContract',
    'getInventory',
    'getInventoryItem',
    'getInventoryTypes',
    'addInventoryItem',
    'filledInventory',
    'updateInventory',
    'generateInventoryAct',
    'getInventoryDuplicate'
  ]
})

export default asyncActions