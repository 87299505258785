import React, { useEffect, useState } from 'react'
import { Filter } from './sections/Filter'
import { PageHeader, Spin, Table } from 'antd'
import { columns, tablePagination } from './constans';
import { useSelector } from 'react-redux';

export const SystemBlueprint = () => {

  const currentColumn = columns();
  const currentTablePagination = tablePagination();
  const { list,status } = useSelector(state => state.sysblueprint);
   
  return (
    <div className="sysblueprint"> 
      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888', paddingLeft: 0 }}
      >
        <Filter  />
      </PageHeader>
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <> 
            </>
          }
          columns={currentColumn}
          dataSource={list}
          pagination={currentTablePagination}
          scroll={{ x: 400 }}
        />
      </Spin>
 
    </div>
  )
}