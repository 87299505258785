import React, {useEffect, useState } from "react";
import {
  DatePicker,
  Input,
  Form,
  Select,
  Typography,
  Button,
  InputNumber,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { locationActions } from "../../../../../services/Location/LocationSlice";
import moment from 'moment'
import 'moment/locale/es'
const InfoBasic = ({ next, setSelectCityBirth,user }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;
  const [selectCity, setSelectCity] = useState();
  const [city, setCity] = useState();
  const [cityLabel, setCityLabel] = useState();
  const { locations } = useSelector((state) => state.location);

  const dispatch = useDispatch();
  const history = useHistory(); 

  useEffect(() => {  
    if(user){
      if(city === undefined || city.length == 0){
        if(typeof user['profile'].birth_location === 'string' && user['profile'].birth_location.length !== 0 && cityLabel !== undefined ){ 
          var city_var = user['profile'].birth_location;
          var string = city_var.split(",");  
          dispatch(locationActions.getLocations(string[0]))   
          const city =  locations .filter((location) =>  location.fullname == user['profile'].birth_location  || location.name == user['profile'].birth_location );  
          setSelectCityBirth(city);   
          setCity(city);
          setCityLabel(location.fullname == user['profile'].birth_location)   
        } 
      } 
    } 
    if (locations.length > 0) {
      const city = locations.filter((location) =>  
        location.fullname == user['profile'].birth_location || 
        location.name == user['profile'].birth_location
      );  
      setSelectCityBirth(city);
      setCity(city);
    }
  }, [locations])

  useEffect(() => {  
    if(user){
      let genre  = null;
      let docid_type  = null;
      let per_trib_type  = null;
      if(user['genre'] != undefined){ 
        switch(user['genre']){
          case 'Femenino':
            genre  = 2;
          break;
          case 'Masculino':
            genre  = 1;
          break;
          case 'Otro':
            genre  = 3;
          break; 
        }
      } 
      if(user['docid'] != undefined){ //docid_type_id
        if(user['docid'].docid_type_id != undefined){
          docid_type =user['docid'].docid_type_id;
        } 
      } 
      if(user['profile'] != undefined){ //per_trib_type_id
        if(user['profile'].per_trib_type_id != undefined){
          per_trib_type =user['profile'].per_trib_type_id;
        } 
      } 
      form.setFieldsValue({
        name:(user['name'] == undefined  )? '':user['name'], 
        lastname:(user['lastname'] == undefined  )? '':user['lastname'],
        issue_date: (user['docid'] == undefined && user['docid']?.issue_date == undefined  )? '':  moment(user['docid']?.issue_date) ,  
        code: (user['docid'] == undefined && user['docid']?.code == undefined)? '':user['docid']?.code,
        docid_type_id:docid_type,
        birth_location: (user['profile'] == undefined && user['profile'].birth_location == undefined  )? '':  user['profile'].birth_location ,
        birthday: (user['profile'] == undefined && user['profile'].birthday == undefined  )? '':  moment(user['profile'].birthday) , 
        per_trib_type_id:per_trib_type,
        genere_id: genre 
      }) 
      if(user['profile'] != undefined && user['profile'].birth_location != undefined  ){  
        if(city === undefined || city.length == 0){
          if(typeof user['profile'].birth_location === 'string' && user['profile'].birth_location.length !== 0){ 
            var city_var = user['profile'].birth_location;
            var string = city_var.split(","); 
            dispatch(locationActions.getLocations(string[0])) 
            const city =  locations .filter((location) =>  location.fullname == user['profile'].birth_location || location.name == user['profile'].birth_location );  
            setSelectCityBirth(city);   
            setCity(city);  
          }
        } 
      } 
    } 
  }, [user])



  const onFinish = (values) => {
    return next(values);
  };

  const layoutstyled = {
    labelCol: {
      xs: {
        span: 1,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 8,
      },
      lg: {
        span: 10,
      },
      xl: {
        span: 10,
      },
    },
    wrapperCol: {
      xs: {
        span: 10,
      },
      sm: {
        span: 20,
      },
      md: {
        span: 14,
      },
      lg: {
        span: 14,
      },
      xl: {
        span: 8,
      },
    },
  };

  const getLocationOnSearch = (e) => {
    if (e.length > 4) dispatch(locationActions.getLocations(e));
  };

  const getLocationOnChange = (e) => { 
    const city = locations.filter((location) => location.id == e);
    setSelectCityBirth(city);
  };

  return (
    <>
      <Title style={{ marginTop: 20, marginBottom: 20 }} level={5}>
        Información Básica
      </Title>
      <Form
        {...layoutstyled}
        form={form}
        name="register"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          name="name"
          label="Nombre"
          rules={[
            { required: true, message: "Ingresa un nombre", whitespace: true },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastname"
          label="Apellido"
          rules={[
            { required: true, message: "Ingresa un nombre", whitespace: true },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="genere_id"
          label="Sexo"
          rules={[
            { required: true, message: "Seleccione una opcion" },
          ]}
        >
          <Select placeholder="Seleccione su Genero">
            <Option value={1}>Hombre</Option>
            <Option value={2}>Mujer</Option>
            <Option value={3}>Indefinido</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="per_trib_type_id"
          label="Tipo de persona"
          rules={[
            { required: true, message: "Seleccione una opcion" },
          ]}
        >
          <Select placeholder="Tipo de persona">
            <Option value={1}>Persona natural Independiente</Option>
            <Option value={2}>Persona natural Empleado</Option>
            <Option value={3}>Persona natural Pensionado</Option>
            <Option value={4}>Persona jurídica</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="birthday"
          label="Fecha de Nacimiento"
          rules={[{ required: true, message: "Selecciona una Fecha" }]}
        >
          <DatePicker />
        </Form.Item>

        <Form.Item
          name="birth_location"
          label="Lugar de Nacimiento"
          rules={[{ required: true, message: "Por favor ingrese el lugar de nacimiento!" }]}
        >
          <Select
            showSearch
            placeholder={"Ciudad, Departamento"}
            showArrow={false}
            filterOption={false}
            onChange={getLocationOnChange}
            onSearch={getLocationOnSearch}
            notFoundContent={null}
          >
            {locations?.map((location) => (
              <Option key={location.id} data={location}>
                {location.fullname}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="docid_type_id"
          label="Documento de Identidad"
          rules={[
            { required: true, message: "Selecciona el tipo de documento" },
          ]}
        >
          <Select placeholder="Tipo de documento">
            <Option value={1}>Pasaporte</Option>
            <Option value={2}>Cedula de extranjería</Option>
            <Option value={3}>Cedula de ciudadanía</Option>
            <Option value={4}>Nit</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="code"
          label="Numero de Documento"
          rules={[{ required: true, message: "Ingresa el numero" }]}
        >
          <InputNumber style={{ width: "200px" }} />
        </Form.Item>

        <Form.Item
          name="issue_date"
          label="Fecha Expedición del Documento"
          rules={[{ required: true, message: "Selecciona una Fecha" }]}
        >
          <DatePicker />
        </Form.Item>

        <div className="footer">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              siguiente
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default InfoBasic;
