export const status_id = [
    "open",
    "in_progress",
    "stopped",
    "canceled",
    "re_opened",
    "closed",
    "Rechazado",
    "accepted",
    "rescheduled",
    "signed",
    "Pendiente de pago",
    "Pagado",
    "delivered",
    "returned",
    "in_deliver",
    "in_return",
    "delivered_not_inventory",
    "Espera de aprobación",
];

export const onChangeNumber = (phone, property, currentPay, methodPay, urlPayu) => {
    let url = new URL("https://api.whatsapp.com/send");
    let date = new Date();
    let hour = date.getHours();
    let welcome = "";
    if (hour >= 0 && hour < 12) {
        welcome = "Buenos días";
    }
    if (hour >= 12 && hour < 18) {
        welcome = "Buenas tardes";
    }
    if (hour >= 18 && hour < 24) {
        welcome = "Buenas noches";
    }
    url.searchParams.append("phone", phone);
    url.searchParams.append(
        "text",
        `${welcome}.

Te confirmamos que ya puedes cancelar el pago de tu canon del inmueble ${property.title
        } por valor de ${(`$ ${currentPay?.total_amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ","))} a través del siguiente enlace:
        
        ${urlPayu}
        
        
        ¡Gracias por confiar en nosotros!
        `
    );
    return url.toString()
};
