
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { Button, Spin, Row, Col, Modal } from "antd";
import { ArrowLeftOutlined, WhatsAppOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/es";

import { propertyActions } from "../../../../services/Property/PropertySlice";
import { shoppingActions } from "../../../../services/Shopping/ShoppingSlice";
import { contractActions } from "../../../../services/Contract/ContractSlice";
import { dispersionActions } from "services/Dispersion/DispersionSlice";

import { TicketModal } from "./Components/TicketModal/TicketModal";
import { RenterAddModal } from "./Components/RenterAddModal/RenterAddModal";
import { ModalPay } from "./Components/ModalPay";
import CollapseDetails from "./Components/CollapseDetails";
import { usePayuData } from './hooks';
import { status_id } from './constants'
import { formmatterCurrency } from "../../../../common/utilities";
import { useNotificationDispatchContext } from '../../../../common/utilities/notification/NotificationContext';
import { PropertyCard } from './Components/PropertyCard/propertyCard'
import { PropertyDataCard } from './Components/PropertyDataCard/PropertyDataCard'

export const PropertyDetail = () => {
  moment.locale("es");
  const [ticket, setTicket] = useState();
  const [contractId, setContractId] = useState();

  const history = useHistory();
  const { id } = useParams();
  const [isModalVisibleRenterAdd, setisModalVisibleRenterAdd] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [methodPay, setMethodPay] = useState(undefined);
  const [currentPay, setCurrentPay] = useState(undefined);
  const [isModalVisiblePayment, setIsModalVisiblePayment] = useState(false)
  const [current, setCurrent] = useState(0);
  const { property, status } = useSelector(
    (state) => state.property
  );

  const { shoppingsProperty, dispersion, discount } = useSelector(
    (state) => state.shopping
  );
  const [cartsProperty, setCartsProperty] = useState([]);
  const dispatchNotificationEvent = useNotificationDispatchContext();

  const dispatch = useDispatch();
  const { result, payData } = usePayuData();

  const [isModalVisibleCreateContract, setIsModalVisibleCreateContract] = useState(false);
  const handleOkCreateContract = () => {
    setIsModalVisibleCreateContract(false);
  };
  const handleCancel = () => {
    setIsModalVisibleCreateContract(false);
    setCurrent(0);
  };
  const next = () => {
    setCurrent(current + 1);
  };
  useEffect(() => {
    dispatch(propertyActions.getProperty(id));
    dispatch(shoppingActions.getShoppingsProperty({ role: 0, propertyId: id }));
    return () => {
      dispatch(shoppingActions.setShoppingsProperty());
      dispatch(propertyActions.setProperty());
      dispatch(contractActions.setContract());
      dispatch(propertyActions.setUser(undefined))
      dispatch(propertyActions.setUserAdmin(undefined))
      dispatch(propertyActions.setUserRenter(undefined))
      dispatch(dispersionActions.setDispersions({
        items: [], pagination: {
          total: 1,
          page: 1,
          limit: 20
        }
      }))
    };
  }, []);

  useEffect(() => {
    if (property && property.contract_initiate.length) {
      dispatch(contractActions.getContract(property?.contract_initiate[0]?.id));
      if (property?.contract_active[0]?.id)
        dispatch(propertyActions.getMedia({ entityId: property?.contract_active[0]?.id, entityName: 'contract' }))
    }
  }, [property]);


  useEffect(() => {
    if (dispersion) {
      dispatch(
        shoppingActions.getShoppingsProperty({ role: 0, propertyId: id })
      );
      dispatch(shoppingActions.setInsertDispersion());
    }
    if (discount) {
      dispatch(
        shoppingActions.getShoppingsProperty({ role: 0, propertyId: id })
      );
    }
  }, [dispersion, discount]);

  let cartsPropertyTemp = [];

  useEffect(() => {
    if (
      shoppingsProperty &&
      shoppingsProperty?.carts &&
      shoppingsProperty?.carts[0]?.carts
    ) {
      shoppingsProperty?.carts[0].carts.map((cart) => {
        cartsPropertyTemp.push({
          ...cart,
          status: status_id[cart.status_id - 1],
        });
      });
      setCartsProperty(cartsPropertyTemp);
    }
  }, [shoppingsProperty]);

  const showModal = (method, pay) => {
    setCurrentPay(pay);
    setMethodPay(method);
    setIsModalVisible(true);
  };

  return (
    <div className="property-detail">
      <Row gutter={32}>
        <Col lg={24} xl={24} xxl={24} >
          <div className="property-detail-header-bar"></div>
          <div className="property-detail-header">
            <Row >
              <Col lg={24}>
                {/*   <Button
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => history.goBack()}
                  style={{ marginBottom: "16px" }}
                /> */}
                <span>Propiedad: {property?.title} </span>
              </Col>
              <Col>
                <span className='property-card__subtitle'>ID  # {property?.id}</span>
              </Col>
            </Row>
            {property?.contract_active?.length == 0 && (
              <Button
                style={{
                  fontWeight: "bold",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                type="primary"
                size="middle"
                onClick={() => {
                  setIsModalVisibleCreateContract(true);
                }}
              >
                Asociar inquilino
              </Button>
            )}

          </div>
          <Spin spinning={status === "loading"}>
            <PropertyDataCard cartsProperty={cartsProperty}
              showModal={showModal}
              setTicket={setTicket}
              formatter={formmatterCurrency}
              setisModalVisibleRenterAdd={setisModalVisibleRenterAdd}
              setContractId={setContractId}
              id={id}
              isModalVisiblePayment={isModalVisiblePayment}
              setIsModalVisiblePayment={setIsModalVisiblePayment} />
          </Spin>
          <TicketModal ticket={ticket} setTicket={setTicket} />

          {isModalVisibleCreateContract && (
            <Modal
              title={"crear Contrato"}
              visible={isModalVisibleCreateContract}
              onOk={handleOkCreateContract}
              onCancel={handleCancel}
              width={1100}
              footer={null}
            >
              <RenterAddModal  
                propertyId={id}
                current={current}
                next={next}
              />
            </Modal>
          )}

          <ModalPay
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            methodPay={methodPay}
            currentPay={currentPay}
          />
        </Col>
      </Row>
    </div >
  );
};

