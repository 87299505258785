import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Button,
  Descriptions,
  Typography,
  Spin,
  Table,
  InputNumber,
} from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { contractActions } from "../../../../../../../services/Contract/ContractSlice";
import { formmatterCurrency } from "../../../../../../../common/utilities";
import { ShowButton } from "scenes/Private/components/ShowButton/ShowButton";


const Tenant = () => {
  const history = useHistory();
  const { contract, status, payents } = useSelector((state) => state.contract);
  const { Title } = Typography;
  const { id } = useParams();
  const [value, setValue] = useState();
  const [skyPayment, setSkyPayment] = useState([]);
  const dispatch = useDispatch();
  const [newValueCotract, setNewValueCotract] = useState({
    id_payment: null,
    total_amount: null,
    date_pay: null,
  });

  const formatDate = (end, start) => {
    return moment(end).diff(moment(start), "months");
  };

  const formatter = (input) => {
    return new Intl.NumberFormat("en", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    })
      .format(input)
      .replace("COP", "$")
      .replace(/,b/gi, ".");
  };

  const onChange = (e, id_payment) => {
    const total_amount = e;
    let date_pay = undefined;
    payents.map((item) => {
      if (item.id === id_payment) {
        date_pay = item.expiration_date;
      }
    });
    setNewValueCotract({
      id_payment,
      total_amount,
      date_pay,
      id: id,
    });
    setValue(null);
  };

  const columns = [
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.id - b.id,
      sortOrder: "ascend",
      render: (created_at) => {
        return <p>{moment(created_at).format("LL")}</p>;
      },
    },
    {
      title: "Valor",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (total_amount) => formatter(total_amount),
    },
    {
      title: "Nuevo Valor",
      dataIndex: "id",
      key: "action",
      render: (id) => {
        return (
          <div style={{ display: "flex" }}>
            <InputNumber
              className="New_Valor"
              onChange={(e) => onChange(e, id)}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              value={value}
            ></InputNumber>
            <br />
            <Button danger={skyPayment.find((idSkype) => id == idSkype)} style={{ marginRight: '20px' }} onClick={() => {
              if (skyPayment.find((idSkype) => id == idSkype))
                setSkyPayment((current) =>
                  current.filter(
                    (paymentID) =>
                      paymentID === id
                  )
                );
              setSkyPayment([...skyPayment, id])
            }}>Saltar pago</Button>
            {payents.map((item) => {
              if (item.id === id) {
                if (moment(new Date()) > moment(item.created_at)) {
                  return (
                    <Button type="danger" key={item.id} disabled>
                      Guardar
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      key={item.id}
                      type="primary"
                      onClick={() =>
                        dispatch(
                          contractActions.editSpecialContract(newValueCotract)
                        )
                      }
                    >
                      Guardar
                    </Button>
                  );
                }
              }
            })}
          </div>
        );
      },
    },
  ];
  return (
    <Spin spinning={contract === null}>
      <Descriptions
        title="Información del Inquilino"
        bordered
        style={{ width: "100%" }}
        layout="vertical"
      >
        <Descriptions.Item label="NOMBRE">
          {contract?.ownership.find(user => user.ownership_type_id == 2)?.user?.profile?.name}
        </Descriptions.Item>
        <Descriptions.Item label="APELLIDO">
          {contract?.ownership.find(user => user.ownership_type_id == 2)?.user?.profile?.lastname}
        </Descriptions.Item>
        <Descriptions.Item label="CORREO">
          {contract?.ownership.find(user => user.ownership_type_id == 2)?.user.email}
        </Descriptions.Item>
        <Descriptions.Item label="Ver detalle de Inquilino">
          <ShowButton
            style={{ marginRight: 10 }}
            onClick={() => history.push(`/user/${contract?.ownership.find(user => user.ownership_type_id == 2)?.user?.id}`)}
            size="small"
           />
        </Descriptions.Item>
        <Descriptions.Item label="INICIO DEL CONTRATO">
        { (contract?.started_at == "1980-01-01 00:00:00")? "Sin definir" : moment(contract?.started_at).format("LL") }
        </Descriptions.Item>
        <Descriptions.Item label="DURACIÓN DEL CONTRATO">
          {formatDate(contract?.finished_at, contract?.started_at)}{" "}
          {formatDate(contract?.finished_at, contract?.started_at) <= 1
            ? "mes"
            : "meses"}
        </Descriptions.Item>
        <Descriptions.Item label="Valor Contrato"> {contract?.costs?.map(cost => formmatterCurrency(cost.value))}</Descriptions.Item>
      </Descriptions>
  {/*     <Title level={5} style={{ marginTop: "2rem" }}>
        {" "}
        Pagos Especiales
      </Title>
      <Spin spinning={status === "loading"}>
        <Table columns={columns} dataSource={payents} bordered={true} />
      </Spin> */}
    </Spin>
  );
};

export default Tenant;
