import { createSlice } from "@reduxjs/toolkit";
import { error } from "highcharts";

const initialState = {
  discountModal: false,
  discountSelected: false,
  discounts: null,
  status: null,
  errormsg: null,
  loading: null,
  error: false,
  alertError: false,
  discountDisModal: false,
  discountDisSelected: false,
  discountsDis: null,
};

const discountsSlice = createSlice({
  name: "discounts",
  initialState: initialState,
  reducers: {
    setState(state, { payload: { key, value } }) {
      state[key] = value
    },
    onChangeStatus(state, { payload }) {
      state.loading = false;
      state.status = payload;
    },
    onError(state, { payload }) {
      state.loading = false;
      state.status = "error";
      state.errormsg = payload;
    },
    onAlert(state, { payload }) {
      state.error = payload
    },
    setLoading(state, { payload: { key, value } }) {
      state.loading[key] = value
    },
    createDiscount() { },
    updateDiscount() { },
    getDiscountDispersion() { },
    updateDiscountContract(){},
    getDiscountContract() { },
    setDiscounts(state, { payload }) {
      console.log("🚀 ~ file: DiscountsSlice.js:41 ~ setDiscounts ~ payload:", payload)
      state.discounts = payload
    },
    setDiscountsDis(state, { payload }) {
      console.log("🚀 ~ file: DiscountsSlice.js:47 ~ setDiscountsDis ~ payload:", payload)
      state.discountsDis = payload
    },
    createDiscountContract() { },
    deleteDiscount(){ },
    deleteDiscountContract(){ }
  },
});

const discountsActions = discountsSlice.actions;
const discountsReducer = discountsSlice.reducer;

export { discountsActions, discountsReducer };
