import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  status: null,
  errormsg: null,
  loading: true, 
}

const emailSlice = createSlice({
  name: "email",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    sendEmail() { }, 
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
   

  }
});

const emailActions = emailSlice.actions
const emailReducer = emailSlice.reducer

export { emailActions, emailReducer };