import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: null,
  properties: [],
  propertiesStatistics: {
    total: { total: 0, acum: 0, prom: 0, properties: [] },
    accepted: { total: 0, acum: 0, prom: 0, properties: [] },
    open: { total: 0, acum: 0, prom: 0, properties: [] },
    publicProperty: { total: 0, acum: 0, prom: 0, properties: [] },
    save: { total: 0, acum: 0, prom: 0, properties: [] },
  },
  property: undefined,
  newProperty: undefined,
  userProperties: [],
  errormsg: null,
  user: undefined,
  userRenter: undefined,
  userAdmin: undefined, 
  propertyInfo: null,
  filter: {},
  pagination: {
    total: 1,
    page: 1,
    limit: 10,
  },
  currentCreateProperty: { id: null },
  propertiesRecord: [],
  media: undefined
};

const propertySlice = createSlice({
  name: "property",
  initialState: initialState,
  reducers: {
    uploadImage() { },
    deleteImage() { },
    getStatistics() { },
    getAmenitys() { },
    setAmenitys() { },
    getProperty() { },
    getProperties() { },
    getUserProperties() { },
    getUser() { },
    getUserRenter() { },
    getUserAdmin() { },
    getPropertiesRecord() { },
    setProperties(state, { payload }) {
      state.properties = payload.data;
      state.pagination = payload.pagination;
      state.filter = payload.filter;
    },
    setStatistics(state, { payload }) {
      state.propertiesStatistics = payload;
    },
    setProperty(state, { payload }) {
      state.property = payload;
    },
    setPriority(state, { payload }) {
      state.property = payload;
    },
    setCurrentCreateProperty(state, { payload }) {

    },
    setPropertiesRecord(state, { payload }) {
      state.propertiesRecord = payload;
    },
    priority() { },
    setUserProperties(state, { payload }) {
      state.userProperties = payload;
    },
    setUser(state, { payload }) {
      state.user = payload;
    },
    setUserRenter(state, { payload }) {
      state.userRenter = payload;
    },
    setUserAdmin(state, { payload }) {
      state.userAdmin = payload;
    },
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    onError(state, { payload }) {
      state.status = "error";
      state.errormsg = payload;
    },
    propertyInfo(state, { payload }) {
      state.propertyInfo = payload
    },
    getFeature() { },
    setFeature() { },
    setPay() { },
    editProperty() { },
    editTestProperty() { },
    getMedia() { },
    setMedia(state, { payload }) {
      state.media = payload
    },
    deleteProperty(state, { payload }) {
    },
  },
});

const propertyActions = propertySlice.actions;
const propertyReducer = propertySlice.reducer;

export { propertyActions, propertyReducer };
