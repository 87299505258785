import React from 'react';
import { Space, Table, Tag } from 'antd';
import { formmatterCurrency } from "common/utilities";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import moment from 'moment'
import 'moment/locale/es'

function TagById({ id }) {
  switch (id) {
    case 0:
      return <Tag icon={<CheckCircleOutlined />} color="success">Dispersada</Tag>;
    case 1:
      return <Tag icon={<SyncOutlined spin />} color="processing">Sin generar</Tag>;
    case 6:
      return <Tag icon={<CloseCircleOutlined />} color="error">error</Tag>;
    case 3:
      return <Tag icon={<CheckCircleOutlined />} color="success">Dispersada</Tag>;
    case 4:
      return <Tag icon={<CheckCircleOutlined />} color="default">Dispersada</Tag>;
    case 5:
      return <Tag icon={<ClockCircleOutlined />} color="default">Sin generar</Tag>;
    case 2:
      return <Tag icon={<MinusCircleOutlined />} color="default">Error de datos</Tag>;
    default:
      return <Tag>Unknown</Tag>;
  }
}

function TagByIdPayment({ id }) {
  switch (id) {
    case 12:
      return <Tag icon={<CheckCircleOutlined />} color="success">Pagado</Tag>;
    case 11:
      return <Tag icon={<SyncOutlined spin />} color="processing">Pendiente</Tag>;
    case 6:
      return <Tag icon={<CloseCircleOutlined />} color="error">error</Tag>;
    case 3:
      return <Tag icon={<CheckCircleOutlined />} color="success">Dispersada</Tag>;
    case 4:
      return <Tag icon={<CheckCircleOutlined />} color="default">Dispersada</Tag>;
    case 5:
      return <Tag icon={<ClockCircleOutlined />} color="default">Sin generar</Tag>;
    case 2:
      return <Tag icon={<MinusCircleOutlined />} color="default">Error de datos</Tag>;
    default:
      return <Tag>Unknown</Tag>;
  }
}

const columns = [
  {
    title: 'Estado',
    dataIndex: 'statusId',
    key: 'statusId',
    render: (id) => <TagByIdPayment id={id} />,
  },
  {
    title: 'Dispersion',
    dataIndex: 'isDispersed',
    key: 'isDispersed',
    render: (id) => <TagById id={id} />,
  },
/*   {
    title: "Periodo",
    dataIndex: "metadata",
    key: "metadata",
    render: (metadata) =>
      metadata
        ? JSON.parse(metadata)?.canon_period
        : "---",
  }, */
  {
    title: 'valor',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (totalAmount) => <span>{formmatterCurrency(totalAmount)}</span>
  },
  {
    title: 'Fecha',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => <span>{moment(createdAt).format('ll')} </span>
  },
];

export const TablePayments = ({ data }) => <>
  <Table columns={columns} dataSource={data} pagination={false} />
  <b></b>
</>;
