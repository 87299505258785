import React, { useEffect, useState } from "react";
import { Radio, Row, Col, Select, DatePicker, Button, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { shoppingActions } from 'services/Shopping/ShoppingSlice'
import { companyActions } from "services/Company/CompanySlice";
import { propertyActions } from "services/Property/PropertySlice";
import { stateAccountActions } from "services/StatusAccount/StateAccountSlice";

export const Filter = ({ }) => {

    const { user } = useSelector((state) => state.auth);
    const { userProperties } = useSelector(state => state.property)
    const [data, setData] = useState([]);
    const [dataInmo, setDataInmo] = useState([]);

    const [propertySearch, setPropertySearch] = useState('Seleccione una propiedad');

    const today = new Date();
    const formattedDate = '';//today.toISOString().split('T')[0];
    const [startDate, setStartDate] = useState(undefined);




    const { pagination, stateAccounts } = useSelector(state => state.stateAccount)

    const dispatch = useDispatch()
    const { page, limit, total } = pagination;
    const { companys } = useSelector((state) => state.company);
    const { company } = useSelector(state => state.user)
    const [value, setValue] = useState();
    const [email, setEmail] = useState();



    const [userEmail, setUserEmail] = useState('');
    const [inmo, setInmo] = useState('Todos');

    const [error, setError] = useState('');

    useEffect(() => {
        const rolesAdmin = ["sysadmin", "admin"];
        const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))
        if (isAdmin) {
            const data = companys?.data?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setDataInmo(data)
        } else {
            const data = company?.map((item) => ({
                value: item?.company_data?.id,
                label: item?.company_data?.name,
            }));
            setDataInmo(data)
        }
    }, [companys])

    useEffect(() => {
        user && dispatch(propertyActions.getUserProperties(user.id))
    }, [user]);

    useEffect(() => {
        console.log(userProperties);
        const data = userProperties?.map((item) => ({
            value: item?.id,
            label: item?.title,
        }));
        setData(data)
    }, [userProperties])

    const handleChange = (newValue) => {
        //dispatch(companyActions.getCompanys());
        setPropertySearch(newValue);
    };

    const handleChangeInmo = (newValue) => {
        setValue(newValue);
    };

    const handleChangeEmail = (newValue) => {
        setEmail(newValue);
    };

    const handleFilter = (e) => { 
            e.preventDefault();
            if (propertySearch == "Seleccione una propiedad") {
                setPropertySearch(0);
            }
            const filterData = { 
                user_email: email,
                inmo_id: value
            };
            var timestamp ='';
            if(startDate){
                timestamp = Date.parse(startDate);
                timestamp = new Date(timestamp).toISOString().split('T')[0];
                filterData.period =  "" + timestamp;
            } 
            dispatch(stateAccountActions.getStateAccount({ filterData })) 
            setError(''); 
    };



    const { Option } = Select;
    return (
        <form onSubmit={handleFilter}>
            <Row gutter={10}>
                <Col>
                    {/*               <label>
                        <input
                            style={{
                                padding: "5px 31px",
                                height: "auto",
                                fontSize: "medium",
                            }}
                            type="date"
                            value={startDate}
                            defaultValue={formattedDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </label> */}
                    <DatePicker onChange={setStartDate} placeholder="Fecha" />

                    {/*   <br></br><br></br>
                    <label>
                        Propiedades
                    </label>
                    <Select
                        showSearch
                        value={propertySearch}
                        placeholder={"Inmobiliaria"}
                        style={{
                            width: 200, padding: "5px 31px",
                            height: "auto",
                            fontSize: "medium",
                        }}
                        optionFilterProp="children"
                        onChange={handleChange}
                        options={data}
                    >
                        <Option value="0">Todos</Option>
                    </Select>
                    <br></br>

                    <br></br> */}


                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </Col>
                <Col>
                    <Select
                        showSearch
                        value={value}
                        placeholder={"Inmobiliaria"}
                        style={{ width: 200 }}
                        optionFilterProp="children"
                        onChange={handleChangeInmo}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={dataInmo}
                    />
                </Col>
                <Col>
                    <Input 
                        value={email}
                        placeholder={"Correo usuario"}
                        style={{ width: 200 }} 
                        onChange={(e) =>handleChangeEmail(e.target.value)} 
                    ></Input>
                </Col>
                <Col>
                    {!stateAccounts.length ? <Button
                        style={{
                            borderRadius: '5px',
                            borderColor: '#d82067',
                            padding: '5px 31px',
                            height: 'auto',
                            fontSize: 'medium',
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Buscar
                    </Button> :

                        <Button className="btn-default" onClick={() => {
                            setValue(undefined);
                            setStartDate(undefined);
                            dispatch(stateAccountActions.setStateAccounts([]))
                        }}>
                            Limpiar
                        </Button>
                    }
                </Col>
            </Row >
        </form>
    );
};
