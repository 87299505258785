import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Form,
  Upload,
  Progress,
  Button
} from "antd";
import { useHistory } from "react-router-dom";
import ApiService from "../../../../common/api/Api"
import { mediaActions } from "services/Media/MediaSlice";
import ImgCrop from "antd-img-crop";

import ImgsViewer from "react-images-viewer";
import { apiUrl } from 'common/config/Environments'
import { emailActions } from "services/Email/EmailSlice";

export const ModalUploadDoc = (cart) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { mediaFiles } = useSelector(state => state.media);
  const { form } = Form.useForm;
  const [fileList, setFileList] = useState([]);
  const [fileListUp, setFileListUp] = useState([]);
  const [progress, setProgress] = useState(0);
  const [load, setLoad] = useState(0);
  const [currImg, setCurrImg] = useState(0);
  const [viewerOpen, setViewerOpen] = useState(false);

  const openImage = (index) => {
    setCurrImg(index);
    setViewerOpen(true);
  };



  useEffect(() => {
    //se obtiene la lista de adjuntos 
    if (cart != null && load == 0) {
      let cart_id = cart.cart.shoppingCartId;
      dispatch(mediaActions.getMedia({ entityId: cart_id, entityName: 'ShoppingCart' }))
      setLoad(1)
    }
  }, [cart])

  useEffect(() => {
    console.log(mediaFiles);
    let show_files = [];
    mediaFiles.forEach(
      function (element) {
        let adminData = JSON.parse(element.metadata);
        if (adminData.type) {
          show_files.push(element)
        }
      }
    );
    setFileList(show_files)
  }, [mediaFiles])

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };


  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onFinish = (values) => {
  };

  const uploadImage = async (options) => {
    const { file } = options;
    const fmData = new FormData();
    fmData.append("media", file);
    fmData.append("group", "pay_support");
    fmData.append("parent_id", parseInt(cart.cart.shoppingCartId));
    fmData.append("metadata", '{"type" : 2}');
    ApiService.uploadImage(options, fmData, setProgress);
  };


  const onPreviewAndConvertToBase64FromURL = async (file) => {
    const imageUrl = `${apiUrl}/${file.filepath}${file.filename}`; // Construye la URL de la imagen.

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob(); // Obtiene el blob de la imagen.

      const base64String = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); // Convierte el Blob a Base64.
        reader.onloadend = () => resolve(reader.result); // `reader.result` contiene la cadena Base64.
        reader.onerror = reject;
      });
      return base64String; // Devuelve o usa la cadena Base64 según sea necesario.
    } catch (error) {
      console.error("Error al convertir la imagen a Base64:", error);
    }
  };


  const sendState = async () => {
    try {

      console.log(cart);
      let email_send = "";
      if (cart.cart.userId == "1") {
        let adminData = cart.cart.metadata[0].admin_data;
        if (adminData != null) {
          email_send = (adminData.bankAccountDistribution.email)
        }
      } else {
        email_send = (cart.user?.email)
      }

      let prop_name = cart.cart.metadata[0].prop_name;
      console.log(email_send);
      console.log(prop_name);
      //se valida que del array general  exista el id en el array de seleccionados
      let file_piv = mediaFiles[0].filename;
     
      const lastDotIndex = file_piv.lastIndexOf('.');
      const extension = lastDotIndex !== -1 ? file_piv.substring(lastDotIndex + 1) : '';
      
      onPreviewAndConvertToBase64FromURL(mediaFiles[0]).then((base64Image) => {

        let label = "Comprobante pago administracion " + prop_name;
        let email_owner = email_send;
        let payload = {
          to: email_owner,
          emailKey: "message_general",
          substitutions: {
            message: label,
            subject: { "en": label, "es": label },
            button: "",
            redirect: ""

          },
          file: {
            name: "Adjunto."+extension,
            data_base_64: base64Image
          }
        };
        // servico de envio de correo
        dispatch(emailActions.sendEmail(payload));
        console.log("envie");
      });


    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <Row>
      <Row>
        {fileList?.map((img, index) => (
          <a
            onClick={() => openImage(index)}
            key={index}
            style={{ marginLeft: "20px" }}
          >
            <img
              src={`${apiUrl}/${img.filepath}${img.filename}`}
              alt={img.filename}
              style={{ height: "100px" }}
            />
          </a>
        ))}
        <ImgsViewer
          imgs={fileList?.map((img) => ({
            src: `${apiUrl}/${img.filepath}${img.filename}`,
            alt: img.filename,
          }))}
          currImg={currImg}
          isOpen={viewerOpen}
          onClickPrev={() => setCurrImg(currImg - 1)}
          onClickNext={() => setCurrImg(currImg + 1)}
          onClose={() => setViewerOpen(false)}
        />


        {fileList.length == 0 ?
          <Form form={form} onFinish={onFinish}>
            <Form.Item name="image" label="Adjunto" labelAlign="left">
              <ImgCrop rotate>
                <Upload
                  accept="image/*"
                  listType="picture-card"
                  fileList={fileListUp}
                  onChange={onChange}
                  onPreview={onPreview}
                  customRequest={uploadImage}
                >
                  {fileListUp.length < 1 && "+ Upload"}
                </Upload>
                {progress > 0 ? <Progress percent={progress} /> : null}
              </ImgCrop>
            </Form.Item>

            <br></br>
            <div
              style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
            >
            </div>
          </Form>
          :
          //se habilita el boton de enviar
          <Row>
            <Button style={{
              borderRadius: '5px',
              borderColor: '#d82067',
              padding: '10px 31px',
              height: 'auto',
              fontSize: 'medium',
              marginLeft: "15px"
            }}
              type="primary"
              htmlType="submit"
              onClick={() => sendState()}  >
              Enviar al correo
            </Button>
          </Row>
        }


      </Row>
    </Row>
  );
};
