import React, { useState } from 'react'

import { useHistory } from 'react-router'
import { formmatterCurrency } from "common/utilities";
import { ShowButton } from '../components/ShowButton/ShowButton'
import { Button } from 'antd';



import moment from 'moment'
import 'moment/locale/es'


export const columns = (isModalVisible, setIsModalVisible, cart, setCart) => {
    const history = useHistory()
    return [
        {
            title: 'id recaudo',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'id / Propiedad',
            dataIndex: 'contract',
            key: 'contract',
            render: (contract) => (
                <Button onClick={() => history.push(`/property/${contract?.property?.id}`)} type="text"><span>{contract?.property?.id} / {contract?.property?.title}</span></Button>
            )
        },
        {
            title: 'Valor',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fecha creación',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => (
                <span>{moment(date).format('LL')}</span>
            )
        },
        {
            title: 'Fecha Recaudado',
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            render: (data) => (
                <>
                    <span>{data ? `${moment(data).format('LL')}` : "Sin recaudar"}</span>
                </>
            )
        },
        {
            title: 'Estado',
            dataIndex: '',
            key: 'status',
            render: (data) => {
                let paymentStore
                switch (data.paymentMethod) {
                    case 1:
                        paymentStore = "payu"
                        break;
                    case 3:
                        paymentStore = "zona pago"
                        break;
                    case 4:
                        paymentStore = "terceros"
                        break;
                    case 5:
                        paymentStore = "Paymentez"
                        break;
                    case 6:
                        paymentStore = "Wompi";
                        break;
                }
                return <span>{data.paymentMethod ? `${data.status} por ${paymentStore}` : data.status}</span>
            }
        },
        {
            title: 'Acciones',
            key: 'action',
            dataIndex: '',
            width: '10%',
            render: (data) => (
                <>
                    <ShowButton onClick={() => { setIsModalVisible(true); setCart(data) }} />
                </>
            )
        }
    ]
}


export const columnsDispersionHistory = () => {
    return [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Concepto',
            dataIndex: 'concept',
            key: 'concept',
        },
        {
            title: 'Creado el',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
    ]
}

export const columnsDispersionData = () => {
    return [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Valor de arriendo recaudado',
            dataIndex: 'valueTotal',
            key: 'valueTotal',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fee yampi',
            dataIndex: 'valueCom',
            key: 'valueCom',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fee inmobiliaria',
            dataIndex: 'valueInmo',
            key: 'valueInmo',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fee aseguradora',
            dataIndex: 'valueSafeSecure',
            key: 'valueSafeSecure',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Valor a pagar/pagado',
            dataIndex: 'valuePay',
            key: 'valuePay',
            render: (value) => (
                <span>{formmatterCurrency(value)}</span>
            )
        },
        {
            title: 'Fecha creacion',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Pagado a',
            dataIndex: 'user',
            key: 'user',
            render: (value) => (
                <span>{value.profiles[0].name + " " + value.profiles[0].lastname}</span>
            )
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'user',
            render: (value) => (
                <span>{value.email}</span>
            )
        },
        {
            title: 'Cuenta bancaria',
            dataIndex: 'bankAccount',
            key: 'bankAccount',
            render: (value) => (
                <span>{value?.number + ' ' + value?.bank?.name}</span>
            )
        },
        {
            title: 'Fees usados',
            dataIndex: 'dispersionContext',
            key: 'dispersionContext',
            render: (value) => (
                <span>{
                    "Fee Yampi = " + value?.commission + ' ' +
                    "Fee inmobiliaria  = " + value?.commissionInmo + ' ' +
                    "Fee aseguradora  = " + value?.commissionSecure

                }</span>
            )
        },
    ]
}

