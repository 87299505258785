import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Typography,
  Form,
  Button,
  Checkbox,
  Progress,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";

import { propertyActions } from "../../../../../services/Property/PropertySlice";
import { ModCons } from "../../../../../common/constants/modCons";
import ApiService from "../../../../../common/api/Api";

export const StepDoneProperty = ({ next }) => {
  const { Title } = Typography;
  const { form } = Form.useForm;
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState(0);
  const { propertyInfo } = useSelector((state) => state.property);

  useEffect(() => {
    if (propertyInfo?.id) {
      setId(propertyInfo?.id);
    }
  }, [id]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onFinish = (values) => {
    let Amenities = [];
    const { image } = values;

    ModCons.map((item) => {
      Amenities.push({
        amenity_type: {
          id: item.id,
        },
        is_enabled: values[item.name] ? true : false,
      });
    });

    dispatch(
      propertyActions.setAmenitys({
        Amenities,
        edit: false,
        property_id: id,
      })
    );

    next();
  };

  const uploadImage = async (options) => {
    const { file } = options;
    const fmData = new FormData();

    fmData.append("media", file);
    fmData.append("group", "property_image");
    fmData.append("parent_id", id);

    ApiService.uploadImage(options, fmData, setProgress);
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="image" label="Imagen" labelAlign="left">
          <ImgCrop rotate>
            <Upload
              accept="image/*"
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              customRequest={uploadImage}
            >
              {fileList.length < 5 && "+ Upload"}
            </Upload>
            {progress > 0 ? <Progress percent={progress} /> : null}
          </ImgCrop>
        </Form.Item>

        {ModCons.map((item) => (
          <Checkbox.Group key={item.id}>
            <Row gutter={[8, 8]}>
              <Form.Item name={item.name} label={item.label}>
                <Col span={24} style={{ marginRight: "25px" }}>
                  <Checkbox value={true} />
                </Col>
              </Form.Item>
            </Row>
          </Checkbox.Group>
        ))}

        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Siguiente
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
