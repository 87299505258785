
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import { getColumnSearchProps } from "common/utilities/getColumnSearchProps";
import { ShowButton } from "../components/ShowButton/ShowButton";
import { userActions } from "services/User/UserSlice";

export const columns = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    return [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            ...getColumnSearchProps("id", useState(""), useState(""), (query) =>
                dispatch(userActions.getUsers({ data: { id: query }, op: "filter" }))
            ),
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            width: "20%",
            ...getColumnSearchProps("name", useState(""), useState(""), (query) =>
                dispatch(userActions.getUsers({ data: { name: query }, op: "filter" }))
            ),
        },
        {
            title: "Apellido",
            dataIndex: "lastname",
            key: "lastname",
            width: "20%",
            ...getColumnSearchProps("lastname", useState(""), useState(""), (query) =>
                dispatch(userActions.getUsers({ data: { last_name: query }, op: "filter" }))
            ),
        },
        {
            title: "Correo",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps("email", useState(""), useState(""), (query) =>
                dispatch(userActions.getUsers({ data: { email: query }, op: "filter" }))
            ),
        },
        {
            title: "Ciudad",
            dataIndex: "location",
            key: "location",
            ...getColumnSearchProps("location", useState(""), useState(""), (query) =>
                dispatch(userActions.getUsers({ data: { location_query: query }, op: "filter" }))
            ),
        },
        {
            title: "Acciones",
            key: "action",
            dataIndex: "id",
            width: "10%",
            render: (id) => (
                <>
                    <ShowButton onClick={() => history.push(`/user/${id}`)} />
                </>
            ),
        },
    ];
}