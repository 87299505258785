import React, { useRef, useState } from 'react'
import { Timeline, Divider, List, Tag } from 'antd';
import { formmatterCurrency } from "common/utilities";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';

import moment from 'moment'
import 'moment/locale/es'
import { TablePayments } from './sections/TablePayments';

function TagById({ id }) {
    switch (id) {
        case 0:
            return <Tag icon={<CheckCircleOutlined />} color="success">Dispersada</Tag>;
        case 1:
            return <Tag icon={<SyncOutlined spin />} color="processing">Sin generar</Tag>;
        case 6:
            return <Tag icon={<CloseCircleOutlined />} color="error">error</Tag>;
        case 3:
            return <Tag icon={<CheckCircleOutlined />} color="success">Dispersada</Tag>;
        case 4:
            return <Tag icon={<CheckCircleOutlined />} color="default">Dispersada</Tag>;
        case 5:
            return <Tag icon={<ClockCircleOutlined />} color="default">Sin generar</Tag>;
        case 2:
            return <Tag icon={<MinusCircleOutlined />} color="default">Error de datos</Tag>;
        default:
            return <Tag>Unknown</Tag>;
    }
}

export const columns = () => {
    return [
        {
            title: 'Id Propiedad ',
            dataIndex: 'id',
            key: 'id',
            render: (data) => {
                return <span>  {data}</span>
            }
        },
        {
            title: 'Propiedad ',
            dataIndex: 'title',
            key: 'title',
            render: (data) => {
                return <span>{data}</span>
            }
        },
        {
            title: 'Recaudo',
            dataIndex: 'shoppingCarts',
            key: 'shoppingCarts',
            className:'align-middle	',
            filters: [
                {
                    text: 'Pagados',
                    value: 12,
                },
                {
                    text: 'Pendientes',
                    value: 0,
                },
            ],
            onFilter: (value, record) => {
                return value == 0 ? record.shoppingCarts.find(shoppingCart => shoppingCart.statusId != 12) : record.shoppingCarts.find(shoppingCart => shoppingCart.statusId == 12)
            },

            render: (data) => {
                return <>
                    <TablePayments data={data} />
                </>
            }
        },
    ]
}

export const columnsAdmin = [
    {
        title: 'Id Propiedad ',
        dataIndex: 'id',
        key: 'id',
        render: (data) => {
            return <span>  {data?.id}</span>
        }
    },
    {
        title: 'Propiedad ',
        dataIndex: 'property',
        key: 'property',
        render: (data) => {
            return <span>{data?.title}</span>
        }
    },
    {
        title: 'Administracion',
        dataIndex: 'costs',
        key: 'costs',
        filters: [
            {
                text: 'Pagados',
                value: 12,
            },
            {
                text: 'Pendientes',
                value: 0,
            },
        ],
        onFilter: (value, record) => {
            return value == 0 ? record.shoppingCarts.find(shoppingCart => [1, 2, 5, 6].includes(shoppingCart.isDispersed)) : record.shoppingCarts.find(shoppingCart => [0, 3, 4].includes(shoppingCart.isDispersed))
        },

        render: (data) =>
            <List
                bordered
                dataSource={data}
                renderItem={(item) => (
                    item.costTypeId == 2 ?
                        <List.Item>
                            {/* <TagById id={item.isDispersed} /> */}
                            <span>{formmatterCurrency(item.value)} </span>
                        </List.Item> : ''
                )}
            />
    },
    {
        title: 'Dispersion',
        dataIndex: 'shoppingCarts',
        key: 'shoppingCarts',

        render: (data) =>
            <List
                bordered
                dataSource={data}
                renderItem={(item) => (
                    <List.Item>
                        <TagById id={item.isDispersed} />
                    </List.Item>
                )}
            />
    },
]

export const columnsOwner = [
    {
        title: 'Id Propiedad ',
        dataIndex: 'property',
        key: 'property',
        render: (data) => {
            return <span>  {data?.id}</span>
        }
    },
    {
        title: 'Propiedad ',
        dataIndex: 'property',
        key: 'property',
        render: (data) => {
            return <span>{data?.title}</span>
        }
    },
    {
        title: 'Pago a propietario',
        dataIndex: 'costs',
        key: 'costs',
        filters: [
            {
                text: 'Pagados',
                value: 12,
            },
            {
                text: 'Pendientes',
                value: 0,
            },
        ],
        onFilter: (value, record) => {
            return value == 0 ? record.shoppingCarts.find(shoppingCart => shoppingCart.statusId != 12) : record.shoppingCarts.find(shoppingCart => shoppingCart.statusId == 12)
        },

        render: (data) =>
            <List
                bordered
                dataSource={data}
                renderItem={(item) => (
                    item.costTypeId == 1 ?
                        <List.Item>
                            <span>{formmatterCurrency(item.value)} </span>
                        </List.Item> : ''
                )}
            />
    },
    {
        title: 'Dispersion',
        dataIndex: 'shoppingCarts',
        key: 'shoppingCarts',

        render: (data) =>
            <List
                bordered
                dataSource={data}
                renderItem={(item) => (
                    <List.Item>
                        <TagById id={item.isDispersed} />
                    </List.Item>
                )}
            />
    },
]