import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Spin, Row, Col } from 'antd'
import { columns, columnsAdmin, columnsOwner } from './constans'

import { ConciliationPageHeader } from './sections/ConcilationsPageHeader'
import { StatisticsSection } from './sections/StatisticsSection'
import { ConciliationModal } from './sections/ConcilationModal'

export const Conciliation = () => {
	const { conciliation, status } = useSelector(state => state.paymentSend)

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [typeModal, setTypeModal] = useState("")
	const [tableData, setTableData] = useState([])
	const currentColumn = columns()

	const showModal = (type) => {
		console.log("🚀 ~ showModal ~ type:", type)
		setTypeModal(type)
		const transformedData = conciliation.payments[0]?.user?.ownerships.map(ownership => ({
			id: ownership.property.id,
			title: ownership.property.title,
			shoppingCarts: ownership.property.contracts.flatMap(contract => contract.shoppingCarts)
		}))
		setTableData(transformedData)
		setIsModalOpen(true)
	}

	const handleOk = () => {
		setIsModalOpen(false)
	}

	const handleCancel = () => {
		setIsModalOpen(false)
	}

	const getColumn = (typeModal) => {
		switch (typeModal) {
			case "payment":
				return currentColumn
			case "admin":
				return columnsAdmin
			case "owner":
				return columnsOwner
			default:
				return columnsAdmin
		}
	}

	return (
		<div className="conciliation">
			<ConciliationPageHeader />
			<Spin spinning={status === "loading"}>
				<div style={{ background: '#ffff', borderRadius: '12px', padding: 25, boxShadow: '0 20px 27px rgb(0 0 0/5%)' }}>
					<Row gutter={16}>
						<StatisticsSection
							sectionTitle="Total arriendos"
							statistics={[
								{ title: 'Total Cobrado', value: conciliation?.totalCharged },
								{ title: 'Total Pagado', value: conciliation?.totalCollected },
								{ title: 'Descuentos', value: conciliation?.discounts },
								{ title: 'Pendientes por recaudar', value: conciliation?.pendingCollection },
							]}
							showModal={showModal}
							type="payment"
						/>
						<StatisticsSection
							sectionTitle="Propietarios"
							statistics={[
								{ title: 'Total', value: conciliation?.owners },
								{ title: 'Total pagado a propietarios', value: conciliation?.ownersCollected },
								{ title: 'Descuentos', value: '0' },
								{ title: 'Total por pagar o pendientes', value: conciliation?.ownersPending },
							]}
							showModal={showModal}
							type="owner"
						/>
						<StatisticsSection
							sectionTitle="Administraciones"
							statistics={[
								{ title: 'Total administraciones', value: conciliation?.administrations },
								{ title: 'Total de admistraciones pagadas', value: conciliation?.administrationsCollected },
								{ title: 'Total administraciones por pagar', value: conciliation?.administrationsPending },
							]}
							showModal={showModal}
							type="admin"
						/>
						<StatisticsSection
							sectionTitle="Conciliación"
							statistics={[
								{ title: 'Total recaudado', value: conciliation?.totalCollected },
								{ title: 'Total pagado propietarios', value: conciliation?.ownersCollected },
								{ title: 'Total pagado administraciones', value: conciliation?.administrationsCollected },
								{ title: 'Total Yampi', value: conciliation?.yampi },
								{ title: 'Total fianza / seguro', value: conciliation?.afianzamiento + conciliation?.aseguradora },
								{ title: 'Saldo', value: conciliation?.inmo },
							]}
						/>
					</Row>
				</div>
			</Spin>
			<ConciliationModal
				isModalOpen={isModalOpen}
				handleOk={handleOk}
				handleCancel={handleCancel}
				columns={getColumn(typeModal)}
				dataSource={tableData}
			/>
		</div>
	)
}
