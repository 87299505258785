import React from 'react'
import { Modal, Table } from 'antd'

export const ConciliationModal = ({ isModalOpen, handleOk, handleCancel, columns, dataSource }) => {
    return (
        <Modal title="Total " visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000} destroyOnClose={true}>
            <Table
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 400 }}
            />
        </Modal>
    )
}
