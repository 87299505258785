import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { DollarOutlined, RedoOutlined, BankOutlined, UploadOutlined, StarOutlined } from "@ant-design/icons"; 
import { ShowButton } from '../components/ShowButton/ShowButton'
import { formmatterCurrency } from 'common/utilities';
import moment from 'moment';
import { billingActions } from 'services/Billing/BillingSlice';



export const columns = (isModalVisible, setIsModalVisible,bill,setBill) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { pagination, filter } = useSelector(state => state.billing)
    const { page,limit } = pagination

    const getBillDetail = (data) => {
        console.log("entre service"+data);
        dispatch(billingActions.getListDescription({ page, limit, filter:{bill_id:data} }))
        console.log("sali service");
    }

    const regenBill = (data) => {
        console.log("entre service"+data);
        
    }


    return [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Cantidad contratos facturados',
            dataIndex: 'itemsBillings',
            key: 'itemsBillings'
        },
        {
            title: 'Total facturado',
            dataIndex: 'valueBilling',
            key: 'valueBilling',
            render: (data) => {
                return <>{formmatterCurrency(data)}</>
            }
        },
        {
            title: 'Fecha creacion',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (data) => {
                return moment(data).format("LL")
            }

        },
        {
            title: 'Acciones',
            key: 'action',
            dataIndex: '',
            render: (data) => {
                let label = "";
                label = <>
                    <ShowButton icon={<BankOutlined />} onClick={() => { getBillDetail(data.id); history.push(`/billing/${data.id}`) }} /> ver detalles
                   
                    <ShowButton onClick={() => { setIsModalVisible(true); setBill(data) }} />preview

                    <ShowButton icon={<RedoOutlined />} onClick={() => {  regenBill(data) }} />Regenerar

                </>
                return label;
            }
        }

    ]
}

export const columnsDetail = (isModalVisible, setIsModalVisible, billItem, setBillItem) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { pagination, filter } = useSelector(state => state.sysblueprint)
    const { limit } = pagination

    return [ 
        {
            title: 'Propiedad',
            dataIndex: '',
            key: '',
            render: (data) => {
                return <>{(data.metadata.property.title)}</>
            }
        },
        {
            title: 'Total facturado',
            dataIndex: 'valueBilling',
            key: 'valueBilling',
            render: (data) => {
                let label = formmatterCurrency(data); 
                if(data == 0){
                    label +=  " - dentro de tarifa fija"
                }
                return label;
            }
        }, 
        {
            title: 'Acciones',
            key: '',
            dataIndex: '',
            render: (data) => {
                let label = "";
                label = <> 
                    <ShowButton icon={<BankOutlined />}   onClick={() => { setIsModalVisible(true); setBillItem(data) }} /> 
                </>
                return label; 
            }
        }

    ]
}

export const tablePaginationBill = () => {
    const dispatch = useDispatch()
    const { pagination, filter } = useSelector(state => state.billing)
    const { page, total } = pagination
    const { limit } = pagination

    const handlePagination = (page, limit) => {
        dispatch(billingActions.getList({ page, limit, filter }))
    }

    return {
        onChange: handlePagination,
        hideOnSinglePage: true,
        current: page,
        pageSize: limit,
        total
    }
}

export const tablePaginationBillDetail = () => {
    const dispatch = useDispatch()
    const { pagination, filter } = useSelector(state => state.billing)
    const { page, total } = pagination
    const { limit } = pagination

    const handlePagination = (page, limit) => {
        dispatch(billingActions.getListDescription({ page, limit, filter }))
    }

    return {
        onChange: handlePagination,
        hideOnSinglePage: true,
        current: page,
        pageSize: limit,
        total
    }
}


export const columnsBill = () => {

    return  [
        {
          title: 'Item',
          dataIndex: '',
          key: '',
          render: (text, record, index) => {
            return <>{index + 1}</>;
          }
        },
        {
          title: 'Descripcion',
          dataIndex: '0',
          key: '',
        }
        ,
        {
          title: 'Cantidad',
          dataIndex: '2',
          key: 'id',
        }
        ,
        {
          title: 'Vr. Unitario',
          dataIndex: '3',
          key: '',
          render: (data) => {
            return <>{formmatterCurrency(data)}</>
        }
        }
        ,
        {
          title: 'Vr. Bruto',
          dataIndex: '3',
          key: '',
          render: (data) => {
            return <>{formmatterCurrency(data)}</>
        }
        }
        ,
        {
          title: 'Total',
          dataIndex: '3',
          key: '',
          render: (data) => {
            return <>{formmatterCurrency(data)}</>
        }
        }
      ];
}

export const columnsBillDetail = () => {
    return [ 
        {
            title: 'Propiedad',
            dataIndex: '',
            key: '',
            render: (data) => {
                return <>{(data.metadata.bill_plan_name.name+" : "+ data.metadata.property.title)}</>
            }
        },
        {
            title: 'Total facturado',
            dataIndex: 'valueBilling',
            key: 'valueBilling',
            render: (data) => {
                let label = formmatterCurrency(data);  
                return label;
            }
        }  
    ]
}