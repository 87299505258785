import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { sysBlueprintActions } from "./SysBlueprintSlice"; 

function* getList({ payload }) {
  yield put(sysBlueprintActions.onChangeStatus("loading"));
  try {
    const { page, limit, filter } = payload;
    const response = yield ApiService.requestPost(`/sys-blueprint/list_sys?page=${page}`, {
      ...filter,
      limit,
    }); 
    let {items, meta} = response; 
    if (response.response) {
      const pagination = { page, limit, meta }; 
      yield put(sysBlueprintActions.setLists({ items: [], pagination, filter }));
    } else { 
      const pagination = { page, limit, meta };
      yield put(sysBlueprintActions.setLists({ items, pagination, filter }));
    } 
    yield put(sysBlueprintActions.onChangeStatus("success"));
  } catch (err) {
    yield put(sysBlueprintActions.onError(err.toString()));
  }
}
  
function* ActionWatcher() {
  yield takeLatest(sysBlueprintActions.getList, getList); 
}

export default function* rootSaga() {
  yield all([ActionWatcher()]);
}
