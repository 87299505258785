import React, { useEffect } from "react";
import { Collapse, Row, Col, Tabs } from "antd";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router";

import { contractActions } from "../../../../../../services/Contract/ContractSlice";
import Owner from "./components/Owner";
import Tenant from "./components/Tenant";
import Discounts from "./components/Discounts";
import Dispersions from "./components/Dispersions";
import { ContractCard } from './sections/ContractCard'
import { shoppingActions } from 'services/Shopping/ShoppingSlice'
import { Payment } from "./sections/Payment";
import { ContractFeesCard } from './sections/ContractFeesCard'; 
import ContractConfigurations from "./components/ContractConfigurations";

export const ContractModal = ({ contractId, setContractId }) => {
  const history = useHistory();
  const { contract_id } = useParams();
  const { Panel } = Collapse;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(contractActions.getContract(contract_id));
    dispatch(contractActions.getPayents({ contract_id: contract_id }));

    return () => {
      dispatch(contractActions.setContract());
    };
  }, [contractId]);



  return (
    <>
      <Row gutter={32}>
        <Col span={8} >
          <ContractCard />
        </Col>
        <Col span={16} >
          <div className="property-detail-header">
            <span>Detalle de Contrato</span>
          </div>
          <div style={{ background: '#ffff', borderRadius: '12px', padding: 20, boxShadow: '0 20px 27px rgb(0 0 0/5%)' }}>
            <Tabs defaultActiveKey="1" tabBarStyle={{ fontSize: '22px' }} size='small'>
              <Tabs.TabPane tab="Propietario" key="1" >
                <Owner />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Inquilino" key="2" >
                <Tenant />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Tarifas" key="6" >
                <ContractFeesCard />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Dispersiones de usuario" key="3" >
                <Dispersions />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Recaudos" key="4" >
                <Payment />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Descuentos y adiciones" key="5" >
                <Discounts />
              </Tabs.TabPane>
              {/*          <Tabs.TabPane tab="Inventarios" key="5" >
                <InventoryCard />
              </Tabs.TabPane> */}
              <Tabs.TabPane tab="Configuracion" key="7" >
              <ContractConfigurations/>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </>
  );
};
