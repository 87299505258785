import React, { useState, useEffect } from "react";
import { Modal, Col, Row, Divider, Button, Form, Input, Select, InputNumber } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { discountsActions } from "services/Discounts/DiscountsSlice";

export const DiscountDispersionModal = ({ visible, discount, contractId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (discount) {
      form.setFieldsValue({
        ...discount,
        recurrent: discount.recurrent === 1,
        type: discount.type === 1,
        is_active: discount.is_active === 1,
      });
    }
  }, [discount, form]);

  const hideModal = () => {
    dispatch(discountsActions.setState({ key: "discountDisModal", value: false }));
    dispatch(discountsActions.setState({ key: "discountDisSelected", value: null }));
  };

  const handleFinish = (values) => {
    values['contract_id'] = parseInt(contractId);
    if (discount) {
      dispatch(discountsActions.updateDiscount({ id: discount.id, ...values }));
    } else {
      dispatch(discountsActions.createDiscount(values));
    }
  };

  return (
    <Modal
      title={`Detalle del descuento a la dispersión`}
      visible={visible}
      onOk={hideModal}
      onCancel={hideModal}
      width="50%"
      className="ticket-modal"
    >
      <Form layout="vertical" name="createDiscount" form={form} onFinish={handleFinish}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="concept"
              label="Descripción del descuento"
              rules={[
                {
                  required: true,
                  message: "Ingrese la descripción",
                },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="value"
              label="Valor"
              rules={[
                {
                  required: true,
                  message: "Ingrese el valor del descuento",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                size="large"
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="recurrent"
              label="Frecuencia"
              rules={[
                {
                  required: true,
                  message: "Seleccione la frecuencia",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                size="large"
                options={[
                  { value: true, label: "Periódico" },
                  { value: false, label: "Única vez" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="type"
              label="Tipo"
              rules={[
                {
                  required: true,
                  message: "Seleccione el tipo",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                size="large"
                options={[
                  { value: false, label: "Descuento" },
                  { value: true, label: "Adición" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="is_active"
              label="Estado"
              rules={[
                {
                  required: true,
                  message: "Seleccione el estado",
                },
              ]}
            >
              <Select
               size="large"
                style={{ width: "100%" }}
                options={[
                  { value: false, label: "Inactivo" },
                  { value: true, label: "Activo" },
                ]}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                {discount ? "Editar" : "Agregar"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
