
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, InputNumber, Input, Alert } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { urlYampiWeb } from "../../../../../common/config/Environments";
import { twilioActions } from 'services/Twilio/TwilioSlice';
import { userActions } from "services/User/UserSlice";

import { usePayuData } from '../hooks';
import { onChangeNumber } from '../constants'
import { BsLink } from "react-icons/bs";
import moment from "moment";
import "moment/locale/es";



export const ModalPay = ({ isModalVisible, setIsModalVisible, methodPay, currentPay }) => {
    const [isCopylVisible, setIsCopylVisible] = useState(false);
    const [urlPayW, setUrlPayW] = useState("https://api.whatsapp.com/send");
    const [urlPay, setUrlPay] = useState("");
    const [phoneSend, setphoneSend] = useState("0");
    const [dayPay, setDayPay] = useState("");


    moment.locale("co");

    const dispatch = useDispatch()
    const { property, userRenter } = useSelector(
        (state) => state.property
    );
    const { user } = useSelector(
        (state) => state.user
    );
    const { contract } = useSelector((state) => state.contract);


    const { result, payData } = usePayuData();

    const handleOk = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (methodPay) {
            URL_PAY()
        }
    }, [currentPay])

    useEffect(() => {
        if (payData) {
            setUrlPay(payData);
        }
    }, [payData])

    useEffect(() => {
        if (contract) {
            if (contract.gen_payment_at != null) {
                setDayPay(contract.gen_payment_at)
            } else {
                setDayPay(moment(contract?.started_at).format("DD"))
            }
        }
    }, [contract])

    const URL_PAY = () => {
        switch (methodPay) {
            case "yampiPasarela":
                let urlYampi = `${urlYampiWeb}/pagos/wompi/${currentPay.id}`;
                setUrlPay(urlYampi);
                break;
            case "yampiPaymentez":
                setUrlPay("");
                result(currentPay.user_id, currentPay.id)
                break;
            default:
                break;
        }
    };

    const sendMessage = async (type) => {
        if (phoneSend != 0) {
            //se obtiene el nombre del inquilino, propiedad e inmobiliaria 
            let inmo_name = "Yampi";
            user?.company.forEach(
                function (element) {
                    if (element.company_id != 1) {
                        inmo_name = element.company_data.name;
                    }
                }
            );
            const full_name = userRenter?.profile?.name + " " + userRenter?.profile?.lastname;
            // el salto de linea es obligatoriopor el template de twilio si hay cambios revisar este tipo de cosas
            const date_start = moment(new Date().setDate(parseInt(dayPay)));
            const date_end = moment(new Date().setDate(parseInt(dayPay) + 4));
            let message = `Hola ${full_name}, el próximo ${date_start.format(
                'll',
            )}  arranca un nuevo mes de tu contrato de arrendamiento sobre la propiedad  ${property.title}. Recuerda que la fecha de pago va desde ${date_start.format(
                'll',
            )} hasta el ${date_end.format(
                'll',
            )}; si ${inmo_name} tiene recaudo electrónico a continuación encontraras el enlace de pago : `;

            let link_payment = "_";
            if (type == 1) {// con link
                link_payment = 'https://web.yampi.co/pagos/wompi/' + currentPay.id;
            }
            message = message + link_payment;
            message = message + " RECUERDA QUE ESTA LÍNEA SOLO ES PARA NOTIFICACIONES. SI QUIERES CONTACTARTE CON NUESTRO EQUIPO DE SERVICIO AL CLIENTE, HAZ CLIC EN EL SIGUIENTE ENLACE: wa.link/6pb1qe";

            dispatch(twilioActions.sendMessage(
                {
                    "phone": phoneSend,
                    "userId": userRenter.id,
                    "title": "mensaje de pago",
                    "message": message,
                    "media_url": "",
                    "statusCallback": "https://api.yampi.co/twilio_callback"
                }
            ))
        }

    }

    return (
        <Modal
            title={`Mensaje de pago`}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleOk}
            destroyOnClose={true}
        >
            {isCopylVisible ? (
                <Alert message="Copiado al portapapeles" type="success" showIcon />
            ) : null}
            <div style={{ width: "80%", margin: "0 auto" }}>
                <div
                    style={{
                        display: "flex",
                        marginBottom: 30,
                        justifyContent: "center",
                    }}
                >
                    <img
                        style={{ width: 150 }}
                        src={
                            {
                                payu: "https://colombia.payu.com/wp-content/themes/global-website/assets/src/images/payu-logo.svg",
                                yampiPasarela: "https://web.yampi.co/_next/image?url=%2Fimages%2Flogo.png&w=128&q=75",
                                yampiPaymentez: "https://www.ccce.org.co/wp-content/uploads/2019/09/paymentez-1-01-300x300.jpg"
                            }[methodPay]
                        }
                    />
                </div>
                <div style={{ display: "flex" }}>
                    <Input
                        style={{ marginBottom: 20 }}
                        value={urlPay}
                        disabled
                    />
                    <CopyToClipboard
                        text={urlPay}
                        onCopy={() => {
                            setIsCopylVisible(true);
                            setTimeout(() => {
                                setIsCopylVisible(false);
                            }, 2000);
                        }}
                    >
                        <Button>Copiar</Button>
                    </CopyToClipboard>
                </div>
                <p style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}>
                    ¿Cual es el número de teléfono?
                </p>
                <InputNumber
                    size={"large"}
                    style={{ width: "100%", marginBottom: 20 }}
                    onChange={(phone) => {
                        setphoneSend(phone);
                        setUrlPayW(onChangeNumber(phone, property, currentPay, methodPay, urlPay))
                    }}
                />
                <span style={{ display: 'block', marginBottom: '20px' }}>Que mensaje quiere enviar al usuario:</span>
                <Button
                    icon={<WhatsAppOutlined style={{ color: "#1ad741" }} />}
                    type="default"
                    onClick={() => sendMessage(1)}>
                    Mensaje con link
                </Button>
                <br></br><br></br>
                <Button
                    icon={<WhatsAppOutlined style={{ color: "#1ad741" }} />}
                    type="default"
                    onClick={() => sendMessage(2)}>
                    Mensaje de recordatorio
                </Button>
            </div>
        </Modal >
    );
};

