import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Spin, Radio, Row, Col, PageHeader, Modal, Button } from 'antd'

import { stateAccountActions } from 'services/StatusAccount/StateAccountSlice'
import { companyActions } from "services/Company/CompanySlice";

import { columns } from './constans'
import { Filter } from './sections/Filter'
import { ModalStateDetailData } from './sections/ModalStateDetailData'
import ReactExport from "react-export-excel";
import { formmatterCurrency } from "common/utilities";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PDF from './pdf_gen/PDF';
import { ShowButton } from '../components/ShowButton/ShowButton';
import { ModalSendStateAccount } from './sections/ModalSendStateAccount';
import { ModalStateData } from './sections/ModalStateData';


export const StateAccount = () => {

  const dispatch = useDispatch()
  const { stateAccounts, status, pagination } = useSelector(state => state.stateAccount)
  const { user, } = useSelector(state => state.auth)
  const { page, limit, total } = pagination
  const [selectionType, setSelectionType] = useState('11');
  const [current, setCurrent] = useState(0);

  const [isModalVisible, setIsModalVisible] =
    useState(false);

  const [isModalVisibleSend, setIsModalVisibleSend] =
    useState(false);

  const [isModalBreakdownVisible, setIsModalBreakdownVisible] =
    useState(false);

  const [stateAc, setStateAc] =
    useState(false);

  const [userinfo, setUserInfo] = useState({
    regIds: [],
    response: [],
  });

  const currentColumn = columns(isModalVisible, setIsModalVisible, stateAc, setStateAc, userinfo, setUserInfo, isModalBreakdownVisible, setIsModalBreakdownVisible);

  const handleOk = () => {
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setStateAc(false);
  };

  const handleCancelbReackdown = () => {
    setIsModalBreakdownVisible(false);
    setStateAc(false);
  };

  const handleOkSend = () => {
  };

  const handleCancelSend = () => {
    setIsModalVisibleSend(false);
    setStateAc(false);
  };

  useEffect(() => {
    dispatch(companyActions.getCompanys());
    return (() => {
      dispatch(stateAccountActions.setStateAccounts([]))
    })
  }, [])



  const handlePagination = (page, limit) => {
  }

  const tablePagination = {
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: limit,
    total
  }

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  return (
    <div className="user">
      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888' }}
        extra={[
          <Filter />
        ]}
      />
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <>
              <Row justify="space-between">
                <Col flex={1} > <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, margin: '25px 10px' }}>Estados de cuenta</span></Col>
                <Col> 
                  {stateAccounts.length ?
                    <><Button style={{
                      borderRadius: '5px',
                      borderColor: '#d82067',
                      padding: '5px 31px',
                      height: 'auto',
                      fontSize: 'medium',
                    }}
                      type="primary"
                      htmlType="submit"
                      onClick={() => { setIsModalVisibleSend(true); setStateAc(stateAccounts) }}>
                      Enviar Estados
                    </Button>  </> : ""
                  }

                </Col>
              </Row>
            </>
          }
          columns={currentColumn}
          dataSource={stateAccounts}
          scroll={{ x: 400 }}
          pagination={tablePagination}
        />
      </Spin>
      {isModalVisible &&
        <Modal
          title={"Informacion de dispersion"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalStateDetailData
            stateData={stateAc}
          />
        </Modal>
      }
      {isModalVisibleSend &&
        <Modal
          title={"Envio de estados de cuenta"}
          visible={isModalVisibleSend}
          onOk={handleOkSend}
          onCancel={handleCancelSend}
          width={1100}
          footer={null}
        >
          <ModalSendStateAccount
            stateData={stateAc}
          />
        </Modal>
      }
      {isModalBreakdownVisible &&
        <Modal
          title={"Info de estado de cuenta"}
          visible={isModalBreakdownVisible}
          onOk={handleOkSend}
          onCancel={handleCancelbReackdown}
          width={1100}
          footer={null}
        >
          <ModalStateData
            stateData={stateAc[0]}
          />
        </Modal>
      }
    </div>
  )
}