import React, { useState, useEffect } from "react";
import { Modal, Divider, Button, Form, Input, Select, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { contractDispersionActions } from "services/ContractDispersion/ContractDispersionSlice";

const { Option } = Select;

export const ContractDispersionModal = ({ visible, dispersionSelected, contract }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [messageAdmin, setMessageAdmin] = useState("");

  useEffect(() => {
    if (dispersionSelected) {
      form.setFieldsValue({
        ...dispersionSelected,
        ...dispersionSelected.bank_acc_dist,
      });
    }
  }, [dispersionSelected]);

  const hideModal = () => {
    dispatch(contractDispersionActions.setState({ key: "contractDispersionModal", value: false }));
    dispatch(contractDispersionActions.setState({ key: "dispersionSelected", value: null }));
  };

  const handleFinish = (values) => {
    values.contract = parseInt(contract.id);
    values.contract_id = parseInt(contract.id);
    values.con_dis_id = dispersionSelected?.id ?? null;
    values.bank_account_distribution_id = dispersionSelected?.bank_account_distribution_id;
    values.bank_acc_dis_id = dispersionSelected?.bank_account_distribution_id;
    values.type = 0; // Fijo por el momento (0: valor fijo, 1: valor porcentual)

    if (dispersionSelected) {
      dispatch(contractDispersionActions.updateDispersion({ id: dispersionSelected.id, ...values }));
    } else {
      dispatch(contractDispersionActions.createDispersion(values));
    }
  };

  const changeValue = (value) => {
    const cost_admin = contract?.costs.find((cost) => cost?.cost_type_id === 2)?.value ?? 0;
    setMessageAdmin(value ? "" : "Sin valor definido");
    form.setFieldsValue({ value: cost_admin });
  };

  return (
    <Modal
      title="Detalle del descuento"
      visible={visible}
      onOk={() => {
        // Ejecutar alguna acción antes de cerrar el modal
        form.submit(); // Enviar el formulario
        hideModal(); // Cerrar el modal
      }}
      onCancel={hideModal}
      width="70%"
      className="ticket-modal"
    >
      <Divider orientation="left" style={{ marginTop: 0 }}>Información de la Distribución</Divider>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        style={{ maxWidth: 600, margin: "0 auto" }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="concept"
              label="Tipo de distribución"
              rules={[{ required: true, message: "Seleccione el tipo de distribución" }]}
            >
              <Select placeholder="Seleccione un tipo" onChange={changeValue}>
                <Option value={0}>Distribución Personalizada</Option>
                <Option value={1}>Administración de Inmueble</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="value"
              label="Valor de la transferencia"
              rules={[{ required: true, message: "Ingrese el valor de la transferencia" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="priority"
              label="Prioridad de la transferencia"
              rules={[{ required: true, message: "Ingrese la prioridad de la transferencia" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="docid_type_id"
              label="Tipo de documento"
              rules={[{ required: true, message: "Seleccione el tipo de documento" }]}
            >
              <Select placeholder="Seleccione un tipo de documento">
                <Option value={1}>Pasaporte</Option>
                <Option value={2}>Licencia de Conducción</Option>
                <Option value={3}>Cédula de Ciudadanía</Option>
                <Option value={4}>Documento Extranjero</Option>
                <Option value={5}>NIT</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="num_id"
              label="Número de documento"
              rules={[{ required: true, message: "Ingrese el número de documento" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="name"
              label="Nombres"
              rules={[{ required: true, message: "Ingrese los nombres" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="lastname"
              label="Apellidos"
              rules={[{ required: true, message: "Ingrese los apellidos" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="bank_id"
              label="Banco"
              rules={[{ required: true, message: "Seleccione un banco" }]}
            >
              <Select placeholder="Seleccione un banco">
                <Option value={1}>Bank of America</Option>
                <Option value={2}>Banco Agrario</Option>
                <Option value={3}>Banco AV Villas</Option>
                <Option value={4}>Banco Caja Social</Option>
                <Option value={5}>Banco de Occidente</Option>
                <Option value={6}>Banco Popular</Option>
                <Option value={7}>Bancolombia</Option>
                <Option value={8}>BBVA Colombia</Option>
                <Option value={9}>Banco de Bogotá</Option>
                <Option value={10}>Citi Colombia</Option>
                <Option value={11}>Colpatria</Option>
                <Option value={12}>Davivienda</Option>
                <Option value={13}>GNB Sudameris</Option>
                <Option value={14}>ITAU CORPBANCA COLOMBIA </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="type_prod_serv"
              label="Tipo de producto bancario"
              rules={[{ required: true, message: "Seleccione un tipo de producto bancario" }]}
            >
              <Select placeholder="Seleccione un tipo de producto">
                <Option value={1}>Ahorros</Option>
                <Option value={2}>Corriente</Option>
                <Option value={3}>Convenio</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="num_prod_serv"
              label="Número del producto bancario"
              rules={[{ required: true, message: "Ingrese el número del producto bancario" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="email"
              label="Email del destinatario"
              rules={[{ required: true, message: "Ingrese el correo electrónico del destinatario" }]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>

        {/*<Form.Item>
          <Button type="primary" htmlType="submit">
            {dispersionSelected ? "Editar" : "Agregar"}
          </Button>
        </Form.Item>*/}
      </Form>
    </Modal>
  );
};
