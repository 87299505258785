import { put, all, takeLatest } from 'redux-saga/effects';
import ApiService from '../../common/api/Api';
import { docIdTypeActions } from './DocIdTypeSlice';

function* getDocIdType({ payload }) {
  yield put(docIdTypeActions.onChangeStatus('loading'))
  try {
    const docIds = yield ApiService.get('/docid-type', payload)
    yield put(docIdTypeActions.setdocIdTypeList(docIds))
    yield put(docIdTypeActions.onChangeStatus('success'))
  } catch (err) {
    yield put(docIdTypeActions.onError(err.toString()))
  }
}

function* SaveDocId({ payload }) {
  try {
    yield put(docIdTypeActions.onChangeStatus('loading'))
    const response = yield ApiService.post(`phone`, {
      "docidable_id": payload.userId,
      "docid_type_id": payload.docidTypeId,
      "code": payload.code,
      "issue_date": payload.issueDate,
      "issue_location": payload.issueLocation
    });
    yield put(docIdTypeActions.onChangeStatus('success'))
  } catch (err) {
    yield put(docIdTypeActions.onError('No se pudo registrar el documento'))
  }
}

function* ActionWatcher() {
  yield takeLatest(docIdTypeActions.getDocIdType, getDocIdType)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ]);
}
