import React from 'react'
import { Button } from 'antd'
import { EyeOutlined, DollarOutlined } from '@ant-design/icons'


export const ShowButton = ({ onClick, size, icon, style, label }) => (
  <Button
    type="text"
    shape="circle"
    style={style}
    icon={icon ? icon : <EyeOutlined />}
    onClick={onClick}
    size={size || 'middle'}
  >
    {label ? label : ''}
  </Button>
)