import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { logger } from "redux-logger";

import rootSaga from "./Sagas";
import rootReducers from "./Reducers";

let sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({
    thunk: false, serializableCheck: false
  })
    .concat(sagaMiddleware)
    /* .concat(logger), */
];

const store = configureStore({
  reducer: rootReducers,
  middleware,
});

sagaMiddleware.run(rootSaga);

export default store;
