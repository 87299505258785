import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  shoppings: [],
  filterShoppings: {},
  shopping: undefined,
  shoppingTickets: [],
  editPay: undefined,
  status: null,
  errormsg: null,
  shoppingInfo: null,
  loading: false,
  shoppingsProperty: undefined,
  zonaPago: undefined,
  discount: undefined,
  payu: undefined,
  dispersion: undefined,
  dispersionData: undefined,
  pagination: {
    total: 1,
    page: 1,
    limit: 10
  }
}

const shoppingSlice = createSlice({
  name: "shopping",
  initialState: initialState,
  reducers: {
    getShoppingsProperty() { },
    editPay() { },
    setEditPay(state, { payload }) {
      state.editPay = payload
    },
    setShoppingsProperty(state, { payload }) {
      state.shoppingsProperty = payload
    },
    getShoppings(state, { payload }) {
      state.filterShoppings = payload.date_filter
    },
    setShoppings(state, { payload }) {
      state.shoppings = payload.data
      state.pagination = payload.pagination
    },
    zonaPagosInitPayment(state, { payload }) {
      state.zonaPago = undefined
    },
    setZonaPagosInitPayment(state, { payload }) {
      state.zonaPago = payload
    },
    insertDispersion(state, { payload }) { },
    setInsertDispersion(state, { payload }) {
      state.dispersion = payload
    },
    discount(state, { payload }) {
      console.log(payload, "trest")
      state.status = "loading"
    },
    setDiscount(state, { payload }) {
      state.discount = payload
      state.status = null;
    },
    payuInitPayment() { },
    payuInitPayment(state, { payload }) {
      state.payu = payload
    },
    generatePayment() { },
    generatePayment(state, { payload }) {
      state.payu = payload
    },
    rePayment() { },
    rePayment(state, { payload }) {

    },
    getShopping() { },
    getShoppingDataDis() { },
    setShoppingDataDis(state, { payload }) {
      state.dispersionData = payload
    },
    setShopping(state, { payload }) {
      state.shopping = payload
    },
    deleteShopping() { },
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
    onError(state, { payload }) {
      state.status = 'error'
      state.errormsg = payload
    },
    dispersion(state, { payload }) {

    }
  }
})

const shoppingActions = shoppingSlice.actions
const shoppingReducer = shoppingSlice.reducer

export { shoppingActions, shoppingReducer }