import { formmatterCurrency } from 'common/utilities'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router' 
 

export const columns = () => {
    const dispatch = useDispatch()
    const history = useHistory() 
     
    return [
        {
            title: 'Fecha',
            dataIndex: 'date',
            key: 'date'
        }, 
        {
            title: 'contratos mes',
            dataIndex: 'contracts_month',
            key: 'contracts_month'
        }, 
        {
            title: 'total mes',
            dataIndex: 'total_month',
            key: 'total_month',
            render: (data) => {
                return <>{formmatterCurrency(data)}</>
            }
        }, 
        {
            title: 'sumatoria de contratos',
            dataIndex: 'contracts_total',
            key: 'contracts_total'
        }, 
        {
            title: 'total',
            dataIndex: 'total',
            key: 'total',
            render: (data) => {
                return <>{formmatterCurrency(data)}</>
            }
        }  
    ]
} 