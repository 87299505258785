import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
import { Modal, Button, Form, Input } from 'antd';
import styled, { useTheme } from 'styled-components';

import logo from '../../../../../assets/logo-purple.png'
import { authActions } from '../../../../../services/Auth/AuthSlice'
import { RecoverPassword } from '../../../components/RecoverPassword/recoverPassword';

const ButtonPrimary = styled(Button)`
  background: ${props => {
    let background = props.theme.colors.primary.main;
    return background;
  }
  };
  &:hover, :focus, :active{
    background: ${props => {
    let background = props.theme.colors.primary.main;
    return background;
  }
  };
  };
  ;
`;

export const LoginForm = () => {
  const dispatch = useDispatch()
  const [isCompany, setIsCompany] = useState(null);
  const { company, loading } = useSelector(state => state.auth)
  const [showRecoverPasswordModal, toggleRecoverPasswordModal] = useState(false)
  const theme = useTheme()



  useEffect(() => {
    if (company?.length) {
      setIsCompany(company[0])
      theme.colors.primary.main = JSON.parse(company[0].metadata)?.color[0] || "#333"
    }
  }, [company])

  const onFinish = values => {
    if (isCompany) {
      dispatch(authActions.login(values))
    } else
      dispatch(authActions.companyByUser(values))
  }

  return (
    <div className={`login_form`}>
      <div className={` ${isCompany && "slide-left"}`}>
        <div className="login_form_welcome">
          {/* <h3><strong>YAMPI</strong></h3> */}
          <img className="logo" src={isCompany ? JSON.parse(isCompany.metadata)?.img : logo} alt="logo" />
          <h1>Iniciar sesión</h1>
        </div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onValuesChange={(e) => {
            if (e.email) {
              dispatch(authActions.setCompanyByUser(null))
            }
          }}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Por favor ingrese un correo válido!', type: 'email' }]}
          >
            <Input disabled={isCompany} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Correo" style={{ fontSize: 16 }} />
          </Form.Item>
          {isCompany && <Form.Item
            name="password"
            rules={[{ required: true, message: 'Por favor ingrese su contraseña!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Contraseña"
            />

          </Form.Item>}
          {loading && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>}

          {isCompany && <a className="login_form__forgot_pass" onClick={() => setIsCompany(undefined)}>Iniciar sesión con otra cuenta</a>}
          <a className="login_form__forgot_pass" onClick={() => toggleRecoverPasswordModal(true)}>¿Olvidaste tu contraseña?</a>
          <Modal
            title="Restablece tu contraseña"
            visible={showRecoverPasswordModal}
            onCancel={() => toggleRecoverPasswordModal(false)}
            footer={[
              <Button key="back" onClick={() => toggleRecoverPasswordModal(false)}>
                Return
              </Button>,
              <Button className="btn-login" htmlType="submit" key="submit" type="submit" onClick={() => toggleRecoverPasswordModal(true)}>
                Submit
              </Button>
            ]}
          >
            <RecoverPassword closeModal={toggleRecoverPasswordModal} />
          </Modal>
          {company?.length == 0 && <p style={{ color: '#ff4d4f', marginBottom: '1em' }}>Este correo  puede ser incorrecto. Asegúrese de que lo ha escrito correctamente. De lo contrario, póngase en contacto con el administrador.</p>}
          <Form.Item>
            <ButtonPrimary className="btn-login" htmlType="submit" iscompany={isCompany}>
              {isCompany ? 'INICIAR SESIÓN' : 'SIGUIENTE'}
            </ButtonPrimary>
          </Form.Item>
        </Form>
      </div>
    </div >
  )
}