import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Select } from 'antd';
import { shoppingActions } from 'services/Shopping/ShoppingSlice';
import PropTypes from 'prop-types';

export const ModalTypePay = ({ contract }) => {
  const dispatch = useDispatch();
  const { status, discount } = useSelector((state) => state.shopping);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ discount:", discount);
  }, [discount]);

  const onFinish = useCallback((values) => {
    const currentDate = new Date();
    const startDate = new Date(contract.started_at);
    const endDate = new Date(contract.finished_at);

    // Calcular el secuence_pay
    const secuencePay = (currentDate.getFullYear() - startDate.getFullYear()) * 12 + 
                         currentDate.getMonth() - startDate.getMonth() + 1;

    // Evitar secuencePay mayor que los meses del contrato
    const totalContractMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
                                endDate.getMonth() - startDate.getMonth() + 1;
    const finalSecuencePay = Math.min(secuencePay, totalContractMonths);

    // Calcular el secuence_pay_end
    const secuencePayEnd = totalContractMonths;

    dispatch(
      shoppingActions.generatePayment({
        contract_id: contract?.id,
        dispersion_apply: values.type,
        payment_period: values.payment_period,
        secuence_pay: finalSecuencePay,
        secuence_pay_end: secuencePayEnd,
      })
    );
  }, [dispatch, contract]);

  const onFinishFailed = useCallback((errorInfo) => {
    console.log('Failed:', errorInfo);
  }, []);

  const months = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' },
  ];

  // Calcular el mes actual según la lógica del periodo de pago
  const calculateCurrentMonth = () => {
    const currentDate = new Date();
    const startDate = new Date(contract.started_at);

    // Obtener el día de inicio del contrato
    const startDay = startDate.getDate();
    const currentDay = currentDate.getDate();

    // Determinar el mes actual según el día
    let currentMonth = currentDate.getMonth() + 1; // De 1 a 12
    if (currentDay < startDay) {
      currentMonth = currentDate.getMonth(); // Mes anterior
    }

    return currentMonth;
  };

  const currentMonth = calculateCurrentMonth();

  return (
    <Form
      name="basic"
      layout="vertical"
      initialValues={{ remember: true, payment_period: currentMonth }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="p-6 bg-white shadow-md rounded-lg"
    >
      <Form.Item
        label="Recaudo a:"
        name="type"
        rules={[{ required: true, message: 'Ingrese un tipo!' }]}
        className="mb-6"
      >
        <Select
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="Tipo"
          options={[
            { value: 4, label: 'Canon de arrendamiento' },
            { value: 2, label: 'Administracion' },
            { value: 3, label: 'Otro' },
          ]}
        />
      </Form.Item>

      <Form.Item
        label="Periodo de pago:"
        name="payment_period"
        rules={[{ required: true, message: 'Seleccione un mes!' }]}
        className="mb-6"
      >
        <Select
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="Seleccione un mes"
          options={months}
        />
      </Form.Item>

      <Form.Item className="mt-4">
        <Button type="primary" htmlType="submit" className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
          Generar recaudo
        </Button>
      </Form.Item>
    </Form>
  );
};

ModalTypePay.propTypes = {
  contract: PropTypes.object.isRequired,
};
