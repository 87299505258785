import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: null,
  errormsg: null,
  loading: false,
  users: [],
  modules: [],
}

const permissionSlice = createSlice({
  name: "permission",
  initialState: initialState,
  reducers: {
    getUsers() { },
    setUsers(state, { payload }) {
      state.users = payload
    },
    getModules() { },
    setModules(state, { payload }) {
      state.modules = payload
    },
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
    setUserPermission() { },
    onError(state, { payload }) {
      state.status = 'error'
      state.errormsg = payload
    },
  }
})

const permissionActions = permissionSlice.actions
const permissionReducer = permissionSlice.reducer

export { permissionActions, permissionReducer }