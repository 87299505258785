import { put, all, takeLatest } from 'redux-saga/effects'
import ApiService from '../../common/api/Api'
import { specialityActions } from './SpecialitySlice'

function* getSpecialities() {
  yield put(specialityActions.onChangeStatus('loading'))
  try {
    const specialities = yield ApiService.get('/speciality')
    yield put(specialityActions.setSpecialities(specialities))
    yield put(specialityActions.onChangeStatus('success'))
  } catch (err) {
    yield put(specialityActions.onError(err.toString()))
  }
}

function* getSubSpecialities({ payload }) {
  yield put(specialityActions.onChangeStatus('loading'))
  try {
    const specialities = yield ApiService.get(`/speciality/${payload}`)
    yield put(specialityActions.setSubSpecialities(specialities))
    yield put(specialityActions.onChangeStatus('success'))
  } catch (err) {
    yield put(specialityActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(specialityActions.getSpecialities, getSpecialities)
  yield takeLatest(specialityActions.getSubSpecialities, getSubSpecialities)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ])
}
