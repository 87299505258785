import React from 'react';
import { Tabs } from 'antd';

import { Detail, Contract, Payments, Files, PropertyPhotos, Features, Amenities } from '../../Sections';
import { Dispersion } from 'scenes/Private/Dispersion/Dispersion';
import RecordContractCollapse from '../RecordContract/RecordContractCollapse';

export const PropertyDataCard = () => {

    return (
        <div style={{ background: '#ffff', borderRadius: '12px', padding: 20, boxShadow: '0 20px 27px rgb(0 0 0/5%)' }}>
            <Tabs defaultActiveKey="1" tabBarStyle={{ fontSize: '22px' }} size='small'>
                <Tabs.TabPane tab="Detalle de la propiedad" key="1" >
                    <Detail />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Contrato" key="2" >
                    <Contract />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Recaudos" key="3" >
                    <Payments />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Documentos" key="4" >
                    <Files />
                </Tabs.TabPane>
               {/* <Tabs.TabPane tab="Caracteristicas" key="5" > 
                    <Features />
                </Tabs.TabPane> 
                <Tabs.TabPane tab="Fotos" key="6" >
                    <PropertyPhotos />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Comodidades" key="7" >
                    <Amenities />
                </Tabs.TabPane>*/}
                <Tabs.TabPane tab="Dispersiones" key="8" >
                    <Dispersion />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Historial Contratos" key="9" >
                    <RecordContractCollapse />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
};
