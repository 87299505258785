import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input, Button, InputNumber, Form, DatePicker, Row, Col, Card, Tooltip, Modal } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { shoppingActions } from 'services/Shopping/ShoppingSlice';

const { RangePicker } = DatePicker;

export const Filter = () => {
    const dispatch = useDispatch();
    const { companys } = useSelector((state) => state.company);
    const [dataInmo, setDataInmo] = useState([]);
    const [inmoPiv, setInmoPiv] = useState();
    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
    const { company } = useSelector((state) => state.user);
    const { user } = useSelector((state) => state.auth);
    const formRef = React.createRef();

    const { pagination } = useSelector(state => state.shopping);
    const { page, limit } = pagination;


    useEffect(() => {
        if (companys && user && company) {
            const rolesAdmin = ["sysadmin", "admin"];
            const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name));
            let data = [];
            if (isAdmin) {
                if (Array.isArray(companys?.data)) {
                    data = companys.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }));
                }
                setDataInmo([{ value: 0, label: "Todas" }, ...data]);
                dispatch(shoppingActions.getShoppings({
                    page: 1, limit, date_filter: { inmo_id: 0 }
                }));
            } else {
                if (Array.isArray(company)) {
                    data = company.map((item) => ({
                        value: item.company_data.id,
                        label: item.company_data.name,
                    }));
                }
                setDataInmo([...data]);
                setInmoPiv(data[0].value);
                dispatch(shoppingActions.getShoppings({
                    page: 1, limit, date_filter: { inmo_id: data[0].value }
                }));
            }  
        }
    }, [companys, user, company]);

    const onFinish = (values) => {
        const dateFilter = values.date_range
            ? {
                start_date: values.date_range[0].startOf('day').toISOString(),
                end_date: values.date_range[1].endOf('day').toISOString(),
            }
            : {};
        dispatch(shoppingActions.getShoppings({
            page: 1,
            limit,
            date_filter: {
                ...values,
                ...dateFilter,
                inmo_id: values.inmo_id
            }
        }));
    };

    const onReset = () => {
        formRef.current.resetFields();
        dispatch(shoppingActions.getShoppings({
            page: 1, limit, date_filter: { inmo_id: 0 }
        }));
    };

    const toggleAdvancedFilters = () => {
        setShowAdvancedFilters(!showAdvancedFilters);
    };

    return (
        company ?
            <>
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                        inmo_id: inmoPiv
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    ref={formRef}
                    layout="inline"
                    style={{ width: "100%" }}
                >
                    <Card title={
                        <div className="flex">
                            <span className="flex-1">Filtros de Búsqueda</span>
                            <Form.Item
                                label="Inmobiliaria"
                                name="inmo_id"
                            >
                                <Select
                                    showSearch
                                    placeholder="Inmobiliaria"
                                    style={{ width: '100%', minWidth: 180 }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    options={dataInmo}
                                />
                            </Form.Item>
                        </div>
                    } bordered={false} style={{ width: '100%', marginBottom: 20 }}>

                        <Row gutter={[10, 16]} style={{ marginBottom: '16px', width: "100%" }}>
                            <Col xs={24} sm={12} md={6} lg={3}>
                                <Form.Item
                                    label=""
                                    name="shoppingcart_id"
                                >
                                    <InputNumber
                                        type="number"
                                        placeholder="ID Pago"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={5}>
                                <Form.Item
                                    label=""
                                    name="status_id"
                                >
                                    <Select
                                        placeholder="Estado de Recaudo"
                                        style={{ width: '100%' }}
                                        options={[
                                            { value: 0, label: 'Todos' },
                                            { value: 11, label: 'Pendiente Recaudar' },
                                            { value: 12, label: 'Recaudado' },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={7}>
                                <Form.Item
                                    label=""
                                    name="date_range"
                                >
                                    <RangePicker
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            {showAdvancedFilters && (
                                <>
                                    <Col xs={24} sm={12} md={6} lg={5}>
                                        <Form.Item
                                            label=""
                                            name="property_id"
                                        >
                                            <InputNumber
                                                type="number"
                                                placeholder="ID Propiedad"
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={6}>
                                        <Form.Item
                                            label=""
                                            name="property_name"
                                        >
                                            <Input
                                                placeholder="Título Propiedad"
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                            <Col xs={24} sm={12} md={6} lg={3}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" block>
                                        Buscar
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={2}>
                                <Form.Item>
                                    <Tooltip title="Limpiar filtros">
                                        <Button htmlType="button" onClick={onReset} block icon={<DeleteOutlined />} />
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={3}>
                                <Form.Item>
                                    <Button type="link" onClick={toggleAdvancedFilters} block>
                                        {showAdvancedFilters ? "Ocultar filtros " : "Mas filtros"}
                                    </Button>
                                </Form.Item>
                            </Col>
                         
                        </Row>
                    </Card>
                </Form>
            </> : <></>
    );
};
