import React, { useEffect, useState } from 'react'
import { Filter } from './sections/Filter'
import { Modal, PageHeader, Spin, Table } from 'antd'
import { columns } from './constans';
import { useSelector } from 'react-redux'; 

export const GrowCompany = () => {
  
  const { growArray,status } = useSelector(state => state.company); 
  const { active , closed } = growArray;
  const [dataActive, setDataActive] = useState([]);
  const [dataInctive, setDataInactive] = useState([]);
  const currentColumn = columns();

  useEffect(() => {
    if (active && active.length > 0) {
      const dataArrayActive = Object.entries(active[0]).map(([date, values]) => ({ date, ...values }));
      setDataActive(dataArrayActive);
    }

    if (closed && closed.length > 0) {
      const dataArrayClosed = Object.entries(closed[0]).map(([date, values]) => ({ date, ...values }));
      setDataInactive(dataArrayClosed);
    }
  }, [growArray]);


  const handleOk = async () => {

  };

  const handleCancel = async () => {
    setIsModalVisible(false);
  };



  return (
    <div className="sysblueprint">
      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888', paddingLeft: 0 }}
      >
        <Filter />
      </PageHeader>
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <>
            Contratos activos
            </>
          }
          columns={currentColumn}
          dataSource={dataActive} 
          scroll={{ x: 400 }}
        />
      </Spin>
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <>
            Contratos cerrados/cancelados
            </>
          }
          columns={currentColumn}
          dataSource={dataInctive} 
          scroll={{ x: 400 }}
        />
      </Spin>
       
    </div>
  )
}