import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { contractDispersionActions } from "./ContractDispersionSlice"


function* createDispersion({ payload }) {
    try { 
      const res_data_ban = yield ApiService.post(`/admin/bank_acc_dis`, payload); 
      payload['bank_acc_dis_id'] = res_data_ban['id'];
      payload['bank_account_distribution_id'] = res_data_ban['id'];
      const res_data_con = yield ApiService.post(`/admin/con_distribution`, payload);
      yield put(contractDispersionActions.setState({ key: 'contractDispersionModal', value: false }))
      yield put(contractDispersionActions.setState({ key: 'dispersionSelected', value: null }))
      
  } catch (err) {
    yield put(
      contractDispersionActions.onError("No se pudo actualizar el registro")
    );
  }
}

function* updateDispersion({ payload }) { 
  try { 
     const res_data_con = yield ApiService.put(`/admin/con_distribution`, payload);
     const res_data_ban = yield ApiService.put(`/admin/bank_acc_dis`, payload);
     yield put(contractDispersionActions.setState({ key: 'contractDispersionModal', value: false }))
     yield put(contractDispersionActions.setState({ key: 'dispersionSelected', value: null }))
     
  } catch (err) {
    yield put(
      contractDispersionActions.onError("No se pudo actualizar el registro")
    );
  }
}

function* deleteDispersion({ payload }) { 
  try { 
     const del_data_con = yield ApiService.delete(`/admin/con_distribution/`+payload['id']);  
  } catch (err) {
    yield put(
      contractDispersionActions.onError("No se pudo eliminasr el registro")
    );
  }
}


function* ActionWatcher() {
  yield takeLatest(contractDispersionActions.createDispersion, createDispersion)
  yield takeLatest(contractDispersionActions.updateDispersion, updateDispersion)
  yield takeLatest(contractDispersionActions.deleteDispersion, deleteDispersion)
}

export default function* () {
  yield all([ActionWatcher()]);
}

