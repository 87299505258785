import { put, all, takeLatest, select } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import ApiService from '../../common/api/Api';
import { emailActions } from './EmailSlice';

function* sendEmail({ payload }) {
  yield put(emailActions.onChangeStatus('loading'))
  try {
    console.log("🚀 ~ function*sendMenssage ~ payload:", payload)
    const response = yield ApiService.requestPost(`/email/send-email`, payload) 
    console.log("🚀 ~ function*sendMenssage ~ response:", response)
    yield put(emailActions.onChangeStatus('success'))
  } catch (err) {
    yield put(emailActions.onError(err.toString()))
  }
}
 
function* ActionWatcher() {
  yield takeLatest(emailActions.sendEmail, sendEmail) 
}

export default function* () {
  yield all([
    ActionWatcher(),
  ]);
}
