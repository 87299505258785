import React from "react";
import { Menu, Dropdown, Button } from "antd";
const Drop = ({setIsModalPassword,setIsModalEditUser,setIsModalCompany}) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <Button type="primary" onClick={() => setIsModalCompany(true)} style={{width: '100%'}}>
          Agregar Rol y Compañía
        </Button>
      </Menu.Item>
      <Menu.Item>
         <Button type="primary" onClick={() => setIsModalPassword(true)} style={{width: '100%'}}>
          Cambiar Contraseña
        </Button>
      </Menu.Item>
      <Menu.Item>
       <Button type="primary" onClick={() => setIsModalEditUser(true)} style={{width: '100%'}}>
          Editar
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        arrow={{ pointAtCenter: true }}
        trigger={['click']}
      >
        <Button type='primary'>Opciones</Button>
      </Dropdown>
    </div>
  );
};

export default Drop;
