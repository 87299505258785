import { createSlice } from "@reduxjs/toolkit";
import { error } from "highcharts";

const initialState = {
  status: null,
  errormsg: null,
  contracts: [],
  loading: null,
  payents: [],
  error: false,
  alertError: false,
  contract: undefined,
  contractId: undefined,
  lastContract: null,
  finale: null,
  paymentsContract: undefined,
  skipayment: undefined
};

const contractSlice = createSlice({
  name: "contract",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.loading = false;
      state.status = payload;
    },
    onError(state, { payload }) {
      state.loading = false;
      state.status = "error";
      state.errormsg = payload;
    },
    onAlert(state, { payload }) {
      state.error = payload
    },
    getContract() { },
    getConfigIpc() { },
    getPayents() { },
    editSpecialContract() { },
    editDayPay() { },
    setPayents(state, { payload }) {
      state.payents = payload
    },
    setContract(state, { payload }) {
      state.contract = payload;
    },
    setContractConfigIpc(state, { payload }) {
      state.contractIpc = payload;
    },
    editContract() { },
    newContractSign() { },
    renterDefineNewContract(state, { payload }) {
      state.loading = true;
      state.error = false
    },
    getPaymentsHide() { },
    setPaymentsContract(state, { payload }) {
      state.paymentsContract = payload;
    },
    skipayment() { },
    setSkipayment(state, { payload }) {
      state.skipayment = payload;
    },
    downContract(state, { payload }) {
    }
  }
});

const contractActions = contractSlice.actions;
const contractReducer = contractSlice.reducer;

export { contractActions, contractReducer };
