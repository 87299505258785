import React, { useEffect, useState, } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Spin, Button, Dropdown } from 'antd'
import { ArrowLeftOutlined, MoreOutlined } from '@ant-design/icons'
import { menu } from './const'

export const PropertyCard = () => {
    const history = useHistory()

    const { status } = useSelector(state => state.shopping)
    const { property, user, userAdmin } = useSelector((state) => state.property);

    const { contract } = useSelector(state => state.contract)

    return (
        <Spin spinning={status === "loading"}>
            <div className="property-card"  >
    {/*             <Button
                    type="text"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => history.goBack()}
                    style={{ marginBottom: "16px" }}
                /> */}
                <div className='item'>
                    <span className='item__label'> Ubicación: </span>
                    <span className='item__value'> {property?.location} </span>
                </div>
                <div className='item'>
                    <span className='item__label'> Dirección: </span>
                    <span className='item__value'> {property?.localization?.address} </span>
                </div>
                <div className='item'>
                    <span className='item__label'> Propietario: </span>
                    <span onClick={() => history.push(`/user/${user?.id}`)} style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}>
                        {user?.profile?.name ? user?.profile?.name : "Sin propietario"} {user?.profile?.name ? user?.profile?.lastname : ""}
                    </span>
                </div>
                <div className='item'>
                    <span className='item__label'> Inmobiliaria: </span>
                    <span className='item__value'>
                        {userAdmin?.company && userAdmin?.company[0]?.company_data?.name ? userAdmin?.company[0]?.company_data?.name : "Sin Inmobiliaria"} {userAdmin?.company && userAdmin?.company[0]?.company_data?.lastname ? userAdmin?.company[0]?.company_data?.namelastname : ""}
                    </span>
                </div>

            </div>
        </Spin>
    )
}