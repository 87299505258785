import { createContext, useContext } from 'react';

export const NotificationStateContext = createContext(undefined);
export const NotificationDispatchContext = createContext(undefined);

export const useNotificationStateContext = () => {
  const context = useContext(NotificationStateContext);

  if (context === undefined) {
    throw new Error(
      'useNotificationStateContext must be used within a NotificationProvider',
    );
  }
  return context;
};

export const useNotificationDispatchContext = () => {
  const context = useContext(NotificationDispatchContext);
  
  if (context === undefined) {
    throw new Error(
      'useNotificationDispatchContext must be used within a NotificationProvider',
    );
  }
  return context;
};

export const useNotificationContext = () => {
  const stateContext = useContext(NotificationStateContext);
  const dispatchContext = useContext(NotificationDispatchContext);

  if (stateContext === undefined || dispatchContext === undefined) {
    throw new Error(
      'useNotificationContext must be used within a NotificationProvider',
    );
  }
  return [stateContext, dispatchContext];
};
