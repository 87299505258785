import React, { useEffect, useState } from "react";
import { Modal, Steps, Button, Spin } from "antd";
import { SignUpForm } from "./components/SignupForm/SignupForm";
import { ProfileForm } from "./components/ProfileForm/ProfileForm";
import { SpecialityForm } from "./components/SpecialityForm/SpecialityForm";
import { AccountForm } from "./components/AccountForm/AccountForm";
import { specialistActions } from "../../../../services/Specialist/SpecialistSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

const { Step } = Steps;

export const CreateModal = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [register, setRegister] = useState();
  const [profile, setProfile] = useState();
  const [specialties, setSpecialties] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [listSpecialities, setListEspecialities] = useState([])
  const { status } = useSelector((state) => state.specialist);

  useEffect(() => {
    resetStates();
  }, [visible]);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const hideModal = () => {
    setVisible();
    resetStates();
  };

  const resetStates = () => {
    setCurrent(0);
    setRegister();
    setProfile();
    setSpecialties();
  };

  const handleFinish = () => {
    const body = {
      register,
      profile,
      specialties,
      callback: () => {
        setVisible(false);
        setCurrent(0);
      },
    };

    dispatch(specialistActions.createSpecialist(body));
  };

  const steps = [
    {
      title: "Registro",
      content: (
        <SignUpForm next={next} setRegister={setRegister} register={register} />
      ),
    },
    {
      title: "Perfil",
      content: (
        <ProfileForm next={next} setProfile={setProfile} profile={profile} />
      ),
    },
    {
      title: "Especialidades",
      content: (
        <SpecialityForm
          next={next}
          setSpecialties={setSpecialties}
          specialties={specialties}
          listSpecialities={listSpecialities} 
          setListEspecialities={setListEspecialities}
        />
      ),
    },
    /* {
      title: 'Cuentas',
      content: (<AccountForm next={next} setAccounts={setAccounts} />),
    }, */
  ];

  const modalFooter = (
    <div className="steps-action" key="footer">
      {current > 0 && (
        <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
          Anterior
        </Button>
      )}
      {current === 0 && (
        <Button type="primary" htmlType="submit" form="signup-form">
          Siguiente
        </Button>
      )}
      {current === 1 && (
        <Button type="primary" htmlType="submit" form="profile-form">
          Siguiente
        </Button>
      )}
      {current === 2 && (
        <Button type="primary" onClick={handleFinish}>
          Finalizar
        </Button>
      )}
    </div>
  );

  return (
    <Modal
      title={`CREAR ESPECIALISTA`}
      visible={visible}
      onOk={hideModal}
      onCancel={hideModal}
      width={"70%"}
      footer={[modalFooter]}
      className="create-specialist-modal"
    >
      <Spin spinning={status === "loading"}>
        <Steps current={current}>
          {steps.map((item, index) => (
            <Step key={index} title={item.title} />
          ))}
        </Steps>
        {visible && (
          <div className="steps-content">{steps[current].content}</div>
        )}
      </Spin>
    </Modal>
  );
};
