import { createSlice } from "@reduxjs/toolkit";
import { error } from "highcharts";

const initialState = {
  dispersionModal: false,
  dispersionSelected: false,
  dispersions: [],
  cartsDispersion: [],
  status: null,
  errormsg: null,
  loading: null,
  filterData:null,
  error: false,
  alertError: false,
  reload:false,
  dispersionSingle: [],
  pagination: {
    total: 1,
    page: 1,
    limit: 100
  }
};

const dispersionSlice = createSlice({
  name: "dispersion",
  initialState: initialState,
  reducers: {
    setState(state, { payload: { key, value } }) {
      state[key] = value
    },
    onChangeStatus(state, { payload }) {
      state.loading = false;
      state.status = payload;
    },
    onError(state, { payload }) {
      state.loading = false;
      state.status = "error";
      state.errormsg = payload;
    },
    onAlert(state, { payload }) {
      state.error = payload
    },
    setLoading(state, { payload: { key, value } }) {
      state.loading[key] = value
    }, 
    getDispersionList() { }, 
    updateDisRegister() { },
    regenDataDisRegister() { },
    getDispersions() { }, 
    setDispersions(state, { payload }) { 
      state.dispersions = payload.items
      state.pagination = payload.pagination
      state.filterData = payload.filterData
    },
    getDispersionListSingle() { },
    getDispersionSingle() {},
    setDispersionSingle(state, { payload }) { 
      state.dispersionSingle = payload.items
    } ,
    onChangeReload(state, { payload }) {
      state.reload = payload.value; 
    },
    getCartsWithoutDis(){},
    setCartsDispersion(state, { payload }) { 
      state.cartsDispersion = payload
    },
    assignateCartDis(){},
    listLastApproved(){}
  },
});

const dispersionActions = dispersionSlice.actions;
const dispersionReducer = dispersionSlice.reducer;

export { dispersionActions, dispersionReducer };
