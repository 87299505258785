import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Card, Button, Descriptions, Row, Col, Modal, Popconfirm } from 'antd';
import { HomeOutlined } from '@ant-design/icons'

import formmatterCurrency from 'common/utilities/formatter/currency'
import EditDescription from "../../Components/Edit/EditDescription/EditDescription";
import { PropertyCard } from '../../Components/PropertyCard/propertyCard'
import { propertyActions } from 'services/Property/PropertySlice'
import { useNotificationDispatchContext } from 'common/utilities/notification/NotificationContext';


export const Detail = () => {
    const { property } = useSelector((state) => state.property);
    const [isModal, setIsModal] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const dispatchNotificationEvent = useNotificationDispatchContext();

    const confirmTest = () => {
        var is_test_var = true;
        if (property?.is_test) {
            is_test_var = false;
        }
        let pay = {
            is_test: is_test_var,
        };
        let payload = {
            pay,
            id: property?.id
        };
        dispatch(propertyActions.editTestProperty(payload));
    }

    const confirm = () => {
        dispatch(propertyActions.deleteProperty({
            propertyId: property?.id, callback: () => {
                history.goBack();
                dispatchNotificationEvent({
                    type: 'OPEN_SNACKBAR',
                    dialog: 'success',
                    message: 'OK',
                    description: 'Se Borro con exito'
                })
            }
        }));
    }

    return (
        <>
            <Row gutter={20}>
                <Col lg={10}>
                    <Card size="default" title={
                        <Row>
                            <Col flex={1}>
                                <HomeOutlined style={{ background: "#ffdee2", borderRadius: '5px', padding: '5px', color: '#d82067' }} />
                                <span style={{ marginLeft: '5px', fontWeight: 600, color: '#0d0c0c' }}>Información general</span>
                            </Col>
                        </Row>
                    } style={{ borderRadius: 12, marginBottom: '20px' }} bodyStyle={{ padding: 0 }}>
                        <PropertyCard />
                    </Card>
                </Col>
                <Col lg={14}>
                    <Card size="default" title={
                        <Row>
                            <Col flex={1}>
                                <HomeOutlined style={{ background: "#ffdee2", borderRadius: '5px', padding: '5px', color: '#d82067' }} />
                                <span style={{ marginLeft: '5px', fontWeight: 600, color: '#0d0c0c' }}>Costos</span>
                            </Col>
                            <Col>
                                <Button
                                    style={{
                                        fontWeight: "bold",
                                        paddingLeft: "16px",
                                        paddingRight: "16px",
                                    }}
                                    type="primary"
                                    size="middle"
                                    onClick={() => {
                                        setIsModal({ descrition: true });
                                    }}
                                >
                                    Editar
                                </Button>
                            </Col>
                        </Row>
                    } style={{ borderRadius: 12, marginBottom: '20px' }} bodyStyle={{ padding: 0 }}>
                        <Descriptions layout="vertical" bordered >
                            <Descriptions.Item label="Cannon">{formmatterCurrency(property?.costs.find((cost) => cost?.cost_type_id == 1)?.value)}</Descriptions.Item>
                            <Descriptions.Item label="Administración">{property?.costs.find((cost) => cost?.cost_type_id == 2) ? formmatterCurrency(
                                property?.costs.find((cost) => cost?.cost_type_id == 2)?.value
                            ) : "Sin costos de administración"}</Descriptions.Item>
                            <Descriptions.Item label="Prioridad">{property?.priority}</Descriptions.Item>
                            <Descriptions.Item label="Es de pruebas">{(property?.is_test) ? "Si" : "No"}</Descriptions.Item>
                            <Descriptions.Item label="Tipo">{property?.type}</Descriptions.Item>
                            <Descriptions.Item label="Estado" >{property?.status}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>

            <Row style={{ justifyContent: 'flex-end' }}>
                <Col offset={1}>
                    <Popconfirm title="Deseas cambiar a el estado de test de la propiedad" onConfirm={confirmTest} >
                        <Button
                            style={{
                                fontWeight: "bold",
                                paddingLeft: "16px",
                                paddingRight: "16px",
                            }}
                            type="primary"
                            size="middle"

                        >
                            Pruebas
                        </Button>
                    </Popconfirm>
                </Col>
                <Col>
                    <Popconfirm title="Deseas borrar la propiedad" onConfirm={confirm} >
                        <Button
                            style={{
                                fontWeight: "bold",
                                paddingLeft: "16px",
                                paddingRight: "16px",
                                marginLeft: "20px"
                            }}
                            type="danger"
                            size="middle"
                        >
                            Eliminar propiedad
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
            <Modal
                title={"Editar Propiedad"}
                visible={isModal}
                onCancel={() => setIsModal(false)}
                footer={null}
                width={800}
            >
                <EditDescription id={property?.id} handleCancel={() => setIsModal(false)} />
            </Modal>
        </>
    )
};
