import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Descriptions, Row, Spin, Typography, List, Timeline } from "antd";
import { formmatterCurrency } from "../../../../../../../common/utilities";
import { ShowButton } from "scenes/Private/components/ShowButton/ShowButton";

const Owner = () => {
  const history = useHistory();
  const { contract, status, payents } = useSelector((state) => state.contract);
  const { Title } = Typography;
  const formatDate = (end, start) => {
    return moment(end).diff(moment(start), "months");
  };

  return (
    <Spin spinning={contract === undefined}>
      <Row ƒ>
        <Descriptions
          title="Información de Propietario"
          bordered
          style={{ width: "100%" }}
          layout="vertical"
        >
          <Descriptions.Item label="NOMBRE">
            {contract?.ownership.find(user => user.ownership_type_id == 1)?.user?.profile?.name}
          </Descriptions.Item>
          <Descriptions.Item label="APELLIDO">
            {contract?.ownership.find(user => user.ownership_type_id == 1)?.user?.profile?.lastname}
          </Descriptions.Item>
          <Descriptions.Item label="CORREO">
            {contract?.ownership.find(user => user.ownership_type_id == 1)?.user.email}
          </Descriptions.Item>
          <Descriptions.Item label="Ver detalle de Propietario">
            <ShowButton
              style={{ marginRight: 10 }}
              onClick={() => history.push(`/user/${contract?.ownership.find(user => user.ownership_type_id == 1)?.user?.id}`)}
              size="small"
            />
          </Descriptions.Item>
          <Descriptions.Item label="INICIO DEL CONTRATO">
            { (contract?.started_at == "1980-01-01 00:00:00")? "Sin definir" : moment(contract?.started_at).format("LL") }
          </Descriptions.Item>
          <Descriptions.Item label="DURACIÓN DEL CONTRATO">
            {formatDate(contract?.finished_at, contract?.started_at)}{" "}
            {formatDate(contract?.finished_at, contract?.started_at) === 1
              ? "mes"
              : "meses"}
          </Descriptions.Item>
          <Descriptions.Item label="Valor Contrato"> {formmatterCurrency(contract?.costs.reduce((total, cost) => { return total + cost.value }, 0))} </Descriptions.Item>
        </Descriptions>
      </Row>
    </Spin>
  );
};

export default Owner;
