import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: null,
  users: [],
  loading: undefined,
  user: undefined,
  company: undefined,
  isYampiAdmin: false,
  errormsg: null,
  filter: {},
  pagination: {
    total: 1,
    page: 1,
    limit: 20
  },
  stateCount: []
}

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    getUsers() { },
    getStateCount(){},
    setStateCount(state, { payload }){
      state.stateCount = payload.data
    },
    createUser() {},
    addCompanyUser() {},
    changePassword() {},
    setUsers(state, { payload }) {
      state.users = payload.data
      state.pagination = payload.pagination
      state.filter = payload.filter
    },
    getUser() { },
    fetchUser() { },
    setUser(state, { payload }) {
      state.user = payload
    },
    editUser() {},
    setCompany(state, { payload }) {
      state.company = payload;
      state.isYampiAdmin = payload.some(obj => obj.company_id === 1);
    },
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
    onError(state, { payload }) {
      state.status = 'error'
      state.errormsg = payload
    },
  }
})

const userActions = userSlice.actions
const userReducer = userSlice.reducer

export { userActions, userReducer }