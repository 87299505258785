import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { formmatterCurrency } from "common/utilities";


const TableFooters = ({ item }) => {
    const totalPayment = (item) => {
        let total = 0;
        //items.stateData.map((item, index) => (
        //    total = item.dis_reg.valuePay
        //));
        total = item.dis_reg.valuePay;
        return Math.round(total);
    }

    const date_dispersed = (item) => {
        let date = item.dis_reg.dispersedAt; 
        let today = new Date(date);
        let formattedDate = today.toISOString().split('T')[0];
        return formattedDate;
    }

    const footers = () => {
        return (
            <>
                <View style={styles.rowFoot} key={-2}>
                    <View style={styles.cell}>
                        <Text style={styles.text}> </Text>
                    </View>
                    <View style={styles.cell}>
                        <Text style={styles.text}> </Text>
                    </View>
                    <View style={styles.cell}>
                        <Text style={styles.text}> </Text>
                    </View>
                </View>
                <View style={styles.rowFoot} key={-3}>
                    <View style={styles.cell}>
                        <Text style={styles.text}> </Text>
                    </View>
                    <View style={styles.cellR}>
                        <Text style={styles.textR}>PAGO A Destinatario</Text>
                    </View>
                    <View style={styles.cell}>
                        <Text style={styles.text}>{formmatterCurrency(totalPayment(item))}</Text>
                    </View>
                </View>
                <View style={styles.rowFoot} key={-4}>
                    <View style={styles.cell}>
                        <Text style={styles.text}> </Text>
                    </View>
                    <View style={styles.cellR}>
                        <Text style={styles.textR}>FECHA DE PAGO</Text>
                    </View>
                    <View style={styles.cell}>
                        <Text style={styles.text}>{date_dispersed(item)}</Text>
                    </View>
                </View>
            </>
        );
    };

    return (
        <View>
            {footers()}
        </View>
    );
};
const styles = {
    rowFoot: {
        flexDirection: 'row',
    },
    cellAlone: {
        flex: 12,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cell: {
        flex: 1,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        textAlign: 'center',
        fontSize: 12,
    },
    cellR: {
        flex: 1,
        padding: 5,
        alignItems: 'right',
        justifyContent: 'right',
    },
    textR: {
        textAlign: 'right',
        fontSize: 12,
    },
};
export default TableFooters; 