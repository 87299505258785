import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Tabs, Card, Row, Popconfirm, Button, Table, Col, Upload } from 'antd';
import { DollarCircleOutlined, CalendarOutlined, FileTextOutlined } from '@ant-design/icons'
import { formmatterCurrency } from "../../../../common/utilities";
import { getItemDescription } from '../../components/ItemDescription/ItemDescription'
import { DESCRIPTION_DISEPERSION } from './constans'
import { StorageService } from "common/storage/storage-service"
import ApiService from "common/api/Api";
import { propertyActions } from "services/Property/PropertySlice"
import { UploadOutlined } from "@ant-design/icons";


import moment from "moment";

const DetailCard = () => {
    const [progress, setProgress] = useState(0);

    const { shopping, status } = useSelector(state => state.shopping)
    const [defaultList, setDefaultLIst] = useState(undefined)
    const { media } = useSelector(
        (state) => state.property
    );
    const dispatch = useDispatch();
    const servicesColumns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Costo',
            dataIndex: 'cost',
            key: 'cost'
        }
    ]

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ shopping:", shopping)
    }, [shopping])

    const confirm = () => {
        dispatch(shoppingActions.dispersion({
            data: shopping,
            callback: () => {
                dispatchNotificationEvent({
                    type: 'OPEN_SNACKBAR',
                    dialog: 'success',
                    message: 'OK',
                    description: 'Se disperso el pago'
                })
            }
        }));
    }

    const uploadFIle = async (options) => {
        const { file } = options;
        const fmData = new FormData();
        fmData.append("media", file);
        fmData.append("group", "pay_support");
        fmData.append("parent_id", shopping?.id);

        let res = await ApiService.uploadImage(options, fmData, setProgress);
        if (res.status == "200") {
            dispatch(propertyActions.getMedia({ entityId: shopping?.id, entityName: 'ShoppingCart' }))
        }

    };

    const paymentStore = (data) => {
        let paymentStore;

        switch (data?.payment_method) {
            case 1:
                paymentStore = "payu";
                break;
            case 3:
                paymentStore = "zona pago";
                break;
            case 4:
                paymentStore = "terceros";
                break;
            case 5:
                paymentStore = "paymentez";
                break;
        }
        return (data?.payment_method ? `${data?.status} por ${paymentStore} ` : data?.status);
    }

    const PreviewFile = async (file) => {
        // Your process logic. Here we just mock to the same file
        const token = await StorageService.getItem('token')
        return fetch(file.url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'BearerToken': token
            }
        })
            .then(res => res.blob())
            .then(blob => {
                var file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            });

    };

    useEffect(() => {
        if (media) {
            let listFile = []
            media?.map(file => {
                listFile.push({
                    uid: file.id,
                    name: file.filename,
                    status: 'done',
                    response: 'Server Error 500', // custom error message to show
                    url: `https://api.yampi.co/media/private/${file.id}${file.filename} `,
                })
            })
            setDefaultLIst(listFile)
        }
    }, [media])
    return (
        <div style={{ background: '#ffff', borderRadius: '12px', padding: 20, boxShadow: '0 20px 27px rgb(0 0 0/5%)' }}>
            <Tabs defaultActiveKey="1" tabBarStyle={{ fontSize: '22px' }} size='small'>
                <Tabs.TabPane tab="Detalle" key="1" >
                    <Card size="default" title={
                        <>
                            <FileTextOutlined style={{ background: "#ffdee2", borderRadius: '5px', padding: '5px', color: '#d82067' }} />
                            <span style={{ marginLeft: '5px', fontWeight: 600, color: '#0d0c0c' }}>Resumen del pago</span>
                        </>
                    } style={{ borderRadius: 12, marginBottom: 20 }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                            {getItemDescription('ID', shopping?.id, 12)}
                            {getItemDescription('ESTADO', paymentStore(shopping), 12)}
                            {getItemDescription(
                                'RESPONSABLE',
                                `${shopping?.user?.profile?.name} ${shopping?.user?.profile?.lastname}`,
                                12,
                                () => history.push(`/user/${shopping?.user.id}`)
                            )}
                            {getItemDescription('Dispersión', shopping?.is_dispersed ? "Pendiente" : "Realizada", 12)}
                            {getItemDescription('Dispersión descripcion', DESCRIPTION_DISEPERSION(shopping?.is_dispersed), 12)}
                            <Popconfirm title={`Deseas realizar la dispersion del pago #${shopping?.id}`} onConfirm={confirm} >
                                <Button
                                    style={{
                                        fontWeight: "bold",
                                        paddingLeft: "16px",
                                        paddingRight: "16px",
                                        marginLeft: "20px"
                                    }}
                                    type="primary"
                                    size="middle"
                                >
                                    Dispersión pago manual
                                </Button>
                            </Popconfirm>
                        </Row>
                    </Card>
                    <Card size="default" title={
                        <>
                            <DollarCircleOutlined style={{ background: "#ffdee2", borderRadius: '5px', padding: '5px', color: '#d82067' }} />
                            <span style={{ marginLeft: '5px', fontWeight: 600, color: '#0d0c0c' }}>Valor del pago</span>
                        </>
                    } style={{ borderRadius: 12, marginBottom: '20px' }}>
                        <p style={{ fontSize: '22px', fontWeight: 600 }}>{formmatterCurrency(shopping?.total_amount)}</p>
                    </Card>
                    <Row gutter={16}>
                        <Col flex={1}>
                            <Card size="default" title={
                                <>
                                    <CalendarOutlined style={{ background: "#ffdee2", borderRadius: '5px', padding: '5px', color: '#d82067' }} />
                                    <span style={{ marginLeft: '5px', fontWeight: 600, color: '#0d0c0c' }}>Fecha de Creación</span>
                                </>
                            } style={{ borderRadius: 12, marginBottom: '20px' }}>
                                <p style={{ color: '#423d3e' }}>Fecha de creación</p>
                                <p style={{ fontSize: '22px', fontWeight: 600 }}>{moment(shopping?.created_at).format("LL")}</p>
                            </Card>
                        </Col>
                        <Col flex={1}>
                            <Card size="default" title={
                                <>
                                    <CalendarOutlined style={{ background: "#ffdee2", borderRadius: '5px', padding: '5px', color: '#d82067' }} />
                                    <span style={{ marginLeft: '5px', fontWeight: 600, color: '#0d0c0c' }}>Fecha de pago</span>
                                </>
                            } style={{ borderRadius: 12, marginBottom: '20px' }}>
                                <p style={{ color: '#423d3e' }}>Fecha de pago</p>
                                <p style={{ fontSize: '22px', fontWeight: 600 }}>{shopping?.transaction_date ? moment(shopping?.transaction_date).format("LL") : 'Sin fecha de pago'}</p>
                            </Card>
                        </Col>
                    </Row>
                    <Card size="default" title={
                        <>
                            <FileTextOutlined style={{ background: "#ffdee2", borderRadius: '5px', padding: '5px', color: '#d82067' }} />
                            <span style={{ marginLeft: '5px', fontWeight: 600, color: '#0d0c0c' }}>Documentos adjuntos</span>
                        </>
                    } style={{ borderRadius: 12, marginBottom: 20 }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Upload customRequest={uploadFIle} accept=".pdf" fileList={defaultList} showUploadList={
                                {
                                    showDownloadIcon: false,
                                    showRemoveIcon: false
                                }
                            } onPreview={PreviewFile} >
                                <Button icon={<UploadOutlined />}>Subir archivo de soporte</Button>
                            </Upload>
                        </Row>
                    </Card>

                </Tabs.TabPane>
                <Tabs.TabPane tab="Servicios" key="2">
                    <Table
                        columns={servicesColumns}
                        dataSource={shopping?.service}
                        size="small"
                        pagination={false}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
};

export default DetailCard;