import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Descriptions, Row, Spin, Typography, List, Timeline, Button, Modal, Table, Col } from "antd";

import ApiService from "../../../../common/api/Api"
import { shoppingActions } from "services/Shopping/ShoppingSlice";
import { columnsDispersion, columnsDispersionData, columnsDispersionHistory } from "../constans";


export const ModalDispersion = (cart) => {
  const dispatch = useDispatch()
  const { dispersionData } = useSelector(state => state.shopping) 
  const currentColumnHistory = columnsDispersionHistory( );
  const currentcolumnsDisData = columnsDispersionData( );
  const { payload, otraPropiedad } = dispersionData || {};
 

  useEffect(() => { 
    if (!dispersionData) {
      dispatch(shoppingActions.getShoppingDataDis(cart))
      //se agrega a la lista  
    }

  }, [dispersionData, dispatch, cart])


  const listaDeDispersion = payload?.dispersion || [];
  const historial = payload?.history || [];

  return (
    <Row>
       <Table
          title={() =>
            <>
              <Row justify="space-between">
                <Col flex={1} > <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, margin: '25px 10px' }}>Desglose de datos</span></Col>
              </Row>
            </>
          }
          columns={currentColumnHistory}
          dataSource={historial}
          scroll={{ x: 400 }} 
        />
        <Table
          title={() =>
            <>
              <Row justify="space-between">
                <Col flex={1} > <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, margin: '25px 10px' }}>Registro de dispersion</span></Col>
              </Row>
            </>
          }
          columns={currentcolumnsDisData}
          dataSource={listaDeDispersion}
          scroll={{ x: 400 }} 
        />
    </Row>
  );
};
