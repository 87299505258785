import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { formmatterCurrency } from "common/utilities";


const TableHeadersInmo = ({ item }) => {
    const headers = () => {
        return (
            <>
                <View style={styles.row} key={-1}>
                    <View style={styles.cell}>
                        <Text style={styles.text}>{item.names_reg.prop_name}</Text>
                    </View>
                </View>
                <View style={styles.row} key={0}> 
                    <View style={styles.cell}>
                        <Text style={styles.text}>ITEM</Text>
                    </View>
                    <View style={styles.cell}>
                        <Text style={styles.text}>DESCRIPCION</Text>
                    </View>
                    <View style={styles.cell}>
                        <Text style={styles.text}>VALOR</Text>
                    </View>
                </View>
            </>
        );
    };

    return (
        <View>
            {headers()}
        </View>
    );
};
const styles = {
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
    },
    cellAlone: {
        flex: 12,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cell: {
        flex: 1,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        textAlign: 'center',
        fontSize: 12,
    },
};
export default TableHeadersInmo; 