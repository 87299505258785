import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationActions } from "../../../../../../services/Location/LocationSlice";
import { useHistory } from "react-router-dom";
import {
  UserOutlined,
  LockOutlined,
  PhoneOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { Form, Button, Input, Col, Row, Select, DatePicker } from "antd";

const { Option } = Select;

export const ProfileForm = ({ next, setProfile, profile }) => {
  const [selectedCity, selectCity] = useState("");
  let history = useHistory();
  const dispatch = useDispatch();

  const { locations } = useSelector((state) => state.location);
  const { genres } = useSelector((state) => state.genre);
  const { docIdTypeList } = useSelector((state) => state.docIdType);

  const onFinish = (data) => {
    let genre = { genre_id: Number(data.genre) };
    let docId = {
      docidable_id: 1, //---------
      docid_type_id: data.docIdType,
      code: data.docId,
      issue_date: formatDate(new Date(data.issueDate)),
      issue_location: data.issueCity,
    };
    let phone = {
      country_id: 2,
      phone_type_id: 1,
      number: data.telefono,
      entity_type: "user",
      entity_id: 1, //---------
      extension: "",
      prefix: "+57",
    };
    let localization = {
      userId: 1, //---------
      body: {
        location_id: selectedCity[0].id,
        latitude: selectedCity[0].latitude,
        longitude: selectedCity[0].longitude,
        address_data: "user",
        address: data.address,
      },
    };
    let formatData = { genre, docId, phone, localization };

    setProfile(formatData);
    next();
  };

  const formatDate = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return [year, month < 10 ? "0" + month : month, day].join("-");
  };

  const getLocationOnChange = (e) => {
    /* if (e.length > 2)
      dispatch(locationActions.getLocations(e)) */

    const city = locations.filter((location) => location.id == e);

    selectCity(city);
  };

  const getLocationOnSearch = (e) => {
    if (e.length > 2) dispatch(locationActions.getLocations(e));

    /*  if (e.target.name === 'city')
       selectCity(locations.filter(t => t.fullname === e.target.value)) */
  };

  const getDocIdTypeName = (type) => {
    switch (type) {
      case "passport":
        return "Pasaporte";
      case "driver_licence":
        return "Licencia de conducción";
      case "citizen_document":
        return "Cédula";
      case "foreign_document":
        return "Cédula de extranjería";
      case "nit":
        return "NIT";
      default:
        return type;
    }
  };

  return (
    <div className="profile-form">
      <Form
        name="profile-form"
        initialValues={{
          remember: true,
          /* location: profile?.localization?.body?.location_id, */
          address: profile?.localization?.body?.address,
          genre: profile?.genre?.genre_id,
          telefono: profile?.phone?.number,
          docIdType: profile?.docId?.docid_type_id,
          docId: profile?.docId?.code,
          issueCity: profile?.docId?.issue_location,
          /* issueDate: profile?.docId?.issue_date, */
        }}
        onFinish={onFinish}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="location"
              rules={[
                { required: true, message: "Por favor ingrese el nombre!" },
              ]}
            >
              <Select
                showSearch
                placeholder={"Ciudad, Departamento"}
                showArrow={false}
                filterOption={false}
                onChange={getLocationOnChange}
                onSearch={getLocationOnSearch}
                notFoundContent={null}
              >
                {locations.map((location) => (
                  <Option key={location.id}>{location.fullname}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese la dirección!",
                  min: 11,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Dirección"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="genre"
              rules={[
                { required: true, message: "Por favor ingrese el género!" },
              ]}
            >
              <Select placeholder={"Género"}>
                {genres.map((genre) => (
                  <Option key={genre.id}>
                    {genre.id !== 3
                      ? genre.id === 2
                        ? "Femenino"
                        : "Masculino"
                      : "Otro"}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="telefono"
              rules={[
                { required: true, message: "Por favor ingrese el teléfono" },
              ]}
            >
              <Input
                prefix={<PhoneOutlined className="site-form-item-icon" />}
                placeholder="Teléfono"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="docIdType"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese el tipo de documento!",
                },
              ]}
            >
              <Select placeholder={"Tipo de documento"}>
                {docIdTypeList.map((docIdType) => (
                  <Option key={docIdType.id}>
                    {getDocIdTypeName(docIdType.name)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="docId"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un número válido!",
                },
              ]}
            >
              <Input
                prefix={<IdcardOutlined className="site-form-item-icon" />}
                placeholder="Número de documento"
              />
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              name="issueCity"
              rules={[
                { required: true, message: "Por favor ingrese el nombre!" },
              ]}
            >
              <Select
                showSearch
                placeholder={"Lugar de expedición"}
                showArrow={false}
                filterOption={false}
                onSearch={getLocationOnSearch}
                notFoundContent={null}
              >
                {locations.map((location) => (
                  <Option key={location.fullname}>{location.fullname}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={12}>
            <Form.Item
              name="issueDate"
              rules={[
                {
                  required: true,
                  message: "Por favor la fecha de expedición!",
                },
              ]}
            >
              <DatePicker
                placeholder="Fecha de expedición"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
