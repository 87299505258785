import React, { useState } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button, Modal } from 'antd';
import { ModalReport } from './ModalReport'


export const Report = () => {

    const [isModalVisibleCreateProperty, setIsModalVisibleCreateProperty] =
        useState(false);


    const handleOkCreateProperty = () => {
        setIsModalVisibleCreateProperty(false);
    };

    const handleCancel = () => {
        setIsModalVisibleCreateProperty(false);
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => setIsModalVisibleCreateProperty(true)}>Descargar reporte de propiedades</Menu.Item>
        </Menu>
    );

    return <>
        <Dropdown
            overlay={menu}
            placement="bottomLeft"
            arrow={{ pointAtCenter: true }}
            trigger={['click']}
        >
            <Button type='text'><MoreOutlined style={{ fontSize: '20px' }} /></Button>
        </Dropdown>
        <Modal
            title={"Descargar Reporte de Propiedades"}
            visible={isModalVisibleCreateProperty}
            onOk={handleOkCreateProperty}
            onCancel={handleCancel}
            footer={null}
        >
            <ModalReport
                handleCancel={handleCancel}
            />
        </Modal>
    </>
}
    ;
