import formmatterCurrency from 'common/utilities/formatter/currency'
import { Button } from "antd";
import moment from 'moment'
import 'moment/locale/es'


export const columnsTable = (handleUpdateDiscount, unActivateDiscount) => [
	{
		title: "Concepto",
		dataIndex: "concept",
	},
	{
		title: "Valor",
		dataIndex: "value",
		render: (value) => formmatterCurrency(
			value
		)
	},
	{
		title: "Fecha de creación",
		dataIndex: "created_at",
		render: (value) => moment(value).format('ll')
	},
	{
		title: "Estado",
		dataIndex: "is_active",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Activo"
					break;
				case 0:
					data = "Inactivo"
					break;
				default:
					data = "pendiente aprobacion"
					break;
			}
			return data;
		}
	},
	{
		title: "Frecuencia",
		dataIndex: "recurrent",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Periódico"
					break;
				case 0:
					data = "Única vez"
					break;
				default:
					data = "pendiente aprobacion"
					break;
			}
			return data;
		}
	},
	{
		title: "Tipo",
		dataIndex: "type",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Adicion"
					break;
				case 0:
					data = "Descuento"
					break; 
			}
			return data;
		}
	},
	{
		title: "Opciones",
		render: (Row) => {
			return <div><Button className='button' type="primary" onClick={() => handleUpdateDiscount(Row)}>
				Editar
			</Button>
			<br></br><br></br>
			 <Button className='button' type="primary" onClick={() => unActivateDiscount(Row)}>
				Eliminar
			</Button>
			</div>
		}
	}
];

export const columnsTableDis = (handleUpdateDiscountDis, unActivateDiscountDis) => [
	{
		title: "Concepto",
		dataIndex: "concept",
	},
	{
		title: "Valor",
		dataIndex: "value",
		render: (value) => formmatterCurrency(
			value
		)
	},
	{
		title: "Fecha de creación",
		dataIndex: "created_at",
		render: (value) => moment(value).format('ll')
	},
	{
		title: "Estado",
		dataIndex: "is_active",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Activo"
					break;
				case 0:
					data = "Inactivo"
					break;
				default:
					data = "pendiente aprobacion"
					break;
			}
			return data;
		}
	},
	{
		title: "Frecuencia",
		dataIndex: "recurrent",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Periódico"
					break;
				case 0:
					data = "Única vez"
					break;
				default:
					data = "pendiente aprobacion"
					break;
			}
			return data;
		}
	},
	{
		title: "Tipo",
		dataIndex: "type",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Adicion"
					break;
				case 0:
					data = "Descuento"
					break; 
			}
			return data;
		}
	},
	{
		title: "Opciones",
		render: (Row) => {
			return <div><Button className='button' type="primary" onClick={() => handleUpdateDiscountDis(Row)}>
				Editar
			</Button>
			<br></br><br></br>
			 <Button className='button' type="primary" onClick={() => unActivateDiscountDis(Row)}>
				Eliminar
			</Button>
			</div>
		}
	}
];