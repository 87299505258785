import React, { useRef, useState } from 'react'

import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { shoppingActions } from 'services/Shopping/ShoppingSlice'
import { PaymentSendActions } from "services/PaymentSend/PaymentSendSlice";
import { Button } from 'antd';


import moment from 'moment'
import 'moment/locale/es'


export const columns = (isModalVisible, setIsModalVisible, cart, setCart) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { pagination } = useSelector(state => state.shopping)
    const { page, limit } = pagination
    const { user } = useSelector((state) => state.auth);

    const rolesAdmin = ["sysadmin", "admin"];
    const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))

    const sendMenssage = (data) => {
        dispatch(PaymentSendActions.sendMenssage(data))
    }

    let column = [
        {
            title: 'Usuario ',
            dataIndex: 'ownerships',
            key: 'ownerships',
            render: (data) => {
                return <span>  {data[0]?.user?.profiles[0]?.name} {data[0]?.user?.profiles[0]?.lastname}</span>
            }
        },
        {
            title: 'Correo ',
            dataIndex: 'ownerships',
            key: 'ownershipsEmail',
            render: (data) => {
                return <span>  {data[0]?.user?.email}</span>
            }
        },
        {
            title: 'Telefono ',
            dataIndex: 'ownerships',
            key: 'ownershipsPhone',
            render: (data) => {
                return <span>  {data[0]?.user?.phone[0]?.number}</span>
            }
        },
        {
            title: 'id / Propiedad ',
            key: 'title',
            render: (data) => {

                return <Button onClick={() => history.push(`/property/${data?.property?.id}`)} type="text"> <span key={data?.id}> {data?.property?.id} / {data?.property?.title} </span></Button>
            }
        },
        {
            title: 'Días de recaudo',
            dataIndex: '',
            key: 'startdate',
            render: (data) => {
                if (data?.genPaymentAt) {
                    return <span> {data?.genPaymentAt} - {data?.genPaymentAt + 4}</span>
                } else {
                    const dayCurrentPay = data?.startedAt?.substring(0, 10)?.replaceAll('-', '/').split('/')[2];
                    return <span> {parseInt(dayCurrentPay)} - {parseInt(dayCurrentPay) + 4}</span>
                }
            }
        }
    ]
    if (isAdmin)
        column.push({
            title: 'Acciones',
            key: 'action',
            dataIndex: '',
            width: '10%',
            render: (data) => (
                <>
                    <Button onClick={() => {
                        if (data?.genPaymentAt) {
                            sendMenssage({ ...data, type: "reminder", startedAt: data?.genPaymentAt })
                        } else {
                            sendMenssage({ ...data, type: "reminder" })
                        }

                    }} >Enviar Recordatorio</Button>
                    <br></br>
                    <Button type="primary" onClick={() => {
                        if (data?.genPaymentAt) {
                            sendMenssage({ ...data, type: "link", startedAt: data?.genPaymentAt })
                        } else {
                            sendMenssage({ ...data, type: "link" })
                        }
                    }} >Enviar Link de pago</Button>
                </>
            )
        })

    return column
}

export const SearchApp = () => {


};