import React, { useEffect, useState } from "react";
import { Typography, Popover, Avatar, Layout, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { authActions } from "../../../../services/Auth/AuthSlice";
import { useHistory } from "react-router-dom";
import { StorageService } from "../../../../common/storage/storage-service";
import styled, { useTheme } from 'styled-components';
import logo from '../../../../assets/logo-purple.png'

export const Header = () => {
  const { Text } = Typography;
  const { Header: AntdHeader } = Layout;
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const [isCompany, setIsCompany] = useState(null);

  const [visiblePopover, setVisiblePopover] = useState(false);
  const { company, loading } = useSelector(state => state.auth)
  const theme = useTheme()

  const dispatch = useDispatch();
  const logout = () => {
    StorageService.clear();
    history.push("/");
    dispatch(authActions.logout());
  };

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  useEffect(() => {
    if (company?.length) {
      setIsCompany(company[0])
      theme.colors.primary.main = JSON.parse(company[0].metadata)?.color[0] || "#333"
    }
  }, [company])

  return (
    <div>
      <div className={`header-profile header-profile--${module}`}>
        {/* <img className="logo" style={{ height: '60px' }} src={isCompany ? JSON.parse(isCompany.metadata)?.img : logo} alt="logo" /> */}
        <div className="container header-profile--content">
          <div className="sub-header">
            <div className="user-data">
              <div className="icon-name">
                <span className="icon-name__acron">
                  {user?.profile?.name.substring(0, 1).toUpperCase()}
                </span>
                <span
                  className={`font-color-darken icon-name__acron font-color-darken--admin`}
                >
                  {user?.profile?.lastname.substring(0, 1).toUpperCase()}
                </span>
              </div>
              <div className="title">
                <span className="title__greet">Hola.</span>
                <h1 className="title__name">
                  {capitalize(user?.profile?.name)}&nbsp;
                  <strong
                    className={`font-color-darken  font-color-darken--admin`}

                  >
                    {capitalize(user?.profile?.lastname)}
                  </strong>
                </h1>
                <h1 className="title__rol">
                  <strong className="font-color-darken--admin">
                    Administrador
                  </strong>
                </h1>
              </div>
            </div>
            <Button
              style={{
                background: "transparent",
                borderRadius: "5px",
                borderColor: "#d82067",
                padding: "5px 31px",
                height: "auto",
                fontSize: "medium",
              }}
              onClick={logout}
              className="font-color-darken--admin"
            >
              Cerrar Sesión
            </Button>
          </div>
        </div>
      </div>

      {/*      <AntdHeader className="header" >
        <a>
          <Popover
            placement="bottomRight"
            trigger="click"
            visible={visiblePopover}
            onVisibleChange={() => setVisiblePopover(!visiblePopover)}
            content={<a onClick={logout}><h3>Cerrar sesión</h3></a>}
          >
            <Text strong style={{ paddingRight: 20 }}>{user?.profile?.name}</Text>
            <Avatar size="large" icon={<UserOutlined />} />
          </Popover>
        </a>
      </AntdHeader> */}
    </div>
  );
};
