import React, { useState } from "react";
import { InputNumber, Input, Form, Select, Radio, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router";

import { propertyActions } from "../../../../../../../services/Property/PropertySlice";
import { locationActions } from "../../../../../../../services/Location/LocationSlice";


const EditDescription = ({ id, handleCancel }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [admin, setAdmin] = useState(true);
  const [selectCity, setSelectCity] = useState();
  const { locations } = useSelector((state) => state.location);
  const { property, user, userAdmin } = useSelector((state) => state.property);
  const dispatch = useDispatch();
  const history = useHistory();

  const onFinish = (values) => {
    let pay = {
      title: values.title,
      is_ph: values.is_ph,
      is_test: values.is_test || false,
      priority: values.priority || 1,
      property_type_id: values.property_type_id,
    };
    let localization = {
      "address": values.direction,
      "address_data": values.direction,
      "address_extra": values.direction,
      "location_id": locations.length ? values.location : property?.localization?.location?.id,
      "latitude": 0,
      "longitude": 0
    }
    let ownership = {
      "email": values.user_email,
      "ownership_type_id": 1,
      "ownable_id": property?.id,
      "ownable_type": "Property"
    }
    let ownershipAdmin = {
      "email": values.user_email_admin,
      "ownership_type_id": 7,
      "ownable_id": property?.id,
      "ownable_type": "Property"
    }
    let cost = [];

    if (admin) {
      cost.push({
        cost_type: { id: values.ValorAdmin },
        currency: { id: 2 },
        value: values.ValorAlquiler,
      });
    } else {
      cost.push(
        {
          cost_type: { id: 1 },
          currency: { id: 2 },
          value: values.ValorAlquiler,
        },
        {
          cost_type: { id: 2 },
          currency: { id: 2 },
          value: values.ValorAdministracion,
        }
      );
    }

    let payload = {
      pay,
      localization,
      ownership,
      isOwnerTypeOne: property?.ownership?.find(owner => owner.ownership_type_id == 1) ? true : false,
      ownershipAdmin,
      isOwnerTypeSeven: property?.ownership?.find(owner => owner.ownership_type_id == 7) ? true : false,
      id: id,
      cost,
      existAdmin: !!(userAdmin?.email),
      existOwner: !!(user?.email)
    };
    dispatch(propertyActions.editProperty(payload));
  };

  const close = () => {
    history.push(`/property/${id}`);
    handleCancel();
  };

  const onChange = (e) => {
    if (e.target.value === 1) {
      setAdmin(true);
    }
    if (e.target.value === 2) {
      setAdmin(false);
    }
  };


  const getLocationOnSearch = (e) => {
    if (e.length > 4) dispatch(locationActions.getLocations(e));
  };

  const getLocationOnChange = (e) => {
    const city = locations.filter((location) => location.id == e);
    setSelectCity(city);
  };


  return (
    <Form
      /* {...formItemLayout} */
      form={form}
      name="register"
      initialValues={{
        title: property?.title,
        property_type_id: property?.property_type?.id,
        ValorAlquiler: property?.costs?.find(cost => cost.cost_type_id == 1)?.value, is_ph: property?.is_ph != 0,
        ValorAdmin: property?.costs?.find(cost => cost.cost_type_id == 2) ? 2 : 1, is_test: !!property?.is_test,
        priority: property?.priority, direction: property?.localization?.address,
        location: property?.localization?.location?.fullname,
        user_email: user?.email,
        user_email_admin: userAdmin?.email
      }}
      onFinish={onFinish}
      scrollToFirstError
    >
      <Form.Item
        name="title"
        label="Titulo"
        rules={[
          { required: true, message: "Ingresa un nombre", whitespace: true },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="property_type_id"
        label="Tipo de Propiedad"
        rules={[{ required: true, message: "Selecciona el tipo de Propiedad" }]}
      >
        <Select placeholder="Seleccione el tipo de propiedad">
          <Option value={1}>Apartamento</Option>
          <Option value={2}>Casa</Option>
          <Option value={3}>Oficina</Option>
          <Option value={4}>Local Comercial</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="is_ph"
        label="¿Es Propiedad Horizontal?"
        rules={[{ required: true, message: "Selecciona una opción" }]}
      >
        <Radio.Group>
          <Radio value={true}>Si</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="is_test"
        label="¿Es Propiedad de Prueba?"
        rules={[{ required: true, message: "Selecciona una opción" }]}
      >
        <Radio.Group>
          <Radio value={true}>Si</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="ValorAdmin"
        label="¿El valor de alquiler incluye administración?"
      >
        <Radio.Group>
          <Radio onChange={(e) => onChange(e)} value={1}>
            Si
          </Radio>
          <Radio onChange={(e) => onChange(e)} value={2}>
            No
          </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="ValorAlquiler"
        label="Valor del Alquiler"
        rules={[{ required: true, message: "Ingrese un Numero" }]}
      >
        <InputNumber
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          style={{ width: 150 }}
        />
      </Form.Item>

      {admin ? null : (
        <Form.Item
          name="ValorAdministracion"
          label="Valor de Administración"
          rules={[{ required: true, message: "Ingrese un Numero" }]}
        >
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: 150 }}
          />
        </Form.Item>
      )}

      <Form.Item
        name="priority"
        label="Prioridad"
        rules={[{ required: true, message: "Selecciona una opción" }]}
      >
        <InputNumber max={10} min={1} />
      </Form.Item>

      <Form.Item
        name="location"
        label="Ciudad, Departamento"
        rules={[{ required: true, message: "Por favor ingrese el nombre!" }]}
      >
        <Select
          showSearch
          placeholder={"Ciudad, Departamento"}
          showArrow={false}
          filterOption={false}
          onChange={getLocationOnChange}
          onSearch={getLocationOnSearch}
          notFoundContent={null}
        >
          {locations.map((location) => (
            <Option key={location.id} data={location}>
              {location.fullname}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="direction"
        label="Dirección "
        placeholder={"Cra # cll"}
        rules={[
          {
            required: true,
            message: "Ingresa una dirección",
            whitespace: true,
          },
        ]}
      >
        <Input placeholder={"Cra cll #"} />
      </Form.Item>
      <Form.Item
        name="user_email"
        label="email del propietario "
        placeholder={"email del Propietario"}
        rules={[
          {
            message: "Ingresa el email del usuario propietario",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="user_email_admin"
        label="email de la inmobiliaria "
        placeholder={"email de la inmobiliaria"}
        rules={[
          {
            message: "Ingresa el email de la inmobiliaria",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item style={{ marginLeft: "40rem" }}>
        <Button type="primary" htmlType="submit" onClick={close}>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditDescription;
