import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Spin, Radio, Row, Col, PageHeader, Modal, Button } from 'antd'
import { dispersionActions } from "services/Dispersion/DispersionSlice";
import { shoppingActions } from '../../../services/Shopping/ShoppingSlice'
import { columns } from './constans'
import { Filter } from './sections/Filter'
import { ModalDispersionData } from './sections/ModalDispersionData'
import ReactExport from "react-export-excel";
import { ShowButton } from '../components/ShowButton/ShowButton';
import { ModalDownloadData } from './sections/ModalDownloadData';
import { ModalUploadDoc } from './sections/ModalUploadDoc';
import moment from 'moment';
export const Dispersion = () => {

  const today = new Date("01-01-2024");
  const formattedDate = today.toISOString().split('T')[0];
  const dispatch = useDispatch()
  const { dispersions, status, pagination, filterData, reload } = useSelector(state => state.dispersion)
  const { page, limit, total } = pagination
  const [disDownload, setDisDownload] = useState(null);
  const [regenerateData, setRegenerateData] = useState(null);
  const { user } = useSelector((state) => state.property);
  const [startDate, setStartDate] = useState(formattedDate);
  const [userEmail, setUserEmail] = useState('');
  const [inmo, setInmo] = useState('Todos');
  const [property, setProperty] = useState('');
  const [selectionType, setSelectionType] = useState('11');
  const [current, setCurrent] = useState(0);
  const [error, setError] = useState('');
  const [isModalVisible, setIsModalVisible] =
    useState(false);
  const [isModalDownloadVisible, setIsModalDownloadVisible] =
    useState(false);
  const [cart, setCart] =
    useState(false);
  const [isModalUploadVisible, setIsModalUploadVisible] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const currentColumn = columns(isModalVisible, setIsModalVisible, cart, setCart, disDownload, setDisDownload, regenerateData, setRegenerateData, selectedRows, setSelectedRows, isModalUploadVisible, setIsModalUploadVisible);
  const [updateView, setUpdateView] =
    useState(false);

  useEffect(async () => {
    if (reload == true) {
      setUpdateView(false)
      if (startDate) {
        let filterData_local = {
          period: startDate,
        };
        if (inmo != "Todos" ) { 
          if(filterData['context'] != inmo){
            filterData_local.context = inmo;
          } 
        }else if(filterData['context'] != "Todos"){
          filterData_local.context = filterData['context'];
        }
        if (userEmail !== "" && userEmail !== undefined && userEmail !== null) {
          filterData_local.user_email = userEmail;
        }  
        if (filterData.property_id != undefined) {
          filterData_local.property_id = filterData.property_id;
        } 
        await dispatch(dispersionActions.getDispersionList({ page, limit, filterData: filterData_local }))
        await dispatch(dispersionActions.onChangeReload({ value: false }))
        setError('');
      }
    }

  }, [reload])

  useEffect(() => {
    if (disDownload != null) {
      // se habre el modal para seleccionar la fecha

      let answer = window.confirm("confirme que este pago ya fue aprobado por el banco");
      if (answer) {
        let updateData = {
          "status_id": 8,
          "dispersion_register_id": parseInt(disDownload.id)
        }
        dispatch(dispersionActions.updateDisRegister({ updateData }))
      }
      setDisDownload(null);
    }

  }, [disDownload])

  useEffect(async () => {
    if (regenerateData != null) {
      let answer = window.confirm("Desea Regenerar la informacion de dispersion de este pago? , " +
        "tenga en cuenta que esta operacion podria modificar registros que ya esten pagados asociados al recaudo");
      if (answer) {

        let updateData = {
          "shopping_cart": regenerateData.shoppingCartId ? parseInt(regenerateData.shoppingCartId) : 0,
          "contract_id": parseInt(regenerateData.contractId), 
          "code_gen": regenerateData.consecutiveDisGen ? parseInt(regenerateData.consecutiveDisGen) : 0
        }
        await dispatch(dispersionActions.regenDataDisRegister({ updateData }))
        if (startDate) {
          let filterData = {
            period: startDate,
          };
          if (inmo != "Todos") {
            filterData.context = inmo;
          }
          if (userEmail !== "" && userEmail !== undefined && userEmail !== null) {
            filterData.user_email = userEmail;
          }
          //console.log('Filter Data:', filterData); 
          //await dispatch(dispersionActions.getDispersionList({ page, limit, filterData: filterData }))
          setError('');
        }
      }
      setDisDownload(null);
    }
  }, [regenerateData])

  const handleOk = async () => {
    await dispatch(dispersionActions.onChangeReload({ value: true }))
    //dispatch(dispersionActions.getDispersionList({ page, limit, filterData }))
  };

  const handleCancel = async () => {
    setIsModalVisible(false);
    setIsModalDownloadVisible(false);
    setCart(false);
    await dispatch(dispersionActions.onChangeReload({ value: true }))
    //dispatch(dispersionActions.getDispersionList({ page, limit, filterData }))
  };

  const handleUploadOk = () => {
  };

  const handleUploadCancel = () => {
    setIsModalUploadVisible(false);
    setCart(false);
  };


  const handlePagination = async (page, limit) => {
    await dispatch(dispersionActions.onChangeReload({ value: true }))
    //dispatch(dispersionActions.getDispersionList({ page, limit, filterData }))
  }

  // Función para seleccionar todas las filas
  const handleSelectAll = () => {
    if (dispersions.length >= 1) {
      if (selectAll) {
        setSelectedRows([]);
      } else {
        let valid_reg = [];
        dispersions.forEach(element => {
          let errors = element.metadata[0].error;
          if (element.statusId != 8 && errors.length === 0) {
            valid_reg.push(element)
          }
        });
        setSelectedRows(valid_reg); // Asigna todas las filas como seleccionadas
      }
      setSelectAll(!selectAll); // Cambia el estado de selección de todos los checkboxes
    }
  };


  const listLastApproved = async () => {
    let startDate_piv = moment(startDate);
    const date_init_moment = moment(startDate);
    let date_end = date_init_moment
      .add(-6, 'month')
      .toISOString()
      .split('T')[0];
    let filterData = {
      period_end: startDate_piv.toISOString().split('T')[0],
      period: date_end,
    };
    await dispatch(dispersionActions.listLastApproved({ filterData: filterData }))
  }

  const tablePagination = {
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: limit,
    total
  }
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const dataProccesorExcel = (col) => {
    return col.map(row => {

      let bankCode = 0;
      let code_piv = 0;
      let userBankData = row.metadata[0]?.data_user_bank;
      let userData = row.metadata[0]?.data_user;
      if (row.userId == "1") {
        if (userBankData != null) {
          code_piv = userBankData.bankAccountDistribution?.bank.id;
        }

      } else {
        let adminData = row.metadata[0]?.data_user_bank;
        if (adminData != null) {
          code_piv = adminData.bank.id;
        }
      }

      switch (code_piv) {
        case 1://Bank of America
          bankCode = '';
          break;
        case 2://Banco Agrario
          bankCode = 40;
          break;
        case 3://Banco AV Villas
          bankCode = 52;
          break;
        case 4://Banco Caja Social
          bankCode = 32;
          break;
        case 5://Banco de Occidente
          bankCode = 23;
          break;
        case 6://Banco Popular
          bankCode = 2;
          break;
        case 7://Bancolombia
          bankCode = 7;
          break;
        case 8://BBVA Colombia
          bankCode = 13;
          break;
        case 9://Banco de Bogotá
          bankCode = 1;
          break;
        case 10://Citi Colombia
          bankCode = 9;
          break;
        case 11://Colpatria
          bankCode = 19;
          break;
        case 12://Davivienda
          bankCode = 51;
          break;
        case 13://GNB Sudameris
          bankCode = 12;
          break;
        case 20://efectivo
          bankCode = 20;
          break;
        case 21://otro
          bankCode = 21;
          break;
      }

      let bankAccType = 0;
      let bankAccTypePiv = userBankData?.bankAccountTypeId;
      if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          bankAccTypePiv = adminData.bankAccountDistribution.typeProdServ;
        }
      }
      switch (bankAccTypePiv) {
        case 1:
        case 3:
          bankAccType = 'CA';
          break;
        case 2:
        case 4:
          bankAccType = 'CC';
          break;
      }

      let docidCode = 0;
      switch (userData?.docid[0]?.docidTypeId) {
        case 1:
          docidCode = 5;
          break;
        case 2:
          docidCode = 'Licencia de conduccion';
          break;
        case 3:
          docidCode = 1;
          break;
        case 4:
          docidCode = 2;
          break;
        case 5:
          docidCode = 3;
          break;
      }

      let code = (userData?.docid[0]?.code);
      let name = (userData?.profiles[0]?.name);
      let lastname = (userData?.profiles[0]?.lastname);
      let number = (userBankData?.number);
      let valuePay = (row.valuePay);
      let email = (userData?.email);
      let propertyName = row.metadata[0]?.prop_name;

      ///si es de tipo administracion
      if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          code = (adminData.bankAccountDistribution.numId);
          name = (adminData.bankAccountDistribution.name);
          lastname = (adminData.bankAccountDistribution.lastname);
          number = (adminData.bankAccountDistribution.numProdServ);
          valuePay = (row.valuePay);
          email = (adminData.bankAccountDistribution.email);
          propertyName = row.metadata[0]?.prop_name;

        }
      }

      if (docidCode == 0 || bankAccType == 0 || bankCode == 0 ||
        code == undefined || name == undefined ||
        lastname == undefined || number == undefined ||
        valuePay == undefined || email == undefined
      ) {
        return null
      } else {
        return {
          typeDoc: docidCode,
          docNum: code,
          name: name,
          lastName: lastname,
          codeBank: bankCode,
          typeProduct: bankAccType,
          numProd: number,
          valueTotal: valuePay,
          reference: "ARRIENDO",
          email: email,
          description: propertyName
        };
      }
    }).filter(row => row !== null);;

  }

  const dataProccesorExcelBc = (col) => {
    const headerRows = [
      {
        typeDoc: 'NIT PAGADOR',
        docNum: 'TIPO DE PAGO',
        name: 'APLICACIÓN',
        typeTrans: 'SECUENCIA DE ENVÍO',
        codeBank: 'NRO CUENTA A DEBITAR',
        numProd: 'TIPO DE CUENTA A DEBITAR',
        email: 'DESCRIPCIÓN DEL PAGO',
        docAuthorized: '',
        reference: '',
        phone: '',
        value: '',
        dateApplay: ''
      },
      {
        typeDoc: '123456',
        docNum: '220',
        name: 'I',
        typeTrans: 'A1',
        codeBank: '1111111111',
        numProd: 'S',
        email: 'Ejemplo1',
        docAuthorized: '',
        reference: '',
        phone: '',
        value: '',
        dateApplay: ''
      },
      {
        typeDoc: 'Tipo Documento Beneficiario',
        docNum: 'Nit Beneficiario',
        name: 'Nombre Beneficiario',
        typeTrans: 'Tipo Transaccion',
        codeBank: 'Código Banco',
        numProd: 'No Cuenta Beneficiario',
        email: 'Email',
        docAuthorized: 'Documento Autorizado',
        reference: 'Referencia',
        phone: 'Celular Beneficiario',
        value: 'ValorTransaccion',
        dateApplay: 'Fecha de aplicación',

      }
    ];



    const processedData = col.map(row => {
      let bankCode = 0;
      let code_piv = 0;
      let userBankData = row.metadata[0]?.data_user_bank;
      let userData = row.metadata[0]?.data_user;
      if (row.userId == "1") {
        if (userBankData != null) {
          code_piv = userBankData.bankAccountDistribution?.bank.id;
        }

      } else {
        let adminData = row.metadata[0]?.data_user_bank;
        if (adminData != null) {
          code_piv = adminData.bank.id;
        }
      }

      switch (code_piv) {
        case 2://Banco Agrario
          bankCode = 1040;
          break;
        case 3://Banco AV Villas
          bankCode = 1052;
          break;
        case 4://Banco Caja Social
          bankCode = 1032;
          break;
        case 5://Banco de Occidente
          bankCode = 1023;
          break;
        case 6://Banco Popular
          bankCode = 1002;
          break;
        case 7://Bancolombia
          bankCode = 1007;
          break;
        case 8://BBVA Colombia
          bankCode = 1013;
          break;
        case 9://Banco de Bogotá
          bankCode = 1001;
          break;
        case 10://Citi Colombia
          bankCode = 1009;
          break;
        case 11://Colpatria
          bankCode = 1019;
          break;
        case 12://Davivienda
          bankCode = 1051;
          break;
        case 13://GNB Sudameris
          bankCode = 1012;
          break;
        case 20://efectivo
          bankCode = 1020;
          break;
        case 21://otro
          bankCode = 0;
          break;
      }

      let bankAccType = 0;
      let bankAccTypePiv = userBankData?.bankAccountTypeId;
      if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          bankAccTypePiv = adminData.bankAccountDistribution.typeProdServ;
        }
      }
      switch (bankAccTypePiv) {
        case 1:
        case 3:
          bankAccType = 'CA';
          break;
        case 2:
        case 4:
          bankAccType = 'CC';
          break;
      }

      let docidCode = 0;
      switch (userData?.docid[0]?.docidTypeId) {
        case 1:
          docidCode = 5;
          break;
        case 2:
          docidCode = 'Licencia de conduccion';
          break;
        case 3:
          docidCode = 1;
          break;
        case 4:
          docidCode = 2;
          break;
        case 5:
          docidCode = 3;
          break;
      }

      let code = (userData?.docid[0]?.code);
      let name = (userData?.profiles[0]?.name);
      let lastname = (userData?.profiles[0]?.lastname);
      let number = (userBankData?.number);
      let valuePay = (row.valuePay);
      let email = (userData?.email);
      let propertyName = row.metadata[0]?.prop_name;

      ///si es de tipo administracion
      if (row.userId == "1") {
        let adminData = row.metadata[0]?.admin_data;
        if (adminData != null) {
          code = (adminData.bankAccountDistribution.numId);
          name = (adminData.bankAccountDistribution.name);
          lastname = (adminData.bankAccountDistribution.lastname);
          number = (adminData.bankAccountDistribution.numProdServ);
          valuePay = (row.valuePay);
          email = (adminData.bankAccountDistribution.email);
          propertyName = row.metadata[0]?.prop_name;

        }
      }

      if (docidCode == 0 || bankAccType == 0 || bankCode == 0 ||
        code == undefined || name == undefined ||
        lastname == undefined || number == undefined ||
        valuePay == undefined || email == undefined
      ) {
        return null
      } else {
        return {
          typeDoc: docidCode,
          docNum: code,
          name: name + ' ' + lastname,
          typeTrans: 0,
          codeBank: bankCode,
          numProd: number,
          email: email,
          docAuthorized: '',
          reference: "ARRIENDO " + ' ' + propertyName,
          phone: '',
          value: valuePay,
          dateApplay: ''
        };
      }
    }).filter(row => row !== null);;

    return [...headerRows, ...processedData];

  }
  return (
    <div className="user">
      {/*      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888' }}
        extra={[
        ]}
      /> */}


      <Spin spinning={status === "loading"}>
        <Filter />
        <Table
          title={() =>
            <>
              <Row justify="space-between">
                <Col flex={1} > <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, margin: '25px 10px' }}>Dispersiones</span></Col>
              </Row>
              <Row>
                <Button style={{
                  borderRadius: '5px',
                  borderColor: '#d82067',
                  padding: '5px 31px',
                  height: 'auto',
                  fontSize: 'medium',
                  marginRight: "15px"
                }} className="btn-default"
                  onClick={handleSelectAll}>
                  {selectAll ? 'Desseleccionar todo' : 'Seleccionar todo'}
                </Button>

                <Button style={{
                  borderRadius: '5px',
                  borderColor: '#d82067',
                  padding: '5px 31px',
                  height: 'auto',
                  fontSize: 'medium',
                }} className="btn-default" onClick={() => { setIsModalDownloadVisible(true); }}> Aprobar </Button>

                <Button style={{
                  borderRadius: '5px',
                  borderColor: '#d82067',
                  padding: '5px 31px',
                  height: 'auto',
                  fontSize: 'medium',
                  marginLeft: "10px"
                }} className="btn-default" onClick={() => { listLastApproved(); }}> Ultimos aprobados </Button>

              </Row>
            </>
          }
          columns={currentColumn}
          dataSource={dispersions}
          scroll={{ x: 400 }}
          pagination={tablePagination}
        />
      </Spin>
      {dispersions.length ?
        <ExcelFile element={<Button style={{
          borderRadius: '5px',
          borderColor: '#d82067',
          padding: '5px 31px',
          height: 'auto',
          fontSize: 'medium',
          marginTop: 20
        }}
          type="primary">Descargar Davivienda</Button>}>
          <ExcelSheet data={dataProccesorExcel(dispersions)} name="Estado de cuenta" >

            <ExcelColumn label="Tipo de identificacion	" value={(col) => col.typeDoc} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn label="Numero de identificacion	" value={(col) => col.docNum} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn label="Nombre	" value={(col) => col.name} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn label="Apellido	" value={(col) => col.lastName} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn label="Codigo del banco	" value={(col) => col.codeBank} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn label="Tipo de Producto o Servicio	" value={(col) => col.typeProduct} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn label="Numero del Producto o Servicio	" value={(col) => col.numProd} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn label="Valor total pago o de la recarga" value={(col) => col.valueTotal} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn label="Referencia	" value={(col) => col.reference} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn label="Correo Electronico	" value={(col) => col.email} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn label="Descripcion o Detalle" value={(col) => col.description} ExcelCell style={{ alignment: { wrapText: true } }} />
          </ExcelSheet>
        </ExcelFile> : ""
      }
      {isModalVisible &&
        <Modal
          title={"Informacion de dispersion"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalDispersionData
            cart={cart}
          />
        </Modal>
      }
      {isModalDownloadVisible &&
        <Modal
          title={"Confirmacion de pagos"}
          visible={isModalDownloadVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalDownloadData
            carts={selectedRows}
          />
        </Modal>
      }
      {isModalUploadVisible &&
        <Modal
          title={"Archivos de soporte"}
          visible={isModalUploadVisible}
          onOk={handleUploadOk}
          onCancel={handleUploadCancel}
          width={1100}
          footer={null}
        >
          <ModalUploadDoc
            cart={cart}
          />
        </Modal>
      }
      {dispersions.length ?
        <ExcelFile element={<Button style={{
          borderRadius: '5px',
          borderColor: '#d82067',
          padding: '5px 31px',
          height: 'auto',
          fontSize: 'medium',
          margin: 20
        }}
          type="primary">Descargar Bancolombia</Button>}>
          <ExcelSheet data={dataProccesorExcelBc(dispersions)} name="Estado de cuenta">
            <ExcelColumn value={(col) => col.typeDoc || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.docNum || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.name + ' ' + col.lastName || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.typeTrans || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.codeBank || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.numProd || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.email || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.docAuthorized || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.reference || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.phone || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.value || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
            <ExcelColumn value={(col) => col.dateApplay || ''} ExcelCell style={{ alignment: { wrapText: true } }} />
          </ExcelSheet>
        </ExcelFile> : ""
      }
    </div>
  )
}