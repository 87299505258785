import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  status: null,
  errormsg: null,
  loading: true,
  banks: [],
  bankAccountTypes: [],
  bankAccount: []
}

const bankSlice = createSlice({
  name: "bank",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload;
    },
    getBanks() { },
    getBanksAccountType() { },
    addBankAccount() { },
    getBankAccount() { },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
    setBanks(state, { payload }) {
      state.banks = payload;
    },
    setBankAccountTypes(state, { payload }) {
      state.bankAccountTypes = payload;
    },
    setBankAccount(state, { payload }) {
      state.bankAccount = payload.data
    },
    updateBankAccount(state, { payload }) {
      state.bankAccount = payload.data
    },
    deleteBankAccount(state, { payload }) {
      state.bankAccount = payload.data
    }

  }
});

const bankActions = bankSlice.actions
const bankReducer = bankSlice.reducer

export { bankActions, bankReducer };