import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Modal, Row, Col } from "antd";
import { discountsActions } from "services/Discounts/DiscountsSlice";
import { DiscountModal } from "./modals/DiscountModal";
import { columnsTable,columnsTableDis } from './constants' 
import { DiscountDispersionModal } from "./modals/DiscountDispersionModal";

const Discounts = () => {
  const dispatch = useDispatch()
  const { contract } = useSelector((state) => state.contract);
  const { discountSelected, discounts } = useSelector((state) => state.discounts);
  const { discountModal } = useSelector((state) => state.discounts);

  useEffect(() => {
    dispatch(discountsActions.getDiscountContract({ contractId: contract?.id }))
  }, [])

  useEffect(() => {
    console.log("🚀 ~ file: Discounts.js:11 ~ Discounts ~ contract:", contract)
    console.log("🚀 ~ file: Discounts.js:22 ~ Discounts ~ discounts:", discounts)
  }, [discounts])

  const handleUpdateDiscount = (discount) => {
    dispatch(discountsActions.setState({ key: "discountSelected", value: discount }));
    dispatch(discountsActions.setState({ key: "discountModal", value: true }));
  };

  const unActivateDiscount = (data) => {
    Modal.confirm({
      title: "Desea eliminar el descuento",
      onOk: () => { 
        dispatch(discountsActions.deleteDiscountContract({
          "reg_id": parseInt(data.id)
        }))
      },
      onCancel: () => {
      }
    })

  };

  const handleAddDiscount = () => {
    dispatch(discountsActions.setState({ key: "discountModal", value: true }));
  };

 // Data de dispersiones
  const { discountDisSelected, discountsDis } = useSelector((state) => state.discounts); 
  const { discountDisModal } = useSelector((state) => state.discounts);

  useEffect(() => { 
    dispatch(discountsActions.getDiscountDispersion({ contractId: contract?.id }))
  }, [])

  useEffect(() => { 
    console.log("🚀 ~ file: Discounts.js:11 ~ DiscountsDis ~ contract:", contract)
    console.log("🚀 ~ file: Discounts.js:22 ~ DiscountsDis ~ discounts:", discountsDis)
  }, [discountsDis])

  const handleUpdateDiscountDis = (discount) => {
    dispatch(discountsActions.setState({ key: "discountDisSelected", value: discount }));
    dispatch(discountsActions.setState({ key: "discountDisModal", value: true }));
  };

  const unActivateDiscountDis = (data) => {
    Modal.confirm({
      title: "Desea eliminar el descuento de dispersion",
      onOk: () => { 
        dispatch(discountsActions.deleteDiscount({reg_id : data.id}))
      },
      onCancel: () => {
      }
    })

  };

  const handleAddDiscountDis = () => {
    dispatch(discountsActions.setState({ key: "discountDisModal", value: true }));
  };
 
  return (
    <div>
      <div>
        <div>
          <Row style={{ margin: '10px 0 30px' }}>
            <Col flex={'auto'}>
              <h2>Descuentos/Adiciones al inquilino</h2> 
            </Col>
            <Col>
              <Button className='button' type="primary" onClick={handleAddDiscount} >
                Agregar Descuento
              </Button>
            </Col>
          </Row>
          {discountModal && <DiscountModal visible={discountModal} discount={discountSelected} contractId={contract.id} />}
        </div>
        <Table
          pagination={false}
          rowKey="discount_id"
          columns={columnsTable(handleUpdateDiscount, unActivateDiscount)}
          dataSource={discounts}
          size="small"
          scroll={{ y: 410 }}
        />
      </div>
      <div>
        <div>
          <Row style={{ margin: '10px 0 30px' }}>
            <Col flex={'auto'}>
              <h2>Descuentos/Adiciones a la dispersion</h2> 
            </Col>
            <Col>
              <Button className='button' type="primary" onClick={handleAddDiscountDis} >
                Agregar Descuento
              </Button>
            </Col>
          </Row>


          {discountDisModal && <DiscountDispersionModal visible={discountDisModal} discount={discountDisSelected} contractId={contract.id} />}
        </div>
        <Table
          pagination={false}
          rowKey="discount_id"
          columns={columnsTableDis(handleUpdateDiscountDis, unActivateDiscountDis)}
          dataSource={discountsDis}
          size="small"
          scroll={{ y: 410 }}
        />
      </div>
    </div>
  );
};

export default Discounts;
