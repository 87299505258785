import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { ShowButton } from '../../components/ShowButton/ShowButton'

import { getColumnSearchProps } from '../../../../common/utilities/getColumnSearchProps'
import { useHistory } from "react-router";
import moment from "moment";
import "moment/locale/es";

export const TableProperties = (props) => {
  const { data, filter } = props;
  const [dataSource, setDataSource] = useState([]);
  const history = useHistory();

  const status_id = [
    "open",
    "in_progress",
    "stopped",
    "canceled",
    "re_opened",
    "closed",
    "Rechazado",
    "accepted",
    "rescheduled",
    "signed",
    "Pendiente de pago",
    "Pagado",
    "delivered",
    "returned",
    "in_deliver",
    "in_return",
    "delivered_not_inventory",
    "Espera de aprobación",
    "Pago aun no generado",
    "Sin pagos",
  ];

  useEffect(() => {
    switch (filter) {
      case "1":
        setDataSource(data.total.properties);
        break;
      case "2":
        setDataSource(data.accepted.properties);
        break;
      case "3":
        setDataSource(data.open.properties);
        break;
      case "4":
        setDataSource(data.publicProperty.properties);
        break;
      case "5":
        setDataSource(data.save.properties);
        break;

      default:
        break;
    }
    return () => {
      setDataSource([]);
    };
  }, [filter]);

  const columns = [
    {
      title: "id",
      dataIndex: "property_id",
      key: "id",
      sorter: (a, b) => a.property_id - b.property_id,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", useState(""), useState(""), (name) =>
        console.log(name)
      ),
    },
    {
      title: "Último estado de pago",
      dataIndex: "payments",
      key: "payments",
      /* sorter: (a, b) => (a.payments ? a.payments[a.payments?.length - 1] ? a.payments[a.payments?.length - 1].status_id - 1 : 18 : 18) - (b.payments ? b.payments[a.payments?.length - 1] ? b.payments[a.payments?.length - 1].status_id - 1 : 18 : 18), */
      render: (payments) => (
        <>
          <span>
            {
              status_id[
                payments
                  ? payments[payments?.length - 1]
                    ? payments[payments?.length - 1].status_id - 1
                    : 18
                  : 19
              ]
            }{" "}
            {payments
              ? payments[payments?.length - 1] &&
                moment(payments[payments?.length - 1]?.updated_at).format("LL")
              : ""}
          </span>
          {/* <span>{payments && payments[0] ? payments[0].status_id : 18}</span>
          <span>{status_id[12]}</span> */}
        </>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      dataIndex: "property_id",
      render: (id) => (
        <ShowButton onClick={() => history.push(`/property/${id}`)} />
      ),
    },
  ];

  return (
    <>
      <Table dataSource={dataSource} columns={columns} />
    </>
  );
};
