
export const CuiColors= {
    transparent: 'transparent',
    black: '#000000',
    white: '#ffffff',
    primary: {
        light: '#E8EAF0',
        main: 'linear-gradient(180deg, #57157d 0%, #7923aa 99.99%, rgba(121, 35, 170, 0) 100%)',
        dark: '#8D8D9D',
    },
    secondary: {
        light: '#E8EAF0',
        main: '#C3CBD6',
        dark: '#8D8D9D',
    },
    base: {
        lighter: '#878FB8',
        light: '#1D2559',
        main: '#181B32',
        dark: '#070E27',
        'lighter-dark-mode': '#2C2F48',
        'light-dark-mode': '#22253D',
        'lighter-8': 'rgba(135, 143, 184, 0.08)',
        'lighter-16': 'rgba(135, 143, 184, 0.16)',
        'lighter-24': 'rgba(135, 143, 184, 0.24)',
        'lighter-56': 'rgba(135, 143, 184, 0.56)',
        'lighter-70': 'rgba(135, 143, 184, 0.70)',
        'main-56': 'rgba(24, 27, 50, 0.56)',
        'main-80': 'rgba(24, 27, 50, 0.80)',
    },
    purple: {
        dark: '#3417AA',
        main: '#4B22F4',
        medium: '#6945FF',
        light: '#6F4EF6',
        lighter: '#9C86F9',
        'lighter-2': '#F6F4FE',
    },
    gray: {
        lighter: '#F6F7F9',
        light: '#E8EAF0',
        main: '#C3CBD6',
        dark: '#8D8D9D',
        'lighter-dark-mode': '#4F5374',
        'main-dark-mode': '#3F425E',
        'dark-8': 'rgba(141, 141, 157, 0.08)',
        'dark-16': 'rgba(141, 141, 157, 0.16)',
        'dark-24': 'rgba(141, 141, 157, 0.24)',
        'dark-56': 'rgba(141, 141, 157, 0.56)',
        'dark-70': 'rgba(141, 141, 157, 0.70)',
    },
    inherit: 'inherit',
}
export default  {
    colors: CuiColors,
    width: {
        auto: 'auto',
        full: '100%',
        screen: '100vw',
    }
}
