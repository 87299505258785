import React, { useEffect, useState } from "react";
import { Button, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { shoppingActions } from "../../../../../../services/Shopping/ShoppingSlice";
const EditPayValue = ({ cartsProperty, handleCancel }) => {
  const [cost, setCost] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const onChange = (value) => {
    setCost(value);
  };
  const { editPay } = useSelector(state => state.shopping)

  const submit = () => {
    dispatch(
      shoppingActions.editPay({
        valuePay: {
          cost,
          shopping_cart_id: cartsProperty.id,
        },
        propertyId: id,
      })
    );

  };

  return (
    <div className="modal-edit">
      <div className="modal-edit-pay">
        <span style={{ fontWeight: 'bold' }}>Escriba el nuevo valor del pago</span>
        <InputNumber
          style={{ width: "100%", marginTop: "1rem" }}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          size="large"
          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
          onChange={onChange}
        />
        <p style={{ color: 'rgb(237, 28, 39)' }}>{editPay?.response}</p>
      </div>

      <div className="modal-edit-button">
        <Button style={{marginRight: '15px'}} type="default" onClick={handleCancel}>
         Cerrar
        </Button>
        <Button type="primary" onClick={submit}>
          Guardar Valor
        </Button>
      </div>
    </div>
  );
};

export default EditPayValue;
