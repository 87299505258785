import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  status: null,
  errormsg: null,
  loading: true,
  notifications: [],
  pagination: {
    total: 1,
    page: 1,
    limit: 5
  }
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    getNotifications() { },
    setNotifications(state, { payload }) {
      state.notifications = payload.items;
      state.pagination = { total: payload.meta.totalItems, page: payload.meta.currentPage, limit: payload.meta.itemsPerPage };
    },
    onError(state, { payload }) {
      state.status = 'error';
      state.errormsg = payload;
    },
  },
});

const notificationActions = notificationSlice.actions;
const notificationReducer = notificationSlice.reducer;

export { notificationActions, notificationReducer };
