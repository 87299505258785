import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: null,
  list: [],
  plans: [],
  listDescription: [],
  filter: {},
  pagination: {
    total: 1,
    page: 1,
    limit: 25,
  },
  loading: false,
  errormsg: null,
};

const billingSlice = createSlice({
  name: "billing",
  initialState: initialState,
  reducers: {
    getList() { },
    getPlans() { },
    getListDescription() { },
    setLists(state, { payload }) {
      state.list = payload.items
      state.pagination = payload.pagination
      state.filter = payload.filter
    },
    setPlans(state, { payload }) {
      state.plans = payload.items 
    },
    setListsDescription(state, { payload }) {
      state.listDescription = payload.items
      state.pagination = payload.pagination
      state.filter = payload.filter
    },
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
    onError(state, { payload }) {
      state.loading = false;
      state.status = "error";
      state.errormsg = payload;
    },
  },
});

const billingActions = billingSlice.actions;
const billingReducer = billingSlice.reducer;

export { billingActions, billingReducer };
