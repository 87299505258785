import { put, all, takeLatest, select } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import ApiService from '../../common/api/Api';
import { companyActions } from './CompanySlice';

function* getCompanys() {
 
  try {
    const company = yield ApiService.get('/admin/company')
    yield put(companyActions.setCompanys(company))
    
  } catch (err) {
    yield put(companyActions.onError(err.toString()))
  }
}


function* getGrowCompany({payload}){
  try {
    yield put(companyActions.onChangeStatus("loading"));
    const response = yield ApiService.request(
      `/company/get_grow_company/${payload}`,
       "GET"
    ); 
    let items = response;   
    yield put(companyActions.setGrowArray({ items, filter: payload })); // Asegúrate de pasar `filter` correctamente
  
  } catch (err) {
    yield put(
      companyActions.onError("No se completar la operacion")
    );
  }
}


function* ActionWatcher() {
  yield takeLatest(companyActions.getCompanys, getCompanys)
  yield takeLatest(companyActions.getGrowCompany, getGrowCompany)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ]);
}
